// import reportOutStanding from '@/modules/reportOutStanding';
import taisievReportOutStanding from '@/modules/outstandingReports/taisiev';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/taisievReportOutStanding',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: taisievReportOutStanding,
				name: 'taisievReportOutStanding'
			}
		]
	}
];

export default routes;
