const UPDATE_LIST_OPTION = (state, data) => {
    state.getListOption = data;
};

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}

const UPDATE_LIST_OPTION_DEFAULT_CUSTOM = (state, data) => {
    state.getListOptionDefaultCustom = data;
};

const UPDATE_LOADING_CUSTOM = (state, data) => {
    state.getLoadingCustom = data
}

const UPDATE_RADIO_OPTION = (state, data) => {
    state.radioOption = data
}
const UPDATE_CURRENT_RADIO_OPTION = (state, data) => {
    state.radioOptionCurrent = data
}
const UPDATE_LIST_CUSTOM_OPTION = (state, data) => {
    state.listOptionCustom = data
}
const UPDATE_LIST_CREATE_CUSTOM_OPTION = (state, data) => {
    state.listCreateOptionCustom = data
}

export default {
    UPDATE_LOADING,
    UPDATE_LIST_OPTION,
    UPDATE_LIST_OPTION_DEFAULT_CUSTOM,
    UPDATE_LOADING_CUSTOM,
    UPDATE_RADIO_OPTION,
    UPDATE_CURRENT_RADIO_OPTION,
    UPDATE_LIST_CUSTOM_OPTION,
    UPDATE_LIST_CREATE_CUSTOM_OPTION
};
