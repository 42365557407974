const getPermission = (state) => state.permission;
const getPopUp = (state) => state.popUp;
const getCurrentPermission = (state) => state.currentPermission;
const getDeleteItems = (state) => state.deleteItems;

export default{
    getPermission,
    getPopUp,
    getCurrentPermission,
    getDeleteItems
};