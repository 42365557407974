import typeGameSummarySenior from '@/modules/statementReports/typeGame/typeGameSummarySenior';
import Layout from '@/layouts/default';

const routes = [{
    path: '/typeGameSummarySenior',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: typeGameSummarySenior,
        name: 'typeGameSummarySenior'
    }]
}];

export default routes;