import cancelBetting from '@/modules/cancelBetting';
import Layout from '@/layouts/default';

const routes = [{
    path: '/cancel-betting',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: cancelBetting,
        name: 'cancelBetting'
    }]
}];

export default routes;