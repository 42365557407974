export default {
	receipt: 'Receipt',
	invoice_no: 'Invoice No',
	doctor: 'Doctor',
	date: 'Date',
	gender: 'Gender',
	patient: 'Patient',
	age: 'Age',
	phone_number: 'Phone Number',
	description: 'Description',
	qty: 'Qty',
	unit_price: 'Unit Price',
	subtotal: 'subtotal',
	discount: 'Discount',
	total: 'Total',
	total_price: 'Total Price',
	notes: 'Notes',
	deposit: 'Cash-in'
};