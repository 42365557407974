import ocStatementSummaryAgentKm from '@/modules/statementReports/currency/ocStatementSummaryAgentKm';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryAgentKm',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryAgentKm,
        name: 'ocStatementSummaryAgentKm'
    }]
}];

export default routes;