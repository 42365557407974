const UPDATE_USERS = (state, data) => {
    state.users = data;
};
const UPDATE_POPUP = (state, data) => {
   
    if (!data) {
        state.currentUser = {
            user_name: null,
            password: 123456,
            account_info: null,
            // balance: null,
            role_id: null,
            enable:true,
            app_id: null,
            selectedChannel:[1,2,3,4]
        }
    }
    state.popUp = data;
};
const UPDATE_CURRENT_USER = (state, data) => {
    state.currentUser = data;
};

const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
};

const UPDATE_VIEW_WITHDRAW = (state, data) => {
    state.viewTransaction = data;
}

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}

const UPDATE_VIEW_DEPOSIT = (state, data) => {
    state.depositTransaction = data;
}
const LIST_COMPANY = (state, data) => {
    state.listCompany = data;
};
const LIST_SENIOR = (state, data) => {
    state.listSenior = data;
};
const LIST_APP = (state, data) => {
    state.listApp = data;
};
export default {
    UPDATE_LOADING,
    UPDATE_USERS,
    UPDATE_POPUP,
    UPDATE_CURRENT_USER,
    DELETE_ITEMS,
    UPDATE_VIEW_WITHDRAW,
    UPDATE_VIEW_DEPOSIT,
    LIST_COMPANY,
    LIST_SENIOR,
    LIST_APP
};
