<template>
  <div>
    <!-- {{this.objCoResult}}` -->
    <v-layout class="table-result">
      <table class="d-flex flex-row justify-content-center" >
        <tbody style="background-color: #fff; border-radius: 5px;">
        <!-- <tr v-for="r in 6" :key="r">
          <td v-for="c in channel.column" :key="c" style="padding:0px; margin:0px">
            <div v-if="c > channel.column-channel.fix_column" class="td-table-style" style="position:relative">
              <button :class="matchResult(r, c).color +' text-center'"
                      :disabled="!channel.is_open == null || !matchResult(r, c).fight_no "
                      @click="_select(r, calculateColumn(c), matchResult(r, c).ch_id)"><div class="allow justify-center text-center fight-no-style">{{matchResult(r, c).fight_no}}</div>
                      <div v-if="matchResult(r, c).pBanker" class="p-banker"></div>
                  <div v-if="matchResult(r, c).pPlayer" class="p-player"></div></button>
            </div>
          </td>
        </tr> -->
        <tr v-for="r in rows" :key="r" style="height: 50px !important;">
                          <td v-for="c in channel.column" :key="c" style="border:1px solid #000000;padding:1px;text-align: center;width:50px; height:50px !important;">
                            <div v-for="(result, i) in orderResult" :key="i" style="width:50px !important;">
                              <span v-if="c == result.col && r == result.row" style="padding:0;margin:0">
                                <button :class="matchResult(r, c).color +' text-center' + ' result-block'"
                                    :disabled="!channel.is_open == null || !matchResult(r, c).fight_no "
                                    @click="_select(r, calculateColumn(c), matchResult(r, c).ch_id)"><div class="allow justify-center text-center fight-no-style">{{matchResult(r, c).fight_no}}</div>
                                    <div v-if="matchResult(r, c).pBanker" class="p-banker"></div>
                                    <div v-if="matchResult(r, c).pPlayer" class="p-player"></div></button>
                              </span>
                            </div>                         
                          </td>                                 
                        </tr>
        </tbody>
      </table>
    </v-layout>
    <table style="width:100%; justify-contant:center;text-align:center;margin:5px;padding:100px;">
      <tr>

        <td>
          <v-btn class="mx-2" fab dark large color="#f44336">
            <span class="white--text text-h5 font-weight-bold">P</span>
          </v-btn>
        </td>
        <td>
          <v-btn class="mx-2" fab dark large color="#1876d2">
            <span class="white--text text-h5 font-weight-bold">B</span>
          </v-btn>
        </td>
        <td>
          <v-btn class="mx-2" fab dark large color="#3F9542">
            <span class="white--text text-h5 font-weight-bold">T</span>
          </v-btn>
        </td>
        <td>
          <v-btn class="mx-2" fab dark large color="#f44336">
            <span class="white--text text-h5 font-weight-bold">PB</span>
          </v-btn>
        </td>
        <td>
          <v-btn class="mx-2" fab dark large color="#1876d2">
            <span class="white--text text-h5 font-weight-bold">PP</span>
          </v-btn>
        </td>
      </tr>
      <tr>
        <td>
          <span
            style="font-family:'khmer mef1'; font-weight:bold;color:black ;font-size: 25px">{{ objSummaryCoResult.P }}</span>
        </td>
        <td>
          <span
            style="font-family:'khmer mef1'; font-weight:bold;color:black ;font-size: 25px">{{ objSummaryCoResult.B }}</span>
        </td>
        <td>
          <span
            style="font-family:'khmer mef1'; font-weight:bold;color:black ;font-size: 25px">{{ objSummaryCoResult.T }}</span>
        </td>
        <td>
          <span
            style="font-family:'khmer mef1'; font-weight:bold;color:black ;font-size: 25px">{{ objSummaryCoResult.PB }}</span>
        </td>
        <td>
          <span
            style="font-family:'khmer mef1'; font-weight:bold;color:black ;font-size: 25px">{{ objSummaryCoResult.PP }}</span>
        </td>

      </tr>
    </table>

    <!--  dialog result  -->
    <v-dialog v-model="dialogResult" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-5" v-if="objCoTypeOfBetting">
          <v-btn-toggle v-model="defineResult.result_code">
            <btn-result :value="0" :text="'B'" :subText="'anker'" :color="'cir-red'"/>
            <btn-result  :value="1" :text="'P'" :subText="'layer'" :color="'cir-blue'"/>
            <btn-result :value="2" :text="'T'" :subText="'ie'" :color="'cir-green'" />
            <btn-result :value="5" :text="'C'" :subText="'ancel'" :color="'cir-cancel'" />
          </v-btn-toggle>
        </v-card-text>
        <v-card-text class="text-center pb-12 pt-2" v-if="objCoTypeOfBetting">
          <v-btn-toggle v-model="defineResult.sub" multiple>
            <btn-result :value="3" :color="'cir-pair-banker'" :isPairBanker="true" />
            <div class="mx-5"></div>
            <btn-result :value="4" :color="'cir-pair-player'" :isPairPlayer="true" />
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cancelResult">Cancel</v-btn>
          <v-btn color="primary" @click="dialogConfirm=true">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogConfirm"
        persistent
        max-width="580"
        max-height="580"
      >
        <v-card class="text-center">
          <v-card-title class="text-h6 justify-center my-5">
            <div class="w-100" style="width:100%">
              Do you want to submit 
              <span v-if="defineResult.result_code ==1" class="wala-text bold">Player</span> 
              <span v-if="defineResult.result_code ==0" class="meron-text bold">Banker</span> 
              <span v-if="defineResult.result_code ==2" class="tie-text bold">Tie</span> 
              <span v-if="defineResult.result_code ==5" class="cancel-text bold">Cancel</span> 
            <br>
            </div>
            <div
            :class="defineResult.result_code == 1 ? ' confirm-circle wala' : defineResult.result_code == 0 ? ' confirm-circle meron' : defineResult.result_code == 2 ? ' confirm-circle tie' : defineResult.result_code == 3 ? ' confirm-circle cancel' : ''">
          </div>
          <div class="w-100" style="width:100%; margin-top:15px">
            <span v-if="defineResult.sub && (defineResult.sub[0] == 3 || defineResult.sub[1] == 3)" class="pr-5">
              <btn-result :value="3" :color="'cir-pair-banker'" :isPairBanker="true" />
            </span>
            <span v-if="defineResult.sub && (defineResult.sub[0] == 4 || defineResult.sub[1] == 4)">
              <btn-result :value="4" :color="'cir-pair-player'" :isPairPlayer="true" />
            </span>
            <br>
          </div>
           <!-- <div :class=" defineResult.result_code ==1 ? ' confirm-circle wala' :defineResult.result_code==0?' confirm-circle meron':defineResult.result_code==2?' confirm-circle tie':defineResult.result_code==3?' confirm-circle cancel':''">
           </div> -->
          </v-card-title>
          <v-card-actions>

            <v-btn
              color="grey darken-1"
              class="cancel-btn"
              @click="dialogConfirm = false"
            >
              No, Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="agree-btn"
              @click="saveResult"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import BtnResult from "../../../../components/shared/button/btn-result-bacc";

export default {
  components: {BtnResult},
  props: ['objCoResult', 'channel', 'objSummaryCoResult' , 'objCoTypeOfBetting'],
  data() {
    return {
      dialogResult: false,
      dialogConfirm:false,
      defineResult: {
        chanel_id: null,
        resultTypeId: null,
        sub: []
      },
      rows: 6,
      cols: 20
    }
  },
  computed: {
    orderResult() {
      return this.getBaccaratResults();
    },
  },
  watch: {
    objCoResult: function () {
    },
    objCoTypeOfBetting:function(){
    },
    channel: function () {
    },
    objSummaryCoResult: function (){
    }
  },
  mounted(){
    
  },
 
  methods: {
    _select(r, c, ch_id) {
      this.defineResult.rid = r
      this.defineResult.cid = c
      this.defineResult.chanel_id = ch_id
      this.dialogResult = true
    },
    //Match result column and row
    matchResult(r, c) {
      let index = 1
      let flag, code, f_no, disabled, c_id, pBanker, pPlayer;
      let newResult = this.getBaccaratResults();
      newResult.forEach(item => {
        if (item.row === r && item.col === c) {
          flag = true
          code =item.color
          f_no= item.fight_no
          c_id = item.channel_id
          pBanker = item.is_pair_banker
          pPlayer = item.is_pair_player
          if (index > this.objCoResult.length - 15 ){
             disabled = true
          }
          return false;
        }
         index= index + 1
      })
      if (flag) {
        return {
          color: code,
          fight_no: f_no,
          disable: disabled,
          ch_id : c_id,
          pBanker: pBanker,
          pPlayer: pPlayer,
        }
      } else {
        return {
          color: "no-circle",
          disable: false,
          fight_no: "",
          ch_id:''
        }
      }
    },
    getBaccaratResults() {
        let row = 1;
        let col = 1;
        let newResult = [];
        if(this.objCoResult !== null){
          let resultList = this.objCoResult;
          for (var index = resultList.length - 1; index >= 0; index--) {
            const item = resultList[index];
            if(row <= this.rows){
              newResult.push({
                ...item,
                row,
                col,
              });
              row += 1;
            }else{
              row = 1
              col += 1
              newResult.push({
                ...item,
                row,
                col,
              });
              row += 1;
            }
          }
        }
        return newResult;
    },
    saveResult() {
          this.defineResult.resultTypeId = this.objCoTypeOfBetting[this.defineResult.result_code]._id;
          this.defineResult.sub[0] == 3 || this.defineResult.sub[1] == 3 ? this.defineResult.pairBanker = this.objCoTypeOfBetting[3]._id : this.defineResult.pairBanker = null;
          this.defineResult.sub[0] == 4 || this.defineResult.sub[1] == 4 ? this.defineResult.pairPlayer = this.objCoTypeOfBetting[4]._id : this.defineResult.pairPlayer = null;

         
          this.$emit("submitResult", this.defineResult);
          this.defineResult.sub = [];
          this.dialogResult = false
          this.dialogConfirm = false
    },
  
    cancelResult() {
      this.defineResult.rid = null
      this.defineResult.cid = null
      this.defineResult.result_code = null
      this.dialogResult = false
    },
    calculateColumn(c) {
      if (this.channel.column - this.channel.fix_column > 0) {
        return c + (this.channel.column - this.channel.fix_column)
      } else {
        return c;
      }
    }
  }
}
</script>

<style scoped>
.table-result {
 background-color: #acb8bd;
  overflow-x: scroll;
  width: 100%;
  margin-top: 10px;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.td-table-style {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: solid 1px #ccc;
  text-align: center;
}

.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 47px;
  width: 47px;
  max-width: 57px;
  max-height: 57px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}

.cir-blue {
  border: 20px solid #0b4592;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 30px;
  width: 30px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 20px;
  margin: 3px 3px 2px 2px;
}

.cir-red {
  border: 20px solid #b31013;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 30px;
  width: 30px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 20px;
  margin: 3px 3px 2px 2px;
}

.cir-green {
  border: 20px solid #28a745;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 30px;
  width: 30px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 20px;
  margin: 3px 3px 2px 2px;
}

.cir-cancel {
  border: 20px solid grey;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 30px;
  width: 30px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 20px;
  margin: 3px 3px 2px 2px;
}

.no-circle {
  width: 53px;
  height: 55px;
  display: block;
}
.cancel-btn {
  color: white !important;;
  background-color:grey!important;
}
.agree-btn {
  color: white !important;;
  background-color:#3F9542!important;
}
.confirm-circle{
  width:120px; 
  height:120px;
  border-radius:100px;
  padding:20px;
  margin-top:20px;
}
.meron{
  background-color:#b31013
}
.wala{
  background-color:#0b4592
}
.tie{
  background-color:#3F9542
}
.cancel{
  background-color:gray
}
.meron-text{
  color:#b31013
}
.wala-text{
  color:#0b4592
}
.tie-text{
  color:#3F9542
}
.cancel-text{
 color:gray
}
.fight-no-style{
  margin-left: -17.5px;
  width: 35px;
  margin-top: -11px;
  font-size: 16px;
  color:white
}
.allow:hover {
  color: gold;
  font-weight: bolder;
    font-size: 29px;
    margin-top: -21px;

}
.result-block {
  position: relative;
}
.p-banker {
  position: absolute;
  border-radius: 25px;
  right: 10px;
  bottom: 12px;
  width: 10px;
  height: 10px;
  background-color: #f44336;
}

.p-player {
  position: absolute;
  border-radius: 25px;
  left: 10px;
  top: 12px;
  width: 10px;
  height: 10px;
  background-color: #1876d2;
}
</style>
