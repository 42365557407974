import channel from '@/modules/channel';
import Layout from '@/layouts/default';

const routes = [{
    path: '/channel',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: channel,
        name: 'channel'
    }]
}];

export default routes;