/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListKdPayout = async ({commit}, data) => {
    commit('UPDATE_LOADING', true)
    const response = await request.get({
        url: 'kd-payout/getAll',
        data
    });
    if (response.data.code) {
        commit('UPDATE_LIST_KDPAYOUT', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};


const updateKdPayout = async ({commit}, {vm, data, _id}) => {
    const response = await request.post({
        url: 'kd-payout/update/'+_id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};

// const updateSuspendUser = async ({commit}, {vm, data}) => {
//     // console.log("akdshf", data)
//     const response = await request.post({
        
//         url: 'users/updatePlayerSuspendStatus' ,
//         data
//     });
//     if (response.data.code) {
//         // console.log(response.data)
//         vm.$toastr.s(`${response.data.message.descriptions}`)
//     } else vm.$toastr.e(`${response.data.message.descriptions}`)
// };

export default {
    fetchListKdPayout,
    updateKdPayout
    // updateSuspendUser
    // balanceUser
};
