<template>
  <div>
    <v-row class="justify-center py-5 cm-title" style="">
      <v-btn @click="getData()" color="primary" :disabled="loader" class="mt-2 mr-5 ">
        refresh 
      </v-btn>
      <span>Channel Manager</span>

    </v-row>

    <!--    Display List Front Page-->
    <v-row style="margin-top:150px" elevation="24">
      <v-col cols="6" md="3" sm="3" class="my-5 w-100 text-center">
        <span class="text-h5">Channel 1</span>
        <v-btn
          :class="
            ch[0] && ch[0].enable ? 'success mb-3 ml-3' : 'error mb-3 ml-3'
          "
          @click="_updateChanel(ch[0], 1)"
          >{{
            ch[0] && ch[0].enable
              ? "Enabled"
              : ch[0] && !ch[0].enable
              ? "Disbaled"
              : "Loading...."
          }}</v-btn
        >
        <br />
        fight no:
        <span class="fino">{{ ch[0] ? ch[0].fight_no : "Loading" }}</span>
        <v-data-table
          :headers="headers1"
          :items="StatementObj"
          :server-items-length="3"
          item-key="currency"
          class="elevation-1"
          style="font-family: 'khmer mef1'; border:1px solid black; border-right:0px"
          mobile-breakpoint="0"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header="{ props }">
            <template
              v-for="(headers1, i1) in [processTableHeaders(props.headers)]"
            >
              <tr :key="i1">
                <th></th>
                <th
                  v-for="header in headers1"
                  :key="header.value"
                  :width="header.width"
                  style="padding: 30px 0px 20px 0px; font-size:18px"
                  :class="
                    header.text == 'Meron'
                      ? 'lose-color'
                      : header.text == 'Wala'
                      ? 'win-color'
                      : ''
                  "
                >
                  {{ header.text }}
                </th>
              </tr>
            </template></template
          >
          <template v-slot:[`item`]="{ item }">
            <tr v-if="item">
  
              <td class="text-center">
                {{ item.currency }}
              </td>
              <td
                class="text-center lose-color  text-decoration-underline row-pointer"
                @click="_goToDetail(item.m1)"
              >
                {{
                  item.m1.currency_type == 1 || item.m1.currency_type == 3
                    ? currencyFormatEn(item.m1.amount)
                    : currencyFormatKm(item.m1.amount)
                }}
              </td>
                <td
                class="text-center tie-color  text-decoration-underline row-pointer"
                @click="_goToDetail(item.t1)"
              >
                {{
                  item.t1.currency_type == 1 || item.t1.currency_type == 3
                    ? currencyFormatEn(item.t1.amount)
                    : currencyFormatKm(item.t1.amount)
                }}
              </td>
              <td
                class="text-center win-color text-decoration-underline row-pointer"
                @click="_goToDetail(item.w1)"
              >
                {{
                  item.w1.currency_type == 1 || item.w1.currency_type == 3
                    ? currencyFormatEn(item.w1.amount)
                    : currencyFormatKm(item.w1.amount)
                }}
              </td>
            </tr>
          </template>
          <template slot="body.append">
            <tr>
              <td style="text-align:center " colspan="9" v-if="loader">
                <div class="lds-roller my-3">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-row class="mt-5 mx-0 mb-3 justify-center">
          <span class="text-h6 mt-1">ការបង្ហាញឈ្មោះ </span>
           <v-btn
          :class="
            ch[0] && ch[0].is_show_username ? 'error mb-3 ml-3 ' : 'success mb-3 ml-3 '
          "
          @click="_updateIsShowUserName(ch[0], 1)"
          >{{
            ch[0] && ch[0].is_show_username
              ? "បានបិទ"
              : ch[0] && !ch[0].is_show_username
              ? "កំពុងបង្ហាញ"
              : "Loading...."
          }}</v-btn
        >
        </v-row>
          <hr class="w-100 mx-4">
         <v-row class="mt-5 justify-center">
          <span class="text-h6 mr-3 mt-1">កំណត់ Stream Url </span>
           <v-btn
          class="
           warning mb-3 ml-3'
          "
          @click="_showDialogUpdateStreamUrl(ch[0], 1)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        </v-row>
         
        
      </v-col>
      <v-col cols="6" md="3" sm="3" class="my-5 w-100 text-center">
        <span class="text-h5">Channel 2</span>
        <v-btn
          :class="
            ch[1] && ch[1].enable ? 'success mb-3 ml-3' : 'error mb-3 ml-3'
          "
          @click="_updateChanel(ch[1], 2)"
          >{{
            ch[1] && ch[1].enable
              ? "Enabled"
              : ch[1] && !ch[1].enable
              ? "Disbaled"
              : "Loading...."
          }}</v-btn
        >
        <br />
        fight no:
        <span class="fino">{{ ch[1] ? ch[1].fight_no : "Loading" }}</span>
        <v-data-table
          :headers="headers2"
          :items="StatementObj"
          :server-items-length="3"
          item-key="currency"
          class="elevation-1"
          style="font-family: 'khmer mef1';  border:1px solid black; border-right:0px; border-left:0px"
          mobile-breakpoint="0"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header="{ props }">
            <template
              v-for="(headers2, i2) in [processTableHeaders(props.headers)]"
            >
              <tr :key="i2">
                <th
                  v-for="header in headers2"
                  :key="header.value"
                  :width="header.width"
                  style="padding: 30px 0px 20px 0px; font-size:18px"
                  :class="
                    header.text == 'Meron'
                      ? 'lose-color'
                      : header.text == 'Wala'
                      ? 'win-color'
                      : ''
                  "
                >
                  {{ header.text }}
                </th>
              </tr>
            </template></template
          >
          <template v-slot:[`item`]="{ item }">
            <tr v-if="item">
              <td
                class="text-center lose-color  text-decoration-underline row-pointer"
                @click="_goToDetail(item.m2)"
              >
                {{
                  item.m2.currency_type == 1 || item.m2.currency_type == 3
                    ? currencyFormatEn(item.m2.amount)
                    : currencyFormatKm(item.m2.amount)
                }}
              </td>
              <td
                class="text-center tie-color  text-decoration-underline row-pointer"
                @click="_goToDetail(item.t2)"
              >
                {{
                  item.t2.currency_type == 1 || item.t2.currency_type == 3
                    ? currencyFormatEn(item.t2.amount)
                    : currencyFormatKm(item.t2.amount)
                }}
              </td>
              <td
                class="text-center win-color text-decoration-underline row-pointer"
                @click="_goToDetail(item.w2)"
              >
                {{
                  item.w2.currency_type == 1 || item.w2.currency_type == 3
                    ? currencyFormatEn(item.w2.amount)
                    : currencyFormatKm(item.w2.amount)
                }}
              </td>
              <!-- <td class="win-color">{{ currencyFormat(item.amount_win) }}</td> -->
              <!-- <td :class="item.amount_win  < 0 ? 'lose-color' : 'win-color'">{{ currencyFormat(item.amount_win ) }}</td> -->
            </tr>
          </template>
          <template slot="body.append">
            <tr>
              <td style="text-align:center " colspan="9" v-if="loader">
                <div class="lds-roller my-3">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
           <v-row class="mt-5 mx-0 justify-center mb-3">
          <span class="text-h6 mt-1">ការបង្ហាញឈ្មោះ </span>
           <v-btn
          :class="
            ch[1] && ch[1].is_show_username ? 'error mb-3 ml-3 ' : ' success mb-3 ml-3 '
          "
          @click="_updateIsShowUserName(ch[1], 2)"
          >{{
            ch[1] && ch[1].is_show_username
              ? "បានបិទ"
              : ch[1] && !ch[1].is_show_username
              ? "កំពុងបង្ហាញ"
              : "Loading...."
          }}</v-btn
        >
        </v-row>
          <hr class="w-100 mx-4">
         <v-row class="mt-5 justify-center">
          <span class="text-h6 mr-3 mt-1">កំណត់ Stream Url </span>
           <v-btn
          class="
           warning mb-3 ml-3'
          "
          @click="_showDialogUpdateStreamUrl(ch[1], 2)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        </v-row>
      </v-col>
      <v-col cols="6" md="3" sm="3" class="my-5  w-100 text-center">
        <span class="text-h5">Channel 3</span>
        <v-btn
          @click="_updateChanel(ch[2], 3)"
          :class="
            ch[2] && ch[2].enable ? 'success mb-3 ml-3' : 'error mb-3 ml-3'
          "
          >{{
            ch[2] && ch[2].enable
              ? "Enabled"
              : ch[2] && !ch[2].enable
              ? "Disbaled"
              : "Loading...."
          }}</v-btn
        >
        <br />
        fight no:
        <span class="fino">{{ ch[2] ? ch[2].fight_no : "Loading" }}</span>
        <v-data-table
          :headers="headers3"
          :items="StatementObj"
          :server-items-length="3"
          item-key="currency"
          class="elevation-1"
          style="font-family: 'khmer mef1';  border:1px solid black; border-right:0px; border-left:0px"
          mobile-breakpoint="0"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header="{ props }">
            <template
              v-for="(headers3, i3) in [processTableHeaders(props.headers)]"
            >
              <tr 
                :key="i3">
                <th
                  v-for="header in headers3"
                  :key="header.value"
                  :width="header.width"
                  style="padding: 30px 0px 20px 0px; font-size:18px"
                  :class="
                    header.text == 'Meron'
                      ? 'lose-color'
                      : header.text == 'Wala'
                      ? 'win-color'
                      : ''
                  "
                >
                  {{ header.text }}
                </th>
              </tr>
            </template>
          </template>
          <template v-slot:[`item`]="{ item }">
            <tr v-if="item">
              <td
                class="text-center lose-color  text-decoration-underline row-pointer"
                @click="_goToDetail(item.m3)"
              >
                {{
                  item.m3.currency_type == 1 || item.m3.currency_type == 3
                    ? currencyFormatEn(item.m3.amount)
                    : currencyFormatKm(item.m3.amount)
                }}
              </td>
                     <td
                class="text-center tie-color  text-decoration-underline row-pointer"
                @click="_goToDetail(item.t3)"
              >
                {{
                  item.t3.currency_type == 1 || item.t3.currency_type == 3
                    ? currencyFormatEn(item.t3.amount)
                    : currencyFormatKm(item.t3.amount)
                }}
              </td>
              <td
                class="text-center win-color text-decoration-underline row-pointer"
                @click="_goToDetail(item.w3)"
              >
                {{
                  item.w3.currency_type == 1 || item.w3.currency_type == 3
                    ? currencyFormatEn(item.w3.amount)
                    : currencyFormatKm(item.w3.amount)
                }}
              </td>
            </tr>
          </template>
          <template slot="body.append">
            <tr>
              <td style="text-align:center " colspan="9" v-if="loader">
                <div class="lds-roller my-3">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
                <v-row class="mt-5 mx-0 justify-center mb-3">
          <span class="text-h6 mt-1">ការបង្ហាញឈ្មោះ </span>
           <v-btn
          :class="
            ch[2] && ch[2].is_show_username ? ' error mb-3 ml-3 ' : 'success mb-3 ml-3 '
          "
          @click="_updateIsShowUserName(ch[2], 3)"
          >{{
            ch[2] && ch[2].is_show_username
              ? "បានបិទ"
              : ch[2] && !ch[2].is_show_username
              ? "កំពុងបង្ហាញ"
              : "Loading...."
          }}</v-btn
        >
        </v-row>
        <hr class="w-100 mx-4">
         <v-row class="mt-5 justify-center">
          <span class="text-h6 mr-3 mt-1">កំណត់ Stream Url </span>
           <v-btn
          class="
           warning mb-3 ml-3'
          "
          @click="_showDialogUpdateStreamUrl(ch[2], 3)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        </v-row>
      </v-col>
      <v-col cols="6" md="3" sm="3" class="my-5 w-100 text-center">
        <span class="text-h5">Channel 4</span>
        <v-btn
          @click="_updateChanel(ch[3], 4)"
          :class="
            ch[3] && ch[3].enable ? 'success mb-3 ml-3' : 'error mb-3 ml-3'
          "
          >{{
            ch[3] && ch[3].enable
              ? "Enabled"
              : ch[3] && !ch[3].enable
              ? "Disbaled"
              : "Loading...."
          }}</v-btn
        >
        <br />
        fight no:
        <span class="fino">{{ ch[3] ? ch[3].fight_no : "Loading" }}</span>
        <v-data-table
          :headers="headers4"
          :items="StatementObj"
          :server-items-length="3"
          item-key="currency"
          class="elevation-1"
          style="font-family: 'khmer mef1'; border:1px solid black; border-left:0px"
          mobile-breakpoint="0"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header="{ props }">
            <template
              v-for="(headers4, i4) in [processTableHeaders(props.headers)]"
            >
              <tr :key="i4">
                <th
                  v-for="header in headers4"
                  :key="header.value"
                  :width="header.width"
                  style="padding: 30px 0px 20px 0px; font-size:18px"
                  :class="
                    header.text == 'Meron'
                      ? 'lose-color'
                      : header.text == 'Wala'
                      ? 'win-color'
                      : ''
                  "
                >
                  {{ header.text }}
                </th>
              </tr>
            </template>
          </template>
          <template v-slot:[`item`]="{ item }">
            <tr>
              <td
                class="text-center lose-color text-decoration-underline row-pointer"
                @click="_goToDetail(item.m4)"
              >
                {{
                  item.m4.currency_type == 1 || item.m4.currency_type == 3
                    ? currencyFormatEn(item.m4.amount)
                    : currencyFormatKm(item.m4.amount)
                }}
              </td>
                     <td
                class="text-center tie-color  text-decoration-underline row-pointer"
                @click="_goToDetail(item.t4)"
              >
                {{
                  item.t4.currency_type == 1 || item.t4.currency_type == 3
                    ? currencyFormatEn(item.t4.amount)
                    : currencyFormatKm(item.t4.amount)
                }}
              </td>
              <td
                class="text-center win-color text-decoration-underline row-pointer"
                @click="_goToDetail(item.w4)"
              >
                {{
                  item.w4.currency_type == 1 || item.w4.currency_type == 3
                    ? currencyFormatEn(item.w4.amount)
                    : currencyFormatKm(item.w4.amount)
                }}
              </td>
            </tr>
          </template>
          <template slot="body.append">
            <tr>
              <td style="text-align:center " colspan="9" v-if="loader">
                <div class="lds-roller my-3">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
                   <v-row class="mt-5 mx-0 justify-center mb-3">
          <span class="text-h6 mt-1">ការបង្ហាញឈ្មោះ </span>
           <v-btn
          :class="
            ch[3] && ch[3].is_show_username ? 'error mb-3 ml-3 ' : ' success mb-3 ml-3 '
          "
          @click="_updateIsShowUserName(ch[3], 4)"
          >{{
            ch[3] && ch[3].is_show_username
              ? "បានបិទ"
              : ch[3] && !ch[3].is_show_username
              ? "កំពុងបង្ហាញ"
              : "Loading...."
          }}</v-btn
        >
        </v-row>
         <hr class="w-100 mx-4">
         <v-row class="mt-5 justify-center">
          <span class="text-h6 mr-3 mt-1">កំណត់ Stream Url </span>
           <v-btn
          class="
           warning mb-3 ml-3'
          "
          @click="_showDialogUpdateStreamUrl(ch[3],4)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        </v-row>
      </v-col>
    </v-row>
    <!--    Detail Each Match -->
    <v-dialog
      :value="dialog"
      max-width="1200"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="12" sm="12" md="12" class="text-left mt-4 mb-0 pb-0">
              <v-toolbar-title
                >Detail Player Statement
                <span style="margin-left:20px;"></span> ( Channel Type:
                <span
                  style="margin-left:10px; color:#6699cc; font-size:24px; font-weight:bold "
                  >{{ dataRequest.channelType == 1 ? "1" : dataRequest.channelType == 2 ? "2" : dataRequest.channelType }} </span
                >)</v-toolbar-title
              >
            </v-col>

            <v-col cols="8" sm="8" md="8" class="text-right mb-4">
              <v-text-field
                v-model="v_search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="mr-5"
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" sm="2" md="2" class="text-right">
              <v-btn
                class="white--text btn-custom mr-4 mt-3"
                color="green darken-1"
                depressed
                @click="searchq"
              >
                <!-- @click="fetchListStatement(params)" -->
                SEARCH
              </v-btn>
            </v-col>
            <v-col cols="2" sm="2" md="2" class="text-right">
              <v-btn
                class="white--text btn-custom mr-4 mt-3"
                color="green darken-1"
                depressed
                @click="searchq"
              >
                <!-- @click="fetchListStatement(params)" -->
                Refresh
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            style="font-family: 'khmer mef1'"
            class="elevation-1"
            item-key="date"
            :headers="headers"
            :footer-props="footerProps"
            :items="detailData ? detailData : []"
            :server-items-length="
              detailDataCount ? parseInt(detailDataCount.count) : null
            "
            :sort-desc.sync="dataRequest.descendingFormat"
            :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page"
            :items-per-page.sync="dataRequest.rowsPerPage"
            @update:page="updatePage"
            @update:items-per-page="updatePage"
            @update:sort-desc="updatePage"
            :loading="getLoading"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>
                  {{
                    detailData
                      ? detailData.indexOf(item) +
                        (dataRequest.page - 1) * dataRequest.rowsPerPage +
                        1
                      : "loading ..."
                  }}
                </td>
                <td>{{ item.app_id }}</td>
                <td>{{ item.public_ip }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.user_name }}</td>
                <td>{{ item.public_ip }}</td>
                <td>{{ item.fight_no }}</td>
                <td :class="item.co_type_of_betting.color">
                  {{ item.co_type_of_betting.name }}
                </td>
                <td class="amount-bet-color">
                  {{
                    dataRequest && dataRequest.currencyType == 2
                      ? currencyFormatKm(item.amount)
                      : dataRequest.currencyType &&
                        dataRequest.currencyType != 2
                      ? currencyFormatEn(item.amount)
                      : "Loading ..."
                  }}
                </td>
                <td class="font-weight-bold">
                  {{ currencyFormatEn(item.payout) }}
                </td>
              </tr>
            </template>
            <template slot="body.append">
              <td :colspan="7" class="text-xs-center pl-4 py-2 ">
                {{ $t("title.total") }}
              </td>
              <td class="text-xs-center total-style pl-4  py-2">
                {{
                  dataRequest &&
                  dataRequest.currencyType == 2 &&
                  detailDataCount
                    ? currencyFormatKm(detailDataCount.amount)
                    : dataRequest.currencyType &&
                      dataRequest.currencyType != 2 &&
                      detailDataCount
                    ? currencyFormatEn(detailDataCount.amount)
                    : "Loading ..."
                }}
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5 py-5">
          {{
            targetChannel && targetChannel.enable
              ? "បិទដំណើរការ Channel "
              : "បើកដំណើរការ Channel "
          }}
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="_closeDialog"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2 white--text"
            :color="targetChannel && targetChannel.enable ? 'error' : 'success'"
            @click="_takeAction"
            >{{
              targetChannel && targetChannel.enable
                ? "Inactive Channel"
                : "Active Channel"
            }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-dialog v-model="confirmDialogIsShowUserName" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5 py-5">
          {{
            selectedChannel && selectedChannel.is_show_username
              ? "បិទការបង្ហាញ ឈ្មោះ"
              : "បើកការបង្ហាញ ឈ្មោះ"
          }}
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="_closeDialogIsShowUserName"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2 white--text"
            :color="selectedChannel && selectedChannel.is_show_username ? 'success' : 'error'"
            @click="_takeActionIsShowUserName"
            >{{
              selectedChannel && selectedChannel.is_show_username
                ? "បើកការបង្ហាញ ឈ្មោះ"
                : "បិទការបង្ហាញ ឈ្មោះ"
            }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogUpdateStream
      :dialogUpdateStream="dialogUpdateStream"
      :selectedUpdateChanel="selectedUpdateChanel"
      :loading="getLoading"
      v-on:submit="_handleSubmit"
      v-on:closeDialogUpdateStream="_closeDialogUpdateStream"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
  components: {
      DialogUpdateStream: () => import("./_components/DialogUpdateStream.vue"),
  },

  data: () => ({
    v_search: null,
    getLoading: true,
    headers: [
      {
        text: "ល.រ",
        align: "start",
        value: "no",
      },
         { text: "កម្មវិធី", value: "app_id" },
         { text: "IP", value: "public_ip" },
      { text: "កាលបរិច្ឆេទ", value: "date" },
      { text: "ឈ្មោះ", value: "user_name" },
      { text: "IP", value: "public_ip" },
      { text: "ប្រកួតលេខ", value: "fight_no" },
      { text: "ប្រភេទចាក់", value: "co_type_of_betting.name" },
      { text: "ប្រាក់ចាក់", value: "amount" },
      { text: "Payout", value: "payout" },
    ],
    headers1: [
      {
        text: "Meron",
        value: "Meron_Ch1",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
      {
        text: "Wala",
        value: "Wala_Ch1",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
    ],
    headers2: [
      {
        text: "Meron",
        value: "Meron_Ch2",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
      {
        text: "Wala",
        value: "Wala_Ch2",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
    ],
    headers3: [
      {
        text: "Meron",
        value: "Meron_Ch3",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
      {
        text: "Wala",
        value: "Wala_Ch3",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
    ],
    headers4: [
      {
        text: "Meron",
        value: "Meron_Ch4",
      },
      {
        text: "Wala",
        value: "Wala_Ch4",
      },
    ],
    StatementObj: [
      {
        currency: "$",
        m1: 0,
        w1: 0,
        m2: 0,
        w2: 0,
        m3: 0,
        w3: 0,
        m4: 0,
        w4: 0,
        t1:0,t2:0,t3:0,t4:0
      },
      {
        currency: "៛",
        m1: 0,
        w1: 0,
        m2: 0,
        w2: 0,
        m3: 0,
        w3: 0,
        m4: 0,
        w4: 0,
         t1:0,t2:0,t3:0,t4:0
      },
      {
        currency: "Baht",
        m1: 0,
        w1: 0,
        m2: 0,
        w2: 0,
        m3: 0,
        w3: 0,
        m4: 0,
        w4: 0,
         t1:0,t2:0,t3:0,t4:0
      },
    ],
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    dataRequest: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "user_name",
        "co_type_of_betting.name",
        "co_type_of_betting.desc",
        "co_type_of_betting.color",
        "date",
        "fight_no",
        "is_win",
        "amount",
        "amount_win",
        "cast",
        "app_id",
        "public_ip"
      ],
      search: "",
      fightNo: null,
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "co_type_of_betting.name",
        "co_type_of_betting.desc",
        "co_type_of_betting.color",
        "date",
        "fight_no",
        "is_win",
        "amount",
        "amount_win",
        "cast",
      ],
      search: "",
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
    },
    dialog: false,
    loading: false,
    loader: false,
    detailData: [],
    detailDataCount: [],
    ch: [],
    confirmDialog: false,
    targetChannel: [],
    channel_type: null,
    selectedChannel:[],
    confirmDialogIsShowUserName:false,
    dialogUpdateStream:false,
    selectedUpdateChanel:[]
  }),
  watch: {
    statementC: function(newV) {
      if (newV) this.getFirstData();
    },
    StatementObj: function() {},
    ch: function() {},
  },
  computed: {
    ...mapGetters("$_modules", { user: "getUser" }),
    ...mapGetters("$_controllerForManager", {
      statementC: "getCStatement",
      channels: "getChannel",
    }),
  },
  async created() {
    this.getData();
    this.loadChannel();
  },
  async mounted() {
    // this.$socket1.on("client-current-game", (data) => {
    //   if (data && data.channel_type == 1) {
    //     if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         // this.StatementObj[0].m1 = data.amount;
    //         {
    //           (this.StatementObj[0].m1 = {
    //             amount: data.amount,
    //             channel_type: data.channel_type,
    //             betting_type: data.betting_type,
    //             currency_type: data.currency_type,
    //             fight_no: data.fight_no,
    //           }),
    //             (this.ch[0].fight_no = data.fight_no);
    //         }
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         // this.StatementObj[1].m1 = data.amount;
    //         {
    //           (this.StatementObj[1].m1 = {
    //             amount: data.amount,
    //             channel_type: data.channel_type,
    //             betting_type: data.betting_type,
    //             currency_type: data.currency_type,
    //             fight_no: data.fight_no,
    //           }),
    //             (this.ch[0].fight_no = data.fight_no);
    //         }
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[2].t1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    //    if (data && data.channel_type == 2) {
    //     if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[0].m2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[1].m2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[2].t2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    //   if (data && data.channel_type == 3) {
    //      if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[0].m3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[1].m3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    //    if (data && data.channel_type == 4) {
    //      if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[0].m4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[1].m4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[2].t4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    // });
    // this.$socket2.on("client-current-game", (data) => {
    //   if (data && data.channel_type == 1) {
    //     if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         // this.StatementObj[0].m1 = data.amount;
    //         {
    //           (this.StatementObj[0].m1 = {
    //             amount: data.amount,
    //             channel_type: data.channel_type,
    //             betting_type: data.betting_type,
    //             currency_type: data.currency_type,
    //             fight_no: data.fight_no,
    //           }),
    //             (this.ch[0].fight_no = data.fight_no);
    //         }
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         // this.StatementObj[1].m1 = data.amount;
    //         {
    //           (this.StatementObj[1].m1 = {
    //             amount: data.amount,
    //             channel_type: data.channel_type,
    //             betting_type: data.betting_type,
    //             currency_type: data.currency_type,
    //             fight_no: data.fight_no,
    //           }),
    //             (this.ch[0].fight_no = data.fight_no);
    //         }
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[2].t1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    //    if (data && data.channel_type == 2) {
    //     if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[0].m2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[1].m2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[2].t2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    //   if (data && data.channel_type == 3) {
    //      if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[0].m3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[1].m3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    //    if (data && data.channel_type == 4) {
    //      if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[0].m4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[1].m4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[2].t4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    // });
    // this.$socket3.on("client-current-game", (data) => {
    //   if (data && data.channel_type == 1) {
    //     if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         // this.StatementObj[0].m1 = data.amount;
    //         {
    //           (this.StatementObj[0].m1 = {
    //             amount: data.amount,
    //             channel_type: data.channel_type,
    //             betting_type: data.betting_type,
    //             currency_type: data.currency_type,
    //             fight_no: data.fight_no,
    //           }),
    //             (this.ch[0].fight_no = data.fight_no);
    //         }
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         // this.StatementObj[1].m1 = data.amount;
    //         {
    //           (this.StatementObj[1].m1 = {
    //             amount: data.amount,
    //             channel_type: data.channel_type,
    //             betting_type: data.betting_type,
    //             currency_type: data.currency_type,
    //             fight_no: data.fight_no,
    //           }),
    //             (this.ch[0].fight_no = data.fight_no);
    //         }
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[2].t1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    //    if (data && data.channel_type == 2) {
    //     if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[0].m2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[1].m2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[2].t2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    //   if (data && data.channel_type == 3) {
    //      if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[0].m3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[1].m3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    //    if (data && data.channel_type == 4) {
    //      if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[0].m4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[1].m4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[2].t4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    // });
    // this.$socket4.on("client-current-game", (data) => {
    //   if (data && data.channel_type == 1) {
    //     if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         // this.StatementObj[0].m1 = data.amount;
    //         {
    //           (this.StatementObj[0].m1 = {
    //             amount: data.amount,
    //             channel_type: data.channel_type,
    //             betting_type: data.betting_type,
    //             currency_type: data.currency_type,
    //             fight_no: data.fight_no,
    //           }),
    //             (this.ch[0].fight_no = data.fight_no);
    //         }
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         // this.StatementObj[1].m1 = data.amount;
    //         {
    //           (this.StatementObj[1].m1 = {
    //             amount: data.amount,
    //             channel_type: data.channel_type,
    //             betting_type: data.betting_type,
    //             currency_type: data.currency_type,
    //             fight_no: data.fight_no,
    //           }),
    //             (this.ch[0].fight_no = data.fight_no);
    //         }
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[2].t1 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[0].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    //    if (data && data.channel_type == 2) {
    //     if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[0].m2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[1].m2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[2].t2 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[1].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    //   if (data && data.channel_type == 3) {
    //      if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[0].m3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[1].m3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t3 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[2].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    //    if (data && data.channel_type == 4) {
    //      if (data.currency_type == 1) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[0].m4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[0].w4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[0].t4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 2) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[1].m4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[1].w4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[1].t4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //     }
    //     if (data.currency_type == 3) {
    //       if (data.betting_type == "meron") {
    //         (this.StatementObj[2].m4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //       if (data.betting_type == "wala") {
    //         (this.StatementObj[2].w4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //        if (data.betting_type == "tie") {
    //         (this.StatementObj[2].t4 = {
    //           amount: data.amount,
    //           channel_type: data.channel_type,
    //           betting_type: data.betting_type,
    //           currency_type: data.currency_type,
    //           fight_no: data.fight_no,
    //         }),
    //           (this.ch[3].fight_no = data.fight_no);
    //       }
    //     }
    //   }
    // });  
  },
  methods: {
    processTableHeaders(headers) {
      const nested = !!headers.some((h) => h.children);

      if (nested) {
        let children = [];

        const h = headers.map((h) => {
          if (h.children && h.children.length > 0) {
            children.push(...h.children);

            return {
              rowspan: 1,
              colspan: h.children.length,
              text: h.text,
              align: h.align,
            };
          }
          return {
            rowspan: 2,
            colspan: 1,
            text: h.text,
            align: h.align,
          };
        });

        return {
          children: children,
          parents: h,
        };
      }
      return {
        parents: headers,
      };
    },
    moment,
    currencyFormatEn(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    currencyFormatKm(num) {
      num = parseFloat(num);
      return num.toLocaleString("en");
    },
    _goToDetail(selectedData) {
      this.detailData = [];
      this.detailDataCount = [];
      let bt;
      if (
        selectedData.betting_type == "meron" ||
        selectedData.betting_type == "MERON"
      )
        bt = "MERON";
      if (
        selectedData.betting_type == "wala" ||
        selectedData.betting_type == "WALA"
      )
        bt = "WALA";
      if (
        selectedData.betting_type == "tie" ||
        selectedData.betting_type == "TIE"
      )
        bt = "TIE";
      this.dataRequest.bettingType = bt;
      this.dataRequest.channelType = selectedData.channel_type;
      this.dataRequest.currencyType = selectedData.currency_type;
      this.dataRequest.fightNo = selectedData.fight_no;
      // console.log(this.dataRequest)
      this.loadDetailData();
      this.dialog = true;
    },
    async loadDetailData() {
      this.getLoading = true;
      this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);
      await this.$request
        .post({
          url: "adminReport/getViewCurrentBetting",
          data: this.dataRequest,
        })
        .then((res) => {
          if (res.data.code) {
            this.detailData = res.data.data.object;
            this.detailDataCount = res.data.data.objectCount;
            this.getLoading = false;
          } else {
            this.getLoading = false;
          }
        });
    },
    async getData() {
      try {
        this.loader = true;
        await this.fetchListStatement();
        this.loader = false;
      } catch (error) {
        // console.log(error)
      }
    },
    getFirstData() {
       if (this.statementC) {
        for (let i = 0; i < this.statementC.object.length; i++) {
          if (this.statementC.object[i]._id.channel_type == 1) {
            if (this.statementC.object[i]._id.currency_type == 1) {
              if (this.statementC.object[i]._id.betting_type == "meron")
                this.StatementObj[0].m1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "wala")
                this.StatementObj[0].w1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "tie")
                this.StatementObj[0].t1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
            }
            if (this.statementC.object[i]._id.currency_type == 2) {
              if (this.statementC.object[i]._id.betting_type == "meron") {
                this.StatementObj[1].m1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              }
              if (this.statementC.object[i]._id.betting_type == "wala") {
                this.StatementObj[1].w1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              }
                if (this.statementC.object[i]._id.betting_type == "tie")
                this.StatementObj[1].t1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
            }
            if (this.statementC.object[i]._id.currency_type == 3) {
              if (this.statementC.object[i]._id.betting_type == "meron")
                this.StatementObj[2].m1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "wala")
                this.StatementObj[2].w1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "tie")
                this.StatementObj[2].t1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
            }
          }
          if (this.statementC.object[i]._id.channel_type == 2) {
            if (this.statementC.object[i]._id.currency_type == 1) {
              if (this.statementC.object[i]._id.betting_type == "meron")
                this.StatementObj[0].m2 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "wala")
                this.StatementObj[0].w2 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "tie")
                this.StatementObj[0].t2 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
            }
            if (this.statementC.object[i]._id.currency_type == 2) {
              if (this.statementC.object[i]._id.betting_type == "meron")
                this.StatementObj[1].m2 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "wala")
                this.StatementObj[1].w2 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "tie")
                this.StatementObj[1].t2 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
            }
            if (this.statementC.object[i]._id.currency_type == 3) {
              if (this.statementC.object[i]._id.betting_type == "meron")
                this.StatementObj[2].m2 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "wala")
                this.StatementObj[2].w2 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "tie")
                this.StatementObj[2].t2 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
            }
          }
          if (this.statementC.object[i]._id.channel_type == 3) {
            if (this.statementC.object[i]._id.currency_type == 1) {
              if (this.statementC.object[i]._id.betting_type == "meron")
                this.StatementObj[0].m3 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "wala")
                this.StatementObj[0].w3 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "tie")
                this.StatementObj[0].t3 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
            }
            if (this.statementC.object[i]._id.currency_type == 2) {
              if (this.statementC.object[i]._id.betting_type == "meron")
                this.StatementObj[1].m3 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "wala")
                this.StatementObj[1].w3 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "tie")
                this.StatementObj[1].t3 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
            }
            if (this.statementC.object[i]._id.currency_type == 3) {
              if (this.statementC.object[i]._id.betting_type == "meron")
                this.StatementObj[2].m3 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "wala")
                this.StatementObj[2].w3 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "tie")
                this.StatementObj[2].t3 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
            }
          }
          if (this.statementC.object[i]._id.channel_type == 4) {
            if (this.statementC.object[i]._id.currency_type == 1) {
              if (this.statementC.object[i]._id.betting_type == "meron")
                this.StatementObj[0].m4 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "wala")
                this.StatementObj[0].w4 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "tie")
                this.StatementObj[0].t4 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
            }
            if (this.statementC.object[i]._id.currency_type == 2) {
              if (this.statementC.object[i]._id.betting_type == "meron")
                this.StatementObj[1].m4 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "wala")
                this.StatementObj[1].w4 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "tie")
                this.StatementObj[1].t4 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
            }
            if (this.statementC.object[i]._id.currency_type == 3) {
              if (this.statementC.object[i]._id.betting_type == "meron")
                this.StatementObj[2].m4 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "wala")
                this.StatementObj[2].w4 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "tie")
                this.StatementObj[2].t4 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
            }
          }
        }
      }
    },
    async loadChannel() {
      this.getLoading = true;
      await this.$request
        .get({
          url: "coChannel/getCurrentChannel",
          data: this.dataRequest,
        })
        .then((res) => {
          if (res.data.code) {
            
            this.ch = res.data.data.objCurrentChannel;
            // this.detailDataCount = res.data.data.objectCount;
            this.getLoading = false;
          } else {
            this.getLoading = false;
          }
        });
    },
    searchq() {
      this.getLoading = true;
      this.dataRequest.search = this.v_search;
      this.updatePage();
    },
    updatePage() {
      this.getLoading = true;
      this.loadDetailData();
      this.getLoading = false;
    },
    _updateChanel(ch, channelType) {
      this.targetChannel = ch;
      this.channel_type = channelType;
      this.confirmDialog = true;
    },

     _updateIsShowUserName(ch, channelType) {
      this.selectedChannel = ch;
      this.channel_type = channelType;
      this.confirmDialogIsShowUserName = true;
    },

    _closeDialog() {
      this.targetChannel = [];
      this.channel_type = null;
      this.confirmDialog = false;
    },
     _closeDialogIsShowUserName() {
      this.selectedChannel = [];
      this.channel_type = null;
      this.confirmDialogIsShowUserName = false;
    },
    async _takeAction() {
      let data = {
        enable: !this.targetChannel.enable,
        channel_type: this.channel_type,
      };
      // console.log("_takeAction", this.targetChannel, this.channel_type)
      await this.$request
        .post({
          url: "coChannel/updateEnableStatusChannel",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.loadChannel();
            this.getLoading = false;
          } else {
            // console.log(res.data)
            this.getLoading = false;
          }
        });
      // console.log("_takeAction", this.targetChannel, this.channel_type)
      this.targetChannel = [];
      this.channel_type = null;
      this.confirmDialog = false;
    },
    async _takeActionIsShowUserName() {
      let data = {
        is_show_username: !this.selectedChannel.is_show_username,
        channel_type: this.channel_type,
      };
      await this.$request
        .post({
          url: "coChannel/updateIsShowUsernameChannel",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.loadChannel();
            this.getLoading = false;
          } else {
            // console.log(res.data)
            this.getLoading = false;
          }
        });
      // console.log("_takeAction", this.targetChannel, this.channel_type)
      this.selectedChannel = [];
      this.channel_type = null;
      this.confirmDialogIsShowUserName = false;
    },
    _showDialogUpdateStreamUrl(selectedItem, channelType){
      this.selectedUpdateChanel = selectedItem;
      this.selectedUpdateChanel.channel_type = channelType;
      this.dialogUpdateStream = true
    },
    _closeDialogUpdateStream(){
      this.selectedUpdateChanel = [];
      this.dialogUpdateStream = false;
    },
    async _handleSubmit(){
      let data = {
        live_urls : this.selectedUpdateChanel.live_urls,
        channel_type : this.selectedUpdateChanel.channel_type
      }
      await this.updateChannelUrl({ vm: this, data:data});
      this._closeDialogUpdateStream();
    },
    ...mapMutations("$_controllerForManager", ["LIST_STATEMENT", "UPDATE_CHANNEL_URL"]),
    ...mapActions("$_controllerForManager", [
      "fetchListStatement",
      "fetchGetCurrentChannel",
      "updateChannelUrl"
    ]),
  },
};
</script>

<style lang="scss" scoped>
.fino {
  color: #6699cc;
  font-size: 24px;
  font-weight: bold;
  margin-left: 10px;
}
.cm-title {
  color: black;
  font-weight: 900;
  font-size: 32px;
  position: absolute;
  top: 105px;
  left: 50%;
  margin-left: -175px;
}
.row-pointer:hover {
  cursor: pointer;
  background-color: honeydew;
}
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
.amount-bet-color {
  color: goldenrod !important;
}

.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #cef;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tie-color{
  color:green;
}
</style>
