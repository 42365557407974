/* eslint-disable no-unused-vars */
import Request from '@/services/axios'
let request = new Request;

const fetchGetAllRoleLevel = async ({ commit }, data) => {
    const response = await request.post({
        url: 'roleLevel/getAll',
        data
    });
    if (response.data.code) {
        // console.log(response.data.data)
        commit('UPDATE_ROLE_LEVEL_DATA', response.data.data);
    }
};
const createRoleLevelData = async ({ commit }, { vm, data }) => {
    commit('LOADINGROLELEVEL', true , { root: true });
    const response = await request.post({
        url: 'roleLevel/create',
        data
    });
    if (response.data.code) {
        commit('UPDATE_ROLE_LEVEL_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};
const updateRoleLevel = async ({ commit }, { vm, data, _id }) => {
    commit('LOADINGROLELEVEL', true, { root: true });
    const response = await request.post({
        url: 'roleLevel/update/'+_id,
        data
    });
    if (response.data.code) {
        commit('UPDATE_ROLE_LEVEL_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};
const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const fetchCurrentRoleLevelData = async ({commit}, payload) => {
    commit('UPDATE_CURRENT_DATA', payload);
};
const fetchListUsers = async ({commit}, data) => {
    const response = await request.post({
        url: 'users/getAllByUserId',
        data
    });
    if (response.data.code) {
        commit('UPDATE_USERS', response.data.data.object);
    }
};
const deleteData = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'roleLevel/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
export default {
    fetchGetAllRoleLevel,
    createRoleLevelData,
    updateRoleLevel,
    fetchPopUp,
    fetchCurrentRoleLevelData,
    fetchListUsers,
    deleteData
};