/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListStatement = async({ commit }, preData) => {
    let data = preData.data
    let user_id = preData.user_id
    let reportUrl = preData.reportUrl
    const response = await request.post({
        url: 'adminReport/' + reportUrl + '/' + user_id,
        data,
    });
    if (response.data.code) {
        commit('LIST_STATEMENT', response.data.data);
        commit('COUNT_STATEMENT', response.data.data.objectCount);
    }

};


export default {
    fetchListStatement,
};