import * as moment from 'moment';
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    computed: {
        ...mapGetters("$_modules", { userAccessPermission: "getUserAccessPermission" }),
    },
    methods: {
        ...mapActions('$_modules', ["fetchUserAccessPermission"]),
        ...mapMutations("$_modules", ["UPDATE_USER_ACCESS_PERMISSION"]),
        getAge(dob) {
            var a = moment();
            var b = moment(dob, 'YYYY');
            const age = a.diff(b, 'years');
            return age;
        },
        convertDate(date, format) {
            const defalut = 'YYYY-MM-DD';
            let newDate = new Date(date);
            newDate = moment(newDate).format(format ? format : defalut);
            return newDate;
        },
        convertDateTime(date, format = 'YYYY-MM-DD HH:mm:ss') {
            if (!date) return '';
            return moment(new Date(date)).format(format);
        },
        defaultPrice(price, format) {
            const newprice = price ? price : '';
            if (format) {
                return newprice + format;
            }
            return newprice + '$';
        },
        clearData(data) {
            for (const item in data) {
                switch (item) {
                    case 'dob':
                        data[item] = '';
                        break;
                    default:
                        data[item] = null;
                }
            }
            return data;
        },
        checkUserPermission(permission) {
            let objPermission = this.userAccessPermission.find(i => i.permission_name === permission);
            return !!objPermission;
        },
        alert() {
            return this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Update it!'
            })
        },

        channelStatusColor(status) {
            switch (status) {
                case null:
                    {
                        return 'success'
                    }
                default:
                    return 'error'
            }
        },

        colorCode(code) {
            let cir = 'cir'
            switch (code) {
                case 1:
                    {
                        return cir + ' cir-blue';
                    }
                case 2:
                    {
                        return cir + ' cir-red';
                    }
                case 3:
                    {
                        return cir + ' cir-green';
                    }
                case 4:
                    {
                        return cir + ' cir-cancel';
                    }
                default:
                    return 'no-circle'
            }
        },
    }
};