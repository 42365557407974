const getUsers = (state) => {
	return state.object;
};
const getLoading = (state) => state.loading

export default {
	getLoading,
	getUsers,
	
};
