<template>
  <div>
    <v-layout class="mt-3">
      <v-col col="12" class="pb-0 pt-1" style="width:100%; height:67px; border:1px solid white">
        <v-row >
          <v-col col="6" class="pt-5">
            <v-row style="text-align:center; border-right:1px solid black; justify-content: center;" class="pt-3">
              <div class="pt-3">
        1 : 
      </div>
      <v-avatar class="mx-3" color="red" size="48">
        <span class="white--text text-h5 font-weight-bold">
          {{ summaryResult.one }}
        </span>
      </v-avatar>
      <div class="pt-3">
        2 : 
      </div>
      <v-avatar class="mx-3" color="blue" size="48">
         
        <span class="white--text text-h5 font-weight-bold">
          {{ summaryResult.two }}
        </span>
      </v-avatar>
      <div class="pt-3">
        3 : 
      </div>
      <v-avatar class="mx-3" color="blue" size="48">
        
        <span class="white--text text-h5 font-weight-bold">
          {{ summaryResult.three }}
        </span>
      </v-avatar>
      <div class="pt-3">
        4 : 
      </div>
      <v-avatar class="mx-3" color="red" size="48">
       
        <span class="white--text text-h5 font-weight-bold">
          {{ summaryResult.four }}
        </span>
      </v-avatar>
      <div class="pt-3">
        5 : 
      </div>
      <v-avatar class="mx-3" color="blue" size="48">
        
        <span class="white--text text-h5 font-weight-bold">
          {{ summaryResult.five }}
        </span>
      </v-avatar>
      <div class="pt-3">
        6 : 
      </div>
      <v-avatar class="mx-3" color="blue" size="48">
       
        <span class="white--text text-h5 font-weight-bold">
          {{ summaryResult.six }}
        </span>
      </v-avatar>
            </v-row>
          </v-col>
          <v-col col="" class="pt-5">
            <v-row style="text-align:center; border-left:1px solid white; justify-content: center;" class="pt-3">
              <p class="result-number px-2"><span class="text-warning">Games</span>  =  <span class="result-number">{{ channel.fight_no}}</span>,</p>
              <p class="result-number px-2"><span class="text-success">Group</span>  =  <span class="result-number">{{  channel.group_no}}</span></p>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-layout>

<v-simple-table
    class="history-result result-table"
    width="100%"
    border="1"
    cellpadding="0"
    cellspacing="0"
  >
    <tbody id="tdhistory"  class="center-padding-0">
      <tr v-for="row in 6" :key="row" class="center-padding-0" >
          <td v-for="col in 20" :key="col" class="center-padding-0">
            <div v-for="(result, i) in orderResult" :key="i" class="center-padding-0">
              <span v-if="col == result.col && row == result.row"  class="mx-0 center-padding-0">
                <v-avatar :class="result.color" size="30" class="mx-0 center-padding-0">
                  <span class="white--text text-h6">
                    {{result.name}}
                  </span>
                </v-avatar>
              </span>
            </div>
        </td>
      </tr>
    </tbody>
  </v-simple-table>

  </div>
</template>

<script>

export default {
  props: ["summaryResult", 'objSnResult','channel',],
  data() {
    return {
      dialogResult: false,
     
    };
  },
   watch: {
    summaryResult:function(){
    },
    channel: function () {
    },
  },
  computed: {
    orderResult() {
      return this.getListResults();
    },
    number(bet) {
      return this.getNumber(bet);
    },
  },
  methods: {
    getListResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if(this.objSnResult !== null){
      for (var index = this.objSnResult.length - 1; index >= 0; index--) {
        const item = this.objSnResult[index];
        if(index == this.objSnResult.length - 1){
          newResult.push({
            ...item,
            row,
            col,
          });
        }else{
          const pre = newResult[newResult.length - 1];
          if(pre.name !== item.name){
            newResult.push({ 
              ...item,
              row: 1,
              col: pre.col + 1,
            });
          }else{
            if (pre.row >= 6) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              newResult.push({
                ...item,
                row: pre.row + 1,
                col: pre.col,
              });
            }
          }
        }
      }
      }
      return newResult;
    },
  }
};
</script>

<style scoped>
.history-result td {
  height: 30px;
  width: 30px;
  background: black;
  border: 1px solid #96afde;
  vertical-align: middle;
  box-shadow: -1px 0 #e4dbc5, 0 1px #e4dbc5, 1px 0 #e4dbc5, 0 -1px #e4dbc5;
  -moz-box-shadow: 0 0 2px 1px #fff;
  -webkit-box-shadow: 0 0 2px 1px #fff;
  -ms-box-shadow: 0 0 2px 1px #fff;
  -o-box-shadow: 0 0 2px 1px #fff;
}

.number-blue{
  background-size: 30px;
  display: block;
  height: 30px;
}
.cir-red {
  background-color: #f44336 !important;;
}
.cir-blue {
  background-color: #1976d2 !important;;
}

.cir-black {
  background-color: #1976d2 !important;;
}
.result-table{
  max-width:100%; max-height:100%;min-width:100%; min-height:100%; margin:0; margin-top:20px
}
.center-padding-0 {
  text-align:center;
  padding: 0px!important;
}

.result-number{
  font-family:'khmer mef1'; font-weight:bold;color:white ;font-size: 25px
}
</style>
