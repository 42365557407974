/* eslint-disable no-unused-vars */
import Request from '@/services/axios'
let request = new Request;
const fetchGetCancelBetting = async ({ commit }, data) => {
    commit('LOADING', true );
    let apiUrl;
    if(data.selectOption == 1) apiUrl = 'coChannel/getCancelBettingUSD';
    if(data.selectOption == 2) apiUrl = 'coChannel/getCancelBettingKH';
    if(data.selectOption == 3) apiUrl = '/coChannel/getCancelBettingTB';
    const response = await request.post({
        url: apiUrl,
        data
    });
    if (response.data.code) {
        commit('UPDATE_DATA_CANCEL_BETTING', response.data.data);
        commit('LOADING', false);
    }
};
const updatePayout = async ({ commit }, { vm, data, _id }) => {

    const response = await request.put({
        url: 'vip-percentage/update/'+_id,
        data
    });
    if (response.data.code) {
        commit('UPDATE_PAYOUT_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};
const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const fetchCurrentVipPercentageData = async ({commit}, payload) => {
    
    commit('UPDATE_CURRENT_DATA', payload);
};

export default {
    fetchGetCancelBetting,
    updatePayout,
    fetchPopUp,
    fetchCurrentVipPercentageData,


};