const UPDATE_PAYOUT_DATA = (state, data) => {
    state.payOutData = data;
}
const UPDATE_CHANNEL_AFTER_RESULT = (state, data) => {
    state.currentChannel = data;
}
const LOADING = (state, data) => {
    state.loading = data;
}

const UPDATE_USERS = (state, data) => {
    state.users = data;
};

const UPDATE_CURRENT_DATA = (state, data) => {
    state.currentPayOutData = data;
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentPayOutData = {
            target: null,
            percentage_meron: null,
            percentage_wala: null,
            start_time: null,
            end_time:null,
            meron:null,
            user_id: null,
            user_name: null,
        }
    }
    state.popUp = data;
};
const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
};


export default {
    UPDATE_PAYOUT_DATA,
    UPDATE_USERS,
    UPDATE_CHANNEL_AFTER_RESULT,
    LOADING,
    UPDATE_POPUP,
    UPDATE_CURRENT_DATA,
    DELETE_ITEMS
};