/* eslint-disable no-unused-vars */
import Request from '@/services/axios'
import Vue from "vue";
let request = new Request;

const fetchListStatement = async({ commit }, preData) => {
    let data = preData.data
    data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    let user_id = preData.user_id
    let reportUrl = preData.reportUrl
    Vue.$cookies.set("currency-type", 2, 0);
    Vue.$cookies.set("app_id", preData.appId, 0);
    console.log("reportUrl",preData.data);
    let response;
    if(reportUrl == 'company'){
        response = await request.post({
            url: 'statementChannel/' + reportUrl,
            data,
        });
    }else{
        response = await request.post({
            url: 'statementChannel/' + reportUrl + '/' + user_id,
            data,
        });
    }
    if (response.data.code) {
       
        commit('LIST_STATEMENT', response.data.data);
        commit('COUNT_STATEMENT', response.data.data.objectCount);
    }

};


export default {
    fetchListStatement,
};