import reverseBaccarat from '@/modules/reverseResult/baccarat';
import Layout from '@/layouts/default';

const routes = [{
    path: '/reverseBaccarat',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: reverseBaccarat,
        name: 'reverseBaccarat'
    }]
}];

export default routes;