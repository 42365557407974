import ocStatementSummaryPlayerUSD from '@/modules/statementReports/currency/ocStatementSummaryPlayerUSD';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryPlayerUSD',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryPlayerUSD,
        name: 'ocStatementSummaryPlayerUSD'
    }]
}];

export default routes;