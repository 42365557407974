import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    users: {
        object: [],
        objectCount: null
    },
    popUp: false,
    currentUser: {
        user_name: null,
        password: 123456,
        account_info: null,
        enable:true,
        role_id: null,
        channel_post:null,
        selectedChannel:[1,2,3,4],
        app_id: null
    },
    deleteItems: [],
    loading: true,
    viewTransaction: {},
    depositTransaction: {},
    listCompany:[],
    listSenior:[],
    listApp:[]
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
