const headers = [
    // { text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'ល.រ',
        align: 'start',
        value: 'no',
    },
    { text: 'កាលបរិច្ឆេទ', value: 'date' },
    { text: 'គូប្រកួត', value: 'fight_no' },
    // { text: 'បានចាក់', value: 'co_type_of_betting.desc' },
    // { text: 'លិទ្ធផល', value: 'co_result.desc' },
    { text: 'សរុបប្រាក់ភ្នាល់', value: 'amount' },
    { text: 'Payout', value: 'payout' },
    { text: 'សរុបឈ្នះ/ចាញ់', value: 'amount_win' },
    { text: 'ប្រាក់សងត្រឡប់', value: 'cash' },
];

export default headers;