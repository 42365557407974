const UPDATE_DATA_CANCEL_BETTING = (state, data) => {
    state.dataCancelBetting = data;
}
const UPDATE_DATA_KH = (state, data) => {
    state.dataKh = data;
}
const UPDATE_DATA_TB = (state, data) => {
    state.dataTb = data;
}
const UPDATE_CHANNEL_AFTER_RESULT = (state, data) => {
    state.currentChannel = data;
}
const LOADING = (state, data) => {
    state.loading = data;
}

const UPDATE_USERS = (state, data) => {
    state.users = data;
};

const UPDATE_CURRENT_DATA = (state, data) => {
    state.currentPayOutData = data;
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentPayOutData = {
            vip: null,
            label: null,
        }
    }
    state.popUp = data;
};
const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
};


export default {
    UPDATE_DATA_CANCEL_BETTING,
    UPDATE_DATA_KH,
    UPDATE_DATA_TB,
    UPDATE_USERS,
    UPDATE_CHANNEL_AFTER_RESULT,
    LOADING,
    UPDATE_POPUP,
    UPDATE_CURRENT_DATA,
    DELETE_ITEMS
};