import Menu from '@/modules/MenuManagement';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/menu-management',
		component: Layout,
		children: [
			{
				path: '',
				name: 'menu',
				component: Menu
			}
		]
	}
];

export default routes;
