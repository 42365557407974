<template>
  <div>
    <!-- sort-by="user_name" -->
    <v-data-table
        :headers="headers"
        :items="getStatement ? getStatement.object : []"
        :loading="!getStatement"
        
        item-key="_id._id"
        class="elevation-1"
        :server-items-length="getStatement ? getCountStatement.count : null"
        style="font-family: 'khmer mef1'"
        :page.sync="data.page"
        :footer-props="footerProps"
        :items-per-page.sync="data.rowsPerPage"
        :sort-desc.sync="data.descendingFormat"
        :sort-by.sync="data.sortByFormat"
        @update:page="getData"
        @update:items-per-page="getData"
        @update:sort-desc="getData"
        mobile-breakpoint="0"
    >
      
      <template v-slot:top>
        <Header :darkmode="darkmode"
                :params="data"
                :user-permission="userAccessPermission"
                :user="user"
                @child-checkbox="checkboxValue"
        />  
      </template>

      <template v-slot:[`item`]="{ item }">
      <tr>
        <td class="text-left"> 
          <ActionComponents
            :darkmode="darkmode"
            :item="item"
            :params="data"
            :user-permission="userAccessPermission"
            :user="user"
            :date="check"
        /></td>
        <td>{{getStatement.object.indexOf(item) +1 }}</td>
        <td>{{ item._id.user_name }} </td>
        <td>{{ currencyFormat(item._id.balance) }}</td>
        <td class="amount-bet-color">{{ currencyFormat(item.amount) }}</td>
        <td class="win-color">{{ currencyFormat(item.amount_win) }}</td>
        <td class="lose-color">{{ currencyFormat(item.amount_lose) }}</td>
      </tr>
    </template>
    </v-data-table>
    <loader v-if="this.loader == true" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import headers from "./_api/columns";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
  },

  data: () => ({
    valid: true,
    headers: headers,
    search: null,
    footerProps: {
      'items-per-page-options': [10, 20, 50, 100, 200]
    },
    data: {
      descending: true,
      sortBy: '_id',
      page: 1,
      rowsPerPage: 10,
      fields: ["_id.user_name", "_id.balance", "amount", "amount_win"],
      search: '',
      startDate:  new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
    },
    dialog: false,
    loading: false,
    loader:false,
    user_id:null,
    check:{
      startDate:  new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
    }
  }),
  watch: {
    user:function(){
      this.getData( );
    },
    data:function(newVal){
      let preData = {
        data : newVal,
        user_id : this.user._id
      }
      this.getData( preData );
    },
    getStatement:function(){
      
    }
  },
  computed: {
   reportUrl: function(){
     if(this.user){
        if(this.user.role_name == "DEVELOPER") return "getViewCompanyKh"
        else if(this.user.role_name == "COMPANY") return "getViewSenior"
        else if(this.user.role_name == "MANAGER") return "getViewSenior"
        else if(this.user.role_name == "SENIOR") return "getViewMaster"
        else if(this.user.role_name == "MASTER") return "getViewAgent"
        else if(this.user.role_name == "AGENT") return "getViewPlayer"
        else return null
     }
     else return null
    },
    ...mapGetters("$_modules", {darkmode: "getDarkMode", user: "getUser"}),
    ...mapGetters("$_statement", ["getStatement","getCountStatement"]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    this.getData()
  },
  methods: {
    // Gets the checkbox information from the child component
    checkboxValue: function(params) {
      this.check = params;
    },
    currencyFormat (num) {
      num = parseFloat(num)
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      // return parseInt(num).toLocaleString('en')
    },
    async getData() {
      this.loader=true
      let preData = {
        data : this.data,
        user_id : this.user._id,
        reportUrl : this.reportUrl
      }
      this.checkUserPermission('VIEW') ? await this.fetchListStatement(preData) : []
      this.loader=false
    },
    ...mapMutations("$_statement", ["LIST_STATEMENT","COUNT-STATEMENT"]),
    ...mapActions("$_statement", [
      "fetchListStatement"
    ]),
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
.amount-bet-color{
  color: goldenrod!important
}
.win-color{
    color: rgb(57, 175, 214);
    font-weight: bold
  }
  .lose-color{
    color:#BA2D4A;
    font-weight:bold
  }
</style>
