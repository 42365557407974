import VipPercentage from '@/modules/vipPercentage';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/Vip-Percentage',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'VipPercentage',
				component: VipPercentage
			}
		]
	}
];

export default routes;
