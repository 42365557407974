/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchCurrentChannel = async({ commit }, data) => {
    const response = await request.get({
        url: 'snChannel/getCurrentChannelAndResult',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objSnChannel);
        commit('SUMMARY_RESULT', response.data.data.objSummarySnResult);
        commit('RESULT_TABLE', response.data.data.objSnResult);
    }
};

const createResult = async({ commit }, { vm, data }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'snChannel/createResultAndNewChannel',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objSnChannel);
        commit('SUMMARY_RESULT', response.data.data.objSummarySnResult);
        commit('RESULT_TABLE', response.data.data.objSnResult);
        commit('LOADINGRESULT', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const fetchBtnResult = async({ commit }, data) => {
    const response = await request.get({
        url: 'snChannel/getSnNumber',
        data
    });
    if (response.data.code) {
        commit('BTN_RESULT', response.data.data.objSnNumber);
    }
};
const updateChannel = async({ commit }, { vm, data }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'snChannel',
        data
    });
    if (response.data) {
        commit('UPDATE_CHANNEL', response.data.data.objSnChannel);
        commit('LOADING', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};


export default {
    fetchCurrentChannel,
    fetchBtnResult,
    createResult,
    updateChannel
};