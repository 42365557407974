import PayOut from '@/modules/payOut';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/payOut',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'PayOut',
				component: PayOut
			}
		]
	}
];

export default routes;
