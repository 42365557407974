const getUsers = (state) => {
	return state.users;
};
const getPopUp = (state) => state.popUp;
const getPopUpBankUser = (state) => state.popUpBankUser;
const getCurrentUser = (state) => state.currentUser;
const getDeleteItems = (state) => state.deleteItems;
const getLoading = (state) => state.loading
const getViewTransaction = (state) => state.viewTransaction;
const getDepositTransaction = (state) => state.depositTransaction;
const getListUserCompany = (state) => state.listCompany;
const getListUserSenior = (state) => state.listSenior;
const getListApp = (state) => state.listApp;
const getCurrentBankUser = (state) => state.currentBankUser;

export default {
	getLoading,
	getUsers,
	getPopUp,
	getPopUpBankUser,
	getCurrentUser,
	getDeleteItems,
	getViewTransaction,
	getDepositTransaction,
	getListUserCompany,
	getListUserSenior,
	getListApp,
	getCurrentBankUser
	
};
