import historyByFightReport from '@/modules/historyByFightReport';
import Layout from '@/layouts/default';

const routes = [{
    path: '/historyByFightReport',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: historyByFightReport,
        name: 'historyByFightReport'
    }]
}];

export default routes;