import permission from '@/modules/permission';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/user-permission',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: permission,
				name: 'permission'
			}
		]
	}
];

export default routes;
