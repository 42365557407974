<template>
  <div>
    <div class="section-header" hidden>
      <h1>{{ $t("title.roleLevel") }}</h1>
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active"><a href="/">Dashboard</a></div>
        <div class="breadcrumb-item">Role Level</div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive" style="text-align: center">
          <v-data-table
            :headers="headers"
            :items="getRoleLevelData ? getRoleLevelData.object : []"
            :footer-props="footerProps"
            item-key="_id"
            class=" table table-striped"
            id="table-1"
            :server-items-length="
              getRoleLevelData ? getRoleLevelData.objectCount : null
            "
            style="font-family: 'khmer mef1'"
            :page.sync="data.page"
            :items-per-page.sync="data.rowsPerPage"
            :sort-desc.sync="data.descendingFormat"
            :sort-by.sync="data.sortByFormat"
            hide-default-header
            @update:page="getData"
            @update:items-per-page="getData"
            @update:sort-desc="getData"
            show-select
            :loading="getLoading"
            :value="getDeleteItems"
            @input="itemDelete"
            mobile-breakpoint="0"
          >
          <template v-slot:header="{ props }">
            <th v-for="head in props.headers" :key="head._id">
              {{ head.text }}
            </th>
          </template>
            <template v-slot:top>
              <Header
                :darkmode="darkmode"
                :params="data"
                :dataDelete="getDeleteItems"
                :user-permission="userAccessPermission"
              />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <ActionComponents
                :darkmode="darkmode"
                :item="item"
                :dataDelete="getDeleteItems"
                :params="data"
                :user-permission="userAccessPermission"
              />
            </template>
          </v-data-table>
        </div>
          <Dialog
            :darkmode="darkmode"
            :loading="loading"
            :users="getUsers"
            :roleLevelData="getCurrentRoleLevelData"
            :dialog="getPopUp"
            :is-validate="valid"
            :user="userp"
            v-on:submit="handleSubmit"
            v-on:onCloseDialog="closeDialogCreate"
          />
          <!-- <div class="text-center pt-2">
          <v-pagination
            v-model="data.page"
            :length="
              getRoleLevelData
                ? getRoleLevelData.objectCount % data.rowsPerPage != 0
                  ? getRoleLevelData.objectCount / data.rowsPerPage + 1
                  : getRoleLevelData.objectCount / data.rowsPerPage
                : 0
            "
          ></v-pagination>
          <v-text-field
            :value="data.rowsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="data.rowsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div> -->
        <!-- <div class="loader" v-if="this.loader == true"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      type: 1,
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["role_name", "child_role_name", "child_role_balance"],
      search: "",
    },
    dataUser: {
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 5000,
      fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
      search: "",
    },
    dialog: false,
    loading: false,
  }),
  computed: {
    headers() {
      return [
        // {text: this.$t('header.user_name'), align: 'start', sortable: false, value: 'user_id'},
        {
          text: this.$t("input.child_role_balance"),
          value: "child_role_balance",
        },
        { text: this.$t("input.child_role_name"), value: "child_role_name" },
        { text: this.$t("input.role_name"), value: "role_name" },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", userp: "getUser" }),
    ...mapGetters("$_roleLevel", [
      "getRoleLevelData",
      "getLoading",
      "getDeleteItems",
      "getPopUp",
      "getUsers",
      "getCurrentRoleLevelData",
    ]),
    ...mapGetters("$_role", ["getAllRolesDependOnUser"]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
    await this.getDataUser();
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      this.getCurrentRoleLevelData.type = 1;
      if (!this.getCurrentRoleLevelData._id) {
        await this.createRoleLevelData({
          vm: this,
          data: this.getCurrentRoleLevelData,
        });
        this.getCurrentRoleLevelData = [];
        this.fetchPopUp(false);
      } else {
        let uploadData = {
          type: this.getCurrentRoleLevelData.type,
          role_name: this.getCurrentRoleLevelData.role_name,
          child_role_name: this.getCurrentRoleLevelData.child_role_name,
          child_role_balance: this.getCurrentRoleLevelData.child_role_balance,
          company_balance_b: this.getCurrentRoleLevelData.company_balance_b,
          company_balance_r: this.getCurrentRoleLevelData.company_balance_r,
          company_balance_d: this.getCurrentRoleLevelData.company_balance_d,
        };
        await this.updateRoleLevel({
          vm: this,
          data: uploadData,
          _id: this.getCurrentRoleLevelData._id,
        });
        this.fetchPopUp(false);
      }
      await this.getData();
      this.loading = false;
    },

    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchGetAllRoleLevel(this.data)
        : [];
    },
    async getDataUser() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListUsers(this.dataUser)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    closeDialogCreate() {
      this.getCurrentRoleLevelData = {};
      this.fetchPopUp(false);
    },

    ...mapMutations("$_roleLevel", [
      "UPDATE_ROLE_LEVEL_DATA",
      "DELETE_ITEMS",
      "UPDATE_USERS",
    ]),
    ...mapActions("$_roleLevel", [
      "fetchPopUp",
      "createRoleLevelData",
      "fetchGetAllRoleLevel",
      "fetchListUsers",
      "updateRoleLevel",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_USERS([]);
    this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>
