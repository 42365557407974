<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="getUsers ? getUsers.object : []"
      :footer-props="footerProps"
      item-key="_id"
      class="elevation-1"
      :server-items-length="getUsers ? getUsers.objectCount : null"
      style="font-family: 'khmer mef1'"
      :page.sync="data.page"
      :items-per-page.sync="data.rowsPerPage"
      :sort-desc.sync="data.descendingFormat"
      :sort-by.sync="data.sortByFormat"
      @update:page="getData"
      @update:items-per-page="getData"
      @update:sort-desc="getData"
      :loading="getLoading"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header
          :darkmode="darkmode"
          :params="data"
          :user-permission="userAccessPermission"
        />
      </template>

          <template v-slot:[`item`]="{ item }">
        <tr>
          <td>{{ (getUsers.object.indexOf(item)  + ( (data.page -1 ) *  data.rowsPerPage))+ 1 }}</td>
          <td>{{ item.objMaster.user_name }}</td>
          <td  v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && (item.objMaster.currency_type===1 || item.objMaster.currency_type===3) ">  {{ currencyFormatUsd(item.objMaster.balance)}}</td>
         
        <td  v-if="item.objMaster.role_id !=='612a3fcc1a1fcead8871e822' && item.objMaster.currency_type===2" >
          {{ currencyFormatKh(item.objMaster.balance)}}
        </td>
        <td>
           <a  @click="_openDialogListIpUser(item.objMaster)" >
            {{ item.objMaster.public_ip }}
          </a>
        </td>
          <td> <v-btn :color="item.objMaster.enable ?'success':'error'" @click="_openDialog(item.objMaster)">{{ item.objMaster.enable ? "Active":"Inactive" }}</v-btn></td>
          <td> <v-btn :color="!item.objMaster.is_suspend ?'success':'error'" @click="_openDialogs(item.objMaster)">{{ !item.objMaster.is_suspend ? "Active":"suspend" }}</v-btn></td>
          <td> <v-btn color="primary" @click="upConfigUser(item.objMaster)">  <v-icon
      color="orange"
      small
      class="mr-2"
      >mdi-pencil
    </v-icon>Edit</v-btn></td>
        </tr>
      </template>
      </v-data-table>
      <!-- Confirm Dialog -->
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card >
        <v-card-title class="text-h5 py-5">
         {{player.enable ?  'បិទដំណើរការ Player : '+ player.user_name:'បើកដំណើរការ Player : '+ player.user_name}}
        </v-card-title>
        <v-card-actions>
          
          <v-btn color="blue-grey"    class="ma-2 white--text"  @click="_closeDialog">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn     class="ma-2 white--text" :color="player.enable ?  'error':'success'" @click="_takeAction">{{this.player.enable ? "Inactive this Player" :"Active this Player"}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-dialog v-model="dialogs" persistent max-width="500px">
      <v-card >
        <v-card-title class="text-h5 py-5">
         {{player.suspend ?  'បិទដំណើរការ Player : '+ player.user_name:'បើកដំណើរការ Player : '+ player.user_name}}
        </v-card-title>
        <v-card-actions>
          <v-btn color="blue-grey"    class="ma-2 white--text"  @click="_closeDialogs">Cancel 1</v-btn>
          <v-spacer></v-spacer>
          <v-btn     class="ma-2 white--text" :color="!player.is_suspend ?  'error':'success'" @click="_takeActions">{{!this.player.is_suspend ? "Suspend this Player" :"Active this Player"}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogListIpUserComponents   
      :dialog="ipDialog"
      :listData="listIpUserData"
      @onCloseDialog="ipDialog = !ipDialog"/>

      <DialogConfigUserData   
      :dialog="configDialog"
      :playerConfig = "playerConfig"
      :dataConfig="configUserData"
      :loading="loading"
      :limitConfig="limitConfig"
      v-on:submit="onChangeConfigUser"
      @onCloseDialog="configDialog = !configDialog"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    Header: () => import("./_components/HeaderTable.vue"),
    DialogConfigUserData: () => import("./_components/ConfigUserData.vue"),
    DialogListIpUserComponents: () => import("./_components/DialogListIpUser"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      page: 1,
      rowsPerPage: 10,
      search: "",
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
    },
    loading:false,
    dialog: false,
    dialogs: false,
    configDialog:false,
    player: [],
    playerConfig:{},
    ipDialog:false,
    listIpUserData:[],
    configUserData:{},
    limitConfig:{}
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("header.no"),
          align: "start",
          sortable: false,
          // value: "user_name",
        },
        {
          text: this.$t("header.user_name"),
          align: "start",
          sortable: false,
          value: "user_name",
        },
        // { text: this.$t("header.bank_account"), value: "account_info" },
        { text: this.$t("header.balance"), value: "balance" },  
          { text: this.$t("header.public_ip"), value: "public_ip" },  
        { text: this.$t("header.active"), value: "enable" },
         { text: this.$t("header.suspend"), value: "suspend" },
     { text: this.$t("header.update_config"), value: "configuser" },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", userp: "getUser" }),
    ...mapGetters("$_seniorManagePlayer", [
      "getUsers", "getLoading"
    ]),
  },
  async created() {
    await this.getData();
  },
  watch: {
    getUsers: function() {}
  },
  methods: {
      async onChangeConfigUser() {
      // console.log(this.configUserData); 
      this.loading = true;

      this.configUserData.total_bet_a_fight = parseInt(
        this.configUserData.total_bet_a_fight
      );

      this.configUserData.total_bet_win_a_day = parseInt(
        this.configUserData.total_bet_win_a_day
      );
      this.configUserData.amount_min = parseInt(
        this.configUserData.amount_min
      );

      this.configUserData.amount_max = parseInt(  
        this.configUserData.amount_max
      );
 
      {
        await this.updateConfigUser({ vm: this, d: this.configUserData });
        this.loading = false;
        this.configDialog = false;
      }
    },
   _openDialogListIpUser(item){
      this.getListIpUserData(item._id);
      this.ipDialog = true;
    },
    _openDialogsConfig(item){
      this.playerConfig = item;
      this.configDialog = true;
    },
    async getListIpUserData(_id){
      let data = {
        user_id : _id
      }
       await this.$request
        .post({
          url: "/users/getLoginIpByUser",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.listIpUserData = res.data.data
            // this.waiting = false;
          }
        });
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
    },
    async getData() {
      // console.log("hiello");
        await this.fetchListUsersPlayer(this.data)
    },
    _closeDialog(){
      this.player = [];
      this.dialog = false;
    },
    _closeDialogs(){
      this.player = [];
      this.dialogs = false;
    },
    _openDialog(player){
      this.player = player;
      // console.log(player);
      this.dialog = true;
    },
      _openDialogs(player){
      this.player = player;
      // console.log(player);
      this.dialogs = true;
    },
     async _takeAction(){
        if(this.player) {
          let data = {
            player_id : this.player._id,
            enable : !this.player.enable
          }
          // console.log(data);
          await this.updateUser({ vm: this, data: data })
          this.dialog = false;
          this.getData()
          }
       
    },
     async _takeActions(){
        if(this.player) {
          let data = {
            player_id : this.player._id,
            is_suspend : !this.player.is_suspend
          }
          // console.log("okay")
          await this.updateSuspendUser({ vm: this, data: data })
          this.dialogs = false;
          this.getData()
          }
       
    },
    
    async upConfigUser(item) {
      // console.log(item);
      let data = { currency_type: item.currency_type, _id: item._id  };
      this.$request
        .post({
          url: "configUser/getConfigData",
          data: data,
        })
        .then((res) => {
          // console.log(res);
          this.configUserData = res.data.data.object;
          this.getLimitData(item);
          this.configDialog = true;
        });
    },
       async getLimitData(item) {
      let data = {
        currency_type: item.currency_type,
        _id: item.parent_id,
      };
      const response = await this.$request.post({
        url: "limitBetting/getParentLimitBetting",
        data: data,
      });
      this.limitConfig = response.data.data.object;
    },



    ...mapMutations("$_seniorManagePlayer", [
      "LIST_PLAYER"
    ]),
    ...mapActions("$_seniorManagePlayer", [
      "fetchListUsersPlayer", "updateUser", "updateSuspendUser",
      "updateConfigUser"
    ]),

  },

};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
</style>
