<template>
    <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="codeName"
    class="elevation-1"
    
    style="font-family:'khmer mef1'"
  >   

    <template v-slot:top>        
        
      <v-row>          
        <v-col cols="12" sm="6" md="3">
        <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                label="ចាប់ផ្តើម"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            
            ></v-text-field>
            </template>
            <v-date-picker
            v-model="date"
            @input="menu2 = false"
            ></v-date-picker>
        </v-menu>
        </v-col>
        <v-spacer></v-spacer>

        <v-col
            cols="12"
            sm="6"
            md="3"
        >
        <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date1"
                label="បញ្ចប់"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            
            ></v-text-field>
            </template>
            <v-date-picker
            v-model="date1"
            @input="menu2 = false"
            ></v-date-picker>
        </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search"></v-text-field>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" sm="6" md="3">
            <v-btn class="white--text btn-custom"
                color="green darken-1"
                depressed
            >
                SEARCH
                
            </v-btn>
        </v-col>
      
     
      </v-row>
      
    </template>
   
    
  </v-data-table>   
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      date1: new Date().toISOString().substr(0, 10),
      menu: false,
      menu2: false,
      headers: [
        { text: 'សកម្មភាព', value: 'actions', sortable: false },
        {
          text: 'ល.រ',
          align: 'start',
          sortable: false,
          value: 'No',
        },
        { text: 'អ្នកប្រើប្រាស់', value: 'user_name' },
        { text: 'លេខគណនី', value: 'account_number' },
        { text: 'សមតុល្យសាច់ប្រាក់', value: 'balance_account' },
        { text: 'ប្រាក់បង្វិលជុំ', value: 'money_turnover' },
        { text: 'សរុបប្រាក់ភ្នាល់', value: 'total_stake' },
        { text: 'សរុបចាញ់/ឈ្នះ', value: 'total_losswin' },
        { text: 'កាលបរិច្ឆេទ', value: 'date' },
       
        
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        No:'',  
        user_name: '',
        account_number:'',
        balance_account:'',
        money_turnover:'',
        total_stake:'',
        total_losswin:'',
        date: '',
        
      },
      defaultItem: {
        No:'',  
        user_name: '',
        account_number:'',
        balance_account:'',
        money_turnover:'',
        total_stake:'',
        total_losswin:'',
        date: '',
      },
    }),
    computed: {
      ...mapGetters("$_modules", {
        darkmode: "getDarkMode",
      }),
      formTitle () {
        return this.editedIndex === -1 ? 'CREATE NEW' : 'EDIT ITEM'
      },
    },

    
}
</script>

<style lang="scss" scoped>
    .btn-custom{
        position: relative;
        // right: 250px;
        top: 10px;        
    }
</style>