<template>
  <div>
    <div class="section-header"></div>
    <div section-body>
      <v-data-table
        :headers="headers"
        :items="getConfigUserData ? getConfigUserData.object : []"
        :footer-props="footerProps"
        item-key="_id"
        class="elevation-1 table"
        :server-items-length="
          getConfigUserData ? getConfigUserData.objectCount : null
        "
        style="font-family: 'khmer mef1'"
        :page.sync="data.page"
        :items-per-page.sync="data.rowsPerPage"
        :sort-desc.sync="data.descendingFormat"
        :sort-by.sync="data.sortByFormat"
        @update:page="getData"
        @update:items-per-page="getData"
        @update:sort-desc="getData"
        show-select
        :loading="getLoading"
        :value="getDeleteItems"
        @input="itemDelete"
        mobile-breakpoint="0"
      >
        <template v-slot:top>
          <Header
            :darkmode="darkmode"
            :params="data"
            :dataDelete="getDeleteItems"
            :user-permission="userAccessPermission"
          />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <ActionComponents
            :darkmode="darkmode"
            :item="item"
            :dataDelete="getDeleteItems"
            :params="data"
            :user-permission="userAccessPermission"
          />
        </template>
      </v-data-table>
      <Dialog
        :darkmode="darkmode"
        :loading="loading"
        :users="getUsers"
        :configUserData="getCurrentConfigUserData"
        :dialog="getPopUp"
        :is-validate="valid"
        v-on:submit="handleSubmit"
        v-on:onCloseDialog="closeDialogCreate"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      currency_type: 1,
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "default_bet_a_fight",
        "default_bet_win_a_day",
        "agent_bet_a_fight",
        "agent_bet_win_a_day",
      ],
      search: "",
    },
    dataUser: {
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 5000,
      fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
      search: "",
    },
    dialog: false,
    loading: false,
  }),
  computed: {
    headers() {
      return [
        // {text: this.$t('header.user_name'), align: 'start', sortable: false, value: 'user_id'},
        {
          text: this.$t("input.default_bet_a_fight"),
          value: "default_bet_a_fight",
        },
        {
          text: this.$t("input.default_bet_win_a_day"),
          value: "default_bet_win_a_day",
        },
        {
          text: this.$t("input.agent_bet_a_fight"),
          value: "agent_bet_a_fight",
        },
        {
          text: this.$t("input.agent_bet_win_a_day"),
          value: "agent_bet_win_a_day",
        },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_configUser", [
      "getConfigUserData",
      "getLoading",
      "getDeleteItems",
      "getPopUp",
      "getUsers",
      "getCurrentConfigUserData",
    ]),
    ...mapGetters("$_role", ["getAllRolesDependOnUser"]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
    await this.getDataUser();
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      if (!this.getCurrentConfigUserData._id) {
        await this.createConfigData({
          vm: this,
          data: this.getCurrentConfigUserData,
        });
        this.getCurrentConfigUserData = [];
        this.fetchPopUp(false);
      } else {
        let uploadData = {
          currency_type: this.getCurrentConfigUserData.currency_type,
          default_bet_a_fight:
            this.getCurrentConfigUserData.default_bet_a_fight,
          default_bet_win_a_day:
            this.getCurrentConfigUserData.default_bet_win_a_day,
          agent_bet_a_fight: this.getCurrentConfigUserData.agent_bet_a_fight,
          agent_bet_win_a_day:
            this.getCurrentConfigUserData.agent_bet_win_a_day,
        };
        await this.updateLimit({
          vm: this,
          data: uploadData,
          _id: this.getCurrentConfigUserData._id,
        });
        this.fetchPopUp(false);
      }
      await this.getData();
      this.loading = false;
    },

    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchGetAllConfigUser(this.data)
        : [];
    },
    async getDataUser() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListUsers(this.dataUser)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    closeDialogCreate() {
      this.getCurrentConfigUserData = {};
      this.fetchPopUp(false);
    },

    ...mapMutations("$_configUser", [
      "UPDATE_CONFIG_USER_DATA",
      "DELETE_ITEMS",
      "UPDATE_USERS",
    ]),
    ...mapActions("$_configUser", [
      "fetchPopUp",
      "createConfigData",
      "fetchGetAllConfigUser",
      "fetchListUsers",
      "updateLimit",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_USERS([]);
    this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
</style>
