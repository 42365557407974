const getKdPayout = (state) => {
	return state.kdPayout;
};
const getLoading = (state) => state.loading

export default {
	getLoading,
	getKdPayout,
	
};
