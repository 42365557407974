const headers = [
	{ text: '', value: '', sortable: false ,class:'header-style'},
	{ text: 'ភាគរយ Payout Wala', value: 'wala_percentage' },
	{ text: 'ភាគរយ Payout Meron', value: 'meron_percentage' },
	{ text: 'ភាគរយ Target', value: 'target_percentage' },
	{ text: 'Channel Type', value: 'channel_type' },
	{ text: 'Created Date', value: 'createdAt' },
	{ text: 'Update Date', value: 'updatedAt' },
	{ text: 'កៃប្រែ', value: 'action' },

]

export default headers;
