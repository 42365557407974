import userOnlineReports from '@/modules/userOnlineReports';
import Layout from '@/layouts/default';

const routes = [{
    path: '/userOnlineReports',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: userOnlineReports,
        name: 'userOnlineReports'
    }]
}];

export default routes;