<template>
  <div>
    <div class="section-header" hidden>
      <h1>Menu Management</h1>
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active"><a href="/">Dashboard</a></div>
        <div class="breadcrumb-item">Menu Management</div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive" style="text-align: center">
          <v-data-table
            class=" table table-striped"
            id="table-1"
            item-key="_id"
            :headers="headers"
            :items="getMenus && getMenus.object ? getMenus.object : []"
            :footer-props="footerProps"
            
            :page.sync="data.page"
            :items-per-page.sync="data.rowsPerPage"
            :sort-desc.sync="data.descendingFormat"
            :sort-by.sync="data.sortByFormat"
            hide-default-header
            @update:page="getData"
            @update:items-per-page="getData"
            @update:sort-desc="getData"
            :loading="getLoading"
            :value="getDeleteItems"
            @input="itemDelete"
            show-select
            mobile-breakpoint="0"
          >
          <template v-slot:header="{ props }">
            <th v-for="head in props.headers" :key="head._id">
              {{ head.text }}
            </th>
          </template>
            <template v-slot:top>
              <Header
                :darkmode="darkmode"
                :params="data"
                :data-delete="getDeleteItems"
                :user-permission="userAccessPermission"
              />
            </template>

            <template v-slot:[`item.is_visible`]="{ item }">
              <v-chip
                label
                text-color="white"
                :color="item.is_visible ? 'green' : 'red'"
              >
                {{ item.is_visible ? "Active" : "Inactive" }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <ActionComponents
                :darkmode="darkmode"
                :item="item"
                :dataDelete="getDeleteItems"
                :params="data"
                :user-permission="userAccessPermission"
              />
            </template>
          </v-data-table>
        </div>
        <Dialog
          :darkmode="darkmode"
          :dialog="getPopUp"
          :menu="getCurrentMenu"
          :option-permissions="getPermission.object"
          :option-menus="getParentMenu.object"
          :is-validate.sync="valid"
          :loading="loading"
          v-on:onCloseDialog="fetchPopUp(false)"
          v-on:submit="handleSubmit"
        />
        <!-- <div class="text-center pt-2">
          <v-pagination
            v-model="data.page"
            :length="
              getMenus
                ? getMenus.objectCount % data.rowsPerPage != 0
                  ? getMenus.objectCount / data.rowsPerPage + 1
                  : getMenus.objectCount / data.rowsPerPage
                : 0
            "
          ></v-pagination>
          <v-text-field
            :value="data.rowsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="data.rowsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div> -->
        <div class="loader" v-if="getLoading == true"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/tableHeader.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    loading: false,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 10,
      fields: ["name", "descr", "order", "icon", "route", "url"],
      search: "",
    },
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("header.menu_name"), align: "start", value: "name" },
        { text: this.$t("header.icon"), value: "icon", sortable: false },
        { text: this.$t("header.order"), value: "order" },
        {
          text: this.$t("header.created_at"),
          value: "createdAt",
          sortable: false,
        },
        {
          text: this.$t("header.active"),
          value: "is_visible",
          sortable: false,
        },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_permission", ["getPermission"]),
    ...mapGetters("$_menu", [
      "getMenus",
      "getPopUp",
      "getCurrentMenu",
      "getDeleteItems",
      "getParentMenu",
      "getLoading",
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      if (!this.getCurrentMenu._id) {
        await this.createMenu({ vm: this, data: this.getCurrentMenu });
      } else {
        await this.updateMenu({ vm: this, data: this.getCurrentMenu });
      }
      await this.getData();
      this.loading = false;
    },

    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListMenus(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    ...mapMutations("$_menu", ["DELETE_ITEMS", "UPDATE_MENUS"]),
    ...mapActions("$_menu", [
      "fetchListMenus",
      "fetchPopUp",
      "createMenu",
      "updateMenu",
      "fetchCurrentMenu",
      "fetchParentMenu",
    ]),
    ...mapActions("$_permission", ["fetchListPermission"]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_MENUS([]);
    this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;
}

table {
  font-family: "khmer mef1" !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>
