const LIST_PLAYER = (state, data) => {
    state.object = data;
};

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}

export default {
    UPDATE_LOADING,
    LIST_PLAYER,
};
