<template>
  <div>
    <label :for="label">
      {{ label }} <span v-if="required" class="text-danger"> *</span>
    </label>
    <v-text-field
      dense
      color="blue darken-2"
      :rules="rules"
      v-bind="$attrs"
      v-on="$listeners"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ["label", "rules", "required"],
};
</script>

<style lang="scss" scoped>
</style>