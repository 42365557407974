<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive" style="text-align: center">
          <v-data-table
            :headers="headers"
            :items="getAllBanner ? getAllBanner.object : []"
            :footer-props="footerProps"
            item-key="_id"
            class=" table table-striped"
            id="table-1"
            :server-items-length="getAllBanner ? getAllBanner.count : null"
            style="font-family: 'khmer mef1'"
            :page.sync="data.page"
            :items-per-page.sync="data.rowsPerPage"
            :sort-desc.sync="data.descendingFormat"
            :sort-by.sync="data.sortByFormat"
            hide-default-header
            @update:page="getData"
            @update:items-per-page="getData"
            @update:sort-desc="getData"
            show-select
            :loading="getLoading"
            :value="getDeleteItems"
            @input="itemDelete"
            mobile-breakpoint="0"
          >
          <template v-slot:header="{ props }">
            <th v-for="head in props.headers" :key="head._id">
              {{ head.text }}
            </th>
          </template>
            <template v-slot:top>
              <Header
                :darkmode="darkmode"
                :params="data"
                :dataDelete="getDeleteItems"
                :user-permission="userAccessPermission"
              />
            </template>
            <template v-slot:[`item.is_maintenance`]="{ item }">
                <v-btn :color="item.is_maintenance ? 'success' : 'error'" @click="_openDialogSuspend(item)">
                  {{ item.is_maintenance ? "Maintenance" : "Not Maintenance" }}
                </v-btn>
            </template>
          </v-data-table>
        </div>
          <Dialog
            :darkmode="darkmode"
            :loading="loading"
            :dealerData="getCurrenBannerData"
            :dialog="getPopUp"
            :is-validate="valid"
            v-on:submit="handleSubmit"
            v-on:onCloseDialog="closeDialogCreate"
          />
          <v-dialog v-model="suspendDialog" persistent max-width="500px">
            <v-card >
              <v-card-title class="text-h5 py-5">
              do you want to Maintenance ?
              </v-card-title>
              <v-card-actions>
                <v-btn color="blue-grey"    class="ma-2 white--text"  @click="_closeDialogSuspend">Cancel</v-btn>
                <v-spacer></v-spacer>
                <!-- <v-btn     class="ma-2 white--text" :color="targetChannel && targetChannel.enable ?  'error':'success'" @click="_takeAction">{{targetChannel && targetChannel.enable ? "Inactive Channel" :"Active Channel"}}</v-btn> -->
                <v-btn  class="ma-2 white--text" :color="selectedDealer && selectedDealer.is_maintenance ?  'error':'success'"
                @click="_updateDealerSuspendStatus()">{{selectedDealer && selectedDealer.is_maintenance ? "Not Maintenance" :"Maintenance"}}</v-btn>
                
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- <div class="text-center pt-2">
          <v-pagination
            v-model="data.page"
            :length="
              getAllBanner
                ? getAllBanner.count % data.rowsPerPage != 0
                  ? getAllBanner.count / data.rowsPerPage + 1
                  : getAllBanner.count / data.rowsPerPage
                : 0
            "
          ></v-pagination>
          <v-text-field
            :value="data.rowsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="data.rowsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div> -->
        <div class="loader" v-if="this.loader == true"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    // ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      currency_type: 1,
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["name", "profile_image","barcode","dob"],
      search: "",
    },
    selectedDealer:[],
    suspendDialog : false,
    waiting:false,
    dialog: false,
    loading: false,
    baseUrl: process.env.VUE_APP_API_REPORT_URL
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("input.maintenance_id"), value: "_id" },
        { text: this.$t("input.is_maintenance"), value: "is_maintenance" },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_maintenance", [
      "getAllBanner",
      "getLoading",
      "getDeleteItems",
      "getPopUp",
      "getCurrenBannerData",
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      if (!this.getCurrenBannerData._id) {
        let formData = new FormData();
        formData.append("file", this.getCurrenBannerData.profile_image);
        formData.append("name", this.getCurrenBannerData.name);
        formData.append("dob", this.getCurrenBannerData.dob);
        formData.append("barcode", this.getCurrenBannerData.barcode);
        formData.append("password", this.getCurrenBannerData.password);
        formData.append("profile_image", this.getCurrenBannerData.profile_image);
        formData.append("position", this.getCurrenBannerData.position);
        formData.append("address", this.getCurrenBannerData.address);
        await this.createBannerData({ vm: this, data: formData });
        this.getCurrenBannerData = [];
        this.fetchPopUp(false);
      } else {
        let formData = new FormData();
        formData.append("file", this.getCurrenBannerData.profile_image);
        formData.append("name", this.getCurrenBannerData.name);
        formData.append("dob", this.getCurrenBannerData.dob);
        formData.append("barcode", this.getCurrenBannerData.barcode);
        formData.append("password", this.getCurrenBannerData.password);
        formData.append("profile_image", this.getCurrenBannerData.profile_image);
        formData.append("_id", this.getCurrenBannerData._id);
        formData.append("position", this.getCurrenBannerData.position);
        formData.append("address", this.getCurrenBannerData.address);
        await this.updateLimit({
          vm: this,
          data: formData
        });
        this.fetchPopUp(false);
      }
      await this.getData();
      this.loading = false;
    },

    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchGetAllBanner(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    closeDialogCreate() {
      this.getCurrentConfigUserData = {};
      this.fetchPopUp(false);
    },
    async _updateDealerSuspendStatus(){
      this.waiting = true;
      let data = {
        is_maintenance : !this.selectedDealer.is_maintenance,
        _id: this.selectedDealer._id// channel_type : this.channel_type
      }
      console.log("data",data);
      await this.$request
        .post({
          url: "/apps/updateMain",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.getData()
            this._closeDialogSuspend()
            this.waiting = false;
          }else{
            this.getData()
            this._closeDialogSuspend()
            this.waiting = false;
            
          }
        });
    },
    _openDialogSuspend(item){
      this.selectedDealer = item
      this.suspendDialog = true
    },
    _closeDialogSuspend(){
      this.selectedDealer = []
      this.suspendDialog = false
    },

    ...mapMutations("$_maintenance", ["UPDATE_BANNER_DATA", "DELETE_ITEMS"]),
    ...mapActions("$_maintenance", [
      "fetchPopUp",
      "createBannerData",
      "fetchGetAllBanner",
      "updateLimit",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
   // this.UPDATE_USERS([]);
    //this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>
