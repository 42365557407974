import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
	menus: {
		object:[],
		objectCount: null
	},
	currentMenu: {
		name: null,
		descr: null,
		icon: null,
		url: null,
		route: null,
		parent_id: null,
		permissions: null,
		order: null,
		is_visible: true,
	},
	menuParent: [],
	popUp: false,
	deleteItems: [],
	treeMenu: [],
	loading: true
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
};
