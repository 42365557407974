const headers = [
    { text: 'Actions', align: 'center', value: 'actions', sortable: false },
    {
        text: 'No',
        align: 'center',
        value: 'no',
    },
    { text: 'App', value: '_id.app_id', align: 'center' },
    { text: 'User', value: '_id.company', align: 'center' },
    { text: 'Balance', value: '_id.company_balance', align: 'center' },
    { text: 'Amount Turnover', value: 'amount', align: 'center' },
    { text: 'AmountWin/Lose', value: 'amount_win', align: 'center' },
    // { text: 'សរុបចាញ់', value: 'amount_lose' },
];

export default headers;