<template>
  <v-card-text v-if="visible">
    Please stand by
    <v-progress-linear
      indeterminate
      color="white"
      class="mb-0"
    ></v-progress-linear>
  </v-card-text>
</template>

<script>
export default {
  props: ["visible"],
};
</script>
