const headerAgent = [

  {
    text: 'No',
    align: 'center',
    value: 'no',
  },
  { text: 'View', align: 'center', value: 'actions', sortable: false },
  { text: 'App', value: '_id.app_id', align: 'center' },
  { text: 'Company', value: '_id.com', align: 'center' },
  { text: 'Senior', value: '_id.admin', align: 'center' },
  { text: 'Master', value: '_id.master', align: 'center' },
  { text: 'Agent', value: '_id.agent', align: 'center' },
  { text: 'Balance', value: '_id.agent_balance', align: 'center' },
  { text: 'Amount Turnover', value: 'amount', align: 'center' },
  { text: 'AmountWin/Lose', value: 'amount_win', align: 'center' },]

export default headerAgent;

