import userCompanyUnderManager from '@/modules/userCompanyUnderManager';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/userCompanyUnderManager',
		component: Layout,
		meta: {
			permission: 'userCompanyUnderManager',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'userCompanyUnderManager',
				component: userCompanyUnderManager
			}
		]
	}
];

export default routes;
