import cockFightChannel from '@/modules/channelGames/cockChannel';
import Layout from '@/layouts/default';

const routes = [{
    path: '/cockFightChannel',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: cockFightChannel,
        name: 'cockFightChannel'
    }]
}];

export default routes;