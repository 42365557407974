const getListOption = (state) => state.getListOption;
const getListOptionDefaultCustom = (state) => state.getListOptionDefaultCustom;
const getListOptionCustom = (state) => state.listOptionCustom;
const getListCreateOptionCustom = (state) => state.listCreateOptionCustom;
const getLoading = (state) => state.loading
const getLoadingCustom = (state) => state.loadingCustom;
const getRadioOption = (state) => state.radioOption;
const getCurrentRadioOption = (state) => state.radioOptionCurrent;





export default {
	getLoading,
	getListOption,
	getLoadingCustom,
	getListOptionDefaultCustom,
	getRadioOption,
	getCurrentRadioOption,
	getListOptionCustom,
	getListCreateOptionCustom

};
