<template>
 
  <v-btn :value="value" class="py-10 px-5 btn-height" active-class="btnn-active ">
    <div v-if="!isPairBanker && !isPairPlayer" :class="['cir', color]" > <p class="text">{{text}}</p><p class="sub-text">{{subText}}</p></div>
    <v-img v-if="isPairBanker" class="pair-card" src="images/pair_banker.png"> </v-img>
    <p class="text-pair" v-if="isPairBanker">Pair Banker</p>
    <v-img v-if="isPairPlayer" class="pair-card"  src="images/pair_player.png"> </v-img>
    <p class="text-pair" v-if="isPairPlayer">Pair Player</p>
  </v-btn>
</template>

<script>
export default {
  props: ['color', 'value', 'text', 'subText', 'isPairBanker', 'isPairPlayer'],
  name: "btn-result"
}
</script>

<style scoped>
.btn-height{
  height: 110px!important;
  
}
.text-pair{
  position:absolute;
  top: 110%;
  left: -20%;
  font-size: 20px;
}
.pair-card{
  width:100px;
  height:auto;
  z-index: 9999;
}
.text{
  position: absolute;
    margin: auto;
    left: 10%;
    top: 22%;
    color: black;
    font-size: 58px;
    font-weight: bolder;}
    .sub-text{
  position: absolute;
    margin: auto;
    left: 45%;
    top: 47%;
    color: black;
    font-size: 12px;
    font-weight: bolder;}
.btn-result{
  position: absolute;
  margin:auto
}
.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 55px;
  width: 55px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}

.cir-blue {
  border: 50px solid #0b4592;
}

.cir-red {
  border: 50px solid #b31013;
}

.cir-green {
  border: 50px solid #28a745;
}

.cir-cancel {
  border: 50px solid grey;
}
.btnn-active {
  background-color: #faa845 !important;
}
.cir-pair-banker{
  border: 50px solid grey;
  border-style: double;
}
.cir-pair-player{
  border: 50px solid grey;
  border-style: double ;
}

</style>
