<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="codeName"
    class="elevation-1"
    
    show-select
    item-key="codeName"
    :single-select="singleSelect"
    style="font-family: 'khmer mef1'"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>TRANSACTIONS LIST</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>

        <v-spacer></v-spacer>
        <v-btn>DELETE ALL</v-btn>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card >
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  text @click="closeDelete">Cancel</v-btn>
              <v-btn  text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.active`]="{ item }">
      <v-chip >
        {{ item.active }}
      </v-chip>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import headers from "./_api/columns";
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    active: true,
    singleSelect: false,
    selected: [],
    headers,
    desserts: [],
    search: null,
    editedIndex: -1,
    editedItem: {
      proposer: "",
      operationUnit: "",
      account_info: "",
      amount_proposer: "",
      date: "",
      recipient: "",
      account_number: "",
      active: "",
    },
    defaultItem: {
      proposer: "",
      operationUnit: "",
      account_info: "",
      amount_proposer: "",
      date: "",
      recipient: "",
      account_number: "",
      active: "",
    },
  }),
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "CREATE NEW" : "EDIT ITEM";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
    this.fetchListPayments();
  },
  methods: {
    initialize() {
      this.desserts = [
        {
          proposer: "Master",
          operationUnit: "ដាក់ប្រាក់",
          account_info: "ABA-90303034",
          amount_proposer: "ABA-9494030",
          date: "11/Jun/2021",
          recipient: "Player1",
          account_number: "ABA-440304003",
          active: "Active",
        },
      ];
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    ...mapActions("$_transaction", ["fetchListPayments"]),
  },
};
</script>

<style lang="scss" scoped>
</style>