import masterReports from '@/modules/masterReports';
import Layout from '@/layouts/default';

const routes = [{
    path: '/masterReports',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: masterReports,
        name: 'masterReports'
    }]
}];

export default routes;