const UPDATE_MENUS = (state, data) => {
	state.menus = data;
};

const UPDATE_POPUP = (state, data) => {
	if (!data) {
		state.currentMenu = {
			name: null,
			descr: null,
			icon: null,
			url: null,
			route: null,
			parent_id: null,
			permissions: null,
			order: null,
			is_visible: true,
		}
	}
	state.popUp = data;
};

const DELETE_ITEMS = (state, data) => {
	state.deleteItems = data
}

const UPDATE_CURRENT_MENU = (state, data) => {
	state.currentMenu = data;
};

const UPDATE_PARENT_MENU = (state, data) => {
	state.menuParent = data;
};

const UPDATE_TREE_MENU = (state, data) => {
	state.treeMenu =  data
}

const UPDATE_LOADING = (state, data) => {
	state.loading = data
}

export default {
	UPDATE_MENUS,
	UPDATE_POPUP,
	DELETE_ITEMS,
	UPDATE_CURRENT_MENU,
	UPDATE_PARENT_MENU,
	UPDATE_TREE_MENU,
	UPDATE_LOADING
};
