import betSettings from '@/modules/betSettings';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/bet-settings',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: betSettings,
				name: 'betSettings'
			}
		]
	}
];

export default routes;
