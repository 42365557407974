// import reportOutStanding from '@/modules/reportOutStanding';
import yukiCmReportOutStanding from '@/modules/outstandingReports/yukiCm';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/yukiCmReportOutStanding',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: yukiCmReportOutStanding,
				name: 'yukiCmReportOutStanding'
			}
		]
	}
];

export default routes;
