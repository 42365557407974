import ocStatementSummaryMasterUSD from '@/modules/statementReports/currency/ocStatementSummaryMasterUSD';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryMasterUSD',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryMasterUSD,
        name: 'ocStatementSummaryMasterUSD'
    }]
}];

export default routes;