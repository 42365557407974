<template>
  <div>
    <template class="pt-5">
      <Header
        :darkmode="darkmode"
        :params="data"
        :user-permission="userAccessPermission"
        v-on:refreshPage="refreshPage"
        class="mb-5"
      />
    </template>
      <v-col cols="12" sm="12" md="9" class="pl-5" style="position: absolute; top:85px; z-index: 1;">
        <v-row>
          <v-col cols="6" sm="6" md="3" class="pl-5">
            <v-select
          v-model="channelType"
          :items="itemsChannel"
          item-value="id" item-text="name"
          label="Channel #  "
          class="channel-type"
          style="min-width:100%"
          @input="selectChannelType(channelType)"
          solo
      ><template v-slot:prepend>
        <v-icon color="green darken-2">mdi-television</v-icon>
      </template>
      </v-select>
          </v-col>
               <v-col cols="6" sm="6" md="2" class="pl-5" >
            <v-text-field
          v-model="data.betType"
          label="Bet Type"
          class="channel-type"
          style="min-width:100%"
          solo
      ></v-text-field>
          </v-col>
                <v-col cols="6" sm="6" md="2" class="pl-5" >
            <v-text-field
          v-model="data.userName"
        
          label="User Name"
          class="channel-type"
          style="min-width:100%"
          solo
      ></v-text-field>
  
          </v-col>
            <v-col cols="6" sm="6" md="2" class="pl-5" >
            <v-text-field
          v-model="data.publicIp"
        
          label="IP"
          class="channel-type"
          style="min-width:100%"
          solo
      ></v-text-field>
  
          </v-col>
                          <v-col cols="6" sm="6" md="3" class="pl-5" >
           <v-btn color="primary" style="margin-top:30px" @click="getData">
                Search
           </v-btn>
          </v-col>
        </v-row>
      
    </v-col>
    <v-tabs color="deep-purple accent-4" right>
      <v-tab @click="_gotoOption(1)">USD</v-tab>
      <v-tab @click="_gotoOption(2)">Khmer</v-tab>
      <v-tab @click="_gotoOption(3)">Thai</v-tab>
      <v-tab-item v-for="n in 3" :key="n">
        <v-data-table
          :headers="headers"
          :items="getDataCancelBetting ? getDataCancelBetting.object : []"
          :footer-props="footerProps"
          item-key="_id"
          class="elevation-1 pt-5"
          :server-items-length="
            getDataCancelBetting && getDataCancelBetting.objectCount? getDataCancelBetting.objectCount.count : null
          "
          style="font-family: 'khmer mef1'"
          :page.sync="data.page"
          :items-per-page.sync="data.rowsPerPage"
          :sort-desc.sync="data.descendingFormat"
          :sort-by.sync="data.sortByFormat"
          @update:page="getData"
          @update:items-per-page="getData"
          @update:sort-desc="getData"
          mobile-breakpoint="0"
          :loading="getLoading"
        >
         <template v-slot:[`item.actions`]="{ item }">
            <ActionComponents
              :darkmode="darkmode"
              :item="item"
              :params="data"
              :user-permission="userAccessPermission"
              v-on:refreshPage="getData"
            />
          </template>
          <template v-slot:[`item.amount`]="{ item }">
           {{currencyFormatEn(item.amount)}}
          </template>
          <template v-slot:[`item.no`]="{ item }">
            {{ (getDataCancelBetting.object.indexOf(item)  + ( (data.page -1 ) *  data.rowsPerPage))+ 1 }}
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>

    <Dialog
      :darkmode="darkmode"
      :dialog="getPopUp"
      
      :is-validate="valid"
      v-on:submit="handleSubmit"
      v-on:onCloseDialog="closeDialogCreate"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      currency_type: 0,
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "target",
        "percentage_meron",
        "percentage_wala",
        "start_time",
        "end_time",
        "meron.name",
        "wala.name",
      ],
      search: "",
      vip_option: 1,
      betType:"",
      userName:"",
      publicIp:"",
      selectOption:1,
      channelType : 0,
    },
    itemsChannel: [
        {name:"ប៉ុស្តិ៌ ទាំងអស់", id:0},
        {name:"ប៉ុស្តិ៌ 1", id:1},
        {name:"ប៉ុស្តិ៌ 2", id:2},
        {name:"ប៉ុស្តិ៌ 3", id:3},
        {name:"ប៉ុស្តិ៌ 4", id:4}
    ],
    channelType:0,
    dialog: false,
  }),
  computed: {
    headers() {
      return [
          { text: this.$t("header.no"), value: "no" },
        { text: this.$t("header.username"), value: "user_name" },
        { text: this.$t("header.public_id"), value: "public_ip" },
        { text: this.$t("header.channel_type"), value: "channel_type" },
        { text: this.$t("header.fight_no"), value: "fight_no" },
        { text: this.$t("header.date"), value: "date" },
        { text: this.$t("header.bet_type"), value: "co_type_of_betting.name" },
        { text: this.$t("header.amount"), value: "amount" },
        { text: this.$t("header.payout"), value: "payout" },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", user : "getUser"}),
    ...mapGetters("$_cancelBetting", [
      "getDataCancelBetting",
      "getPopUp",
      "getLoading"
    ]),
    ...mapGetters("$_role", ["getAllRolesDependOnUser"]),
  },
  async created() {
    await this.getData();
  },
  methods: {
    async handleSubmit() {
      // this.loading = true;
        let uploadData = {
          percentage: this.getCurrentPayOutData.percentage,
        };
        await this.updatePayout({
          vm: this,
          data: uploadData,
          _id: this.getCurrentPayOutData._id,
        });
        this.fetchPopUp(false);
      
      await this.getData();
      // this.loading = false;
    },

     _gotoOption(selectOption){
      this.data.selectOption = selectOption;
      this.getData();
    },

    async getData() {
        await this.fetchGetCancelBetting(this.data);
    },

    closeDialogCreate() {
      this.getCurrentPayOutData = {};
      this.fetchPopUp(false);
    },

    selectChannelType(channelType){
      this.data.channelType = channelType;
      this.getData();
    },
    refreshPage(){
      this.data.userName = "";
      this.data.betType = "";
      this.getData()
    },
     currencyFormatEn(num) {
      num = parseFloat(num);
      return num.toLocaleString("en");
    },

    ...mapMutations("$_cancelBetting", [
      "UPDATE_DATA_CANCEL_BETTING",
      "LOADING"
    ]),
    ...mapActions("$_cancelBetting", [
      "fetchPopUp",
      "fetchGetCancelBetting"
    ]),
    
  },

};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
</style>
