import topRangeWinnerReports from '@/modules/topRangeWinnerReports';
import Layout from '@/layouts/default';

const routes = [{
    path: '/topRangeWinnerReports',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: topRangeWinnerReports,
        name: 'topRangeWinnerReports'
    }]
}];

export default routes;