import ocStatementSummaryPlayerKm from '@/modules/statementReports/currency/ocStatementSummaryPlayerKm';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryPlayerKm',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryPlayerKm,
        name: 'ocStatementSummaryPlayerKm'
    }]
}];

export default routes;