const UPDATE_ROLE_LEVEL_DATA = (state, data) => {
    state.roleLevelData = data;
}
const UPDATE_CHANNEL_AFTER_RESULT = (state, data) => {
    state.currentChannel = data;
}
const LOADING = (state, data) => {
    state.loading = data;
}

const UPDATE_USERS = (state, data) => {
    state.users = data;
};

const UPDATE_CURRENT_DATA = (state, data) => {
    state.currentRoleLevelData = data;
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentRoleLevelData = {
            user_name: null,
            role_name: null,
            child_role_balance: null,
            child_role_name: null,
            user_id: null
        }
    }
    state.popUp = data;
};
const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
};


export default {
    UPDATE_ROLE_LEVEL_DATA,
    UPDATE_USERS,
    UPDATE_CHANNEL_AFTER_RESULT,
    LOADING,
    UPDATE_POPUP,
    UPDATE_CURRENT_DATA,
    DELETE_ITEMS
};