import ocStatementSummarySeniorKm from '@/modules/statementReports/currency/ocStatementSummarySeniorKm';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummarySeniorKm',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummarySeniorKm,
        name: 'ocStatementSummarySeniorKm'
    }]
}];

export default routes;