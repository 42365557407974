// import reportOutStanding from '@/modules/reportOutStanding';
import klakloukCmReportOutStanding from '@/modules/outstandingReports/klakloukCm';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/klakloukCmReportOutStanding',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: klakloukCmReportOutStanding,
				name: 'klakloukCmReportOutStanding'
			}
		]
	}
];

export default routes;
