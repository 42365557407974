import LayoutAuth from '@/layouts/auth';
import Auth from '@/modules/auth';

const routes = [
    {
        path: '',
        component: LayoutAuth,
        children: [
            {
                path: 'login',
                name: 'login',
                component: Auth
            }
        ]
    }
]

export default  routes;