import userSubAgent from '@/modules/userSubAgent';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/userSubAgent',
		component: Layout,
		meta: {
			permission: 'userSubAgent',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'userSubAgent',
				component: userSubAgent
			}
		]
	}
];

export default routes;
