const UPDATE_CHANNEL = (state, data) => {
    state.currentChannel = data;
}
const UPDATE_CHANNEL_AFTER_RESULT = (state, data) => {
    state.currentChannel = data;
}
const BTN_RESULT = (state, data) => {
    state.objSnNumber = data;
}
const LOADING = (state, data) => {
    state.loading = data;
}

const LOADINGRESULT = (state, data) => {
    state.loadingResult = data;
}

const SET_RESULT = (state, data) => {
    state.selectResult = data
}


const RESULT_TABLE = (state, data) => {
    state.objSnResult = data
}

const SUMMARY_RESULT = (state, data) => {
    state.summaryResult = data
}
const POPUP_DIALOG_RESULT = (state, data) => {
    state.dialogResult = data
}
const UPDATE_DEALER_INFO = (state, data) => {
    state.dealerInfo = data
}

const POPUP_DIALOG_SCAN_DEALER = (state, data) => {
    state.dealerDialog = data
}
const ALLOW_SCAN = (state, data) => {
    state.allowScanCard = data;
  };
  const RESULT_EVENT = (state, data) => {
    state.resultEvent = data
}
const CURRENT_CARD = (state, data) => {
    state.currentCard = data
}

export default {
    UPDATE_CHANNEL,
    BTN_RESULT,
    SET_RESULT,
    SUMMARY_RESULT,
    RESULT_TABLE,
    UPDATE_CHANNEL_AFTER_RESULT,
    LOADING,
    LOADINGRESULT,
    POPUP_DIALOG_RESULT,
    UPDATE_DEALER_INFO,
    POPUP_DIALOG_SCAN_DEALER,
    ALLOW_SCAN,
    RESULT_EVENT,
    CURRENT_CARD
};