<template>
  <div>
    <!-- sort-by="user_name" -->
    <v-data-table
      :headers="headers"
      :items="getStatement ? getStatement.object : []"
      
      :server-items-length="getCountStatement.count"
      item-key="_id._id"
      class="elevation-1"
      style="font-family: 'khmer mef1'"
      :footer-props="footerProps"
      :page.sync="data.page"
      :items-per-page.sync="data.rowsPerPage"
      :sort-desc.sync="data.descendingFormat"
      :sort-by.sync="data.sortByFormat"
      @update:page="getData"
      @update:items-per-page="getData"
      @update:sort-desc="getData"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header
          :darkmode="darkmode"
          :params="data"
          :user-permission="userAccessPermission"
          :user="user"
          :time="time"
          :listChannelType="getChannelType"
          @child-checkbox="checkboxValue"
        />
        <div class="between w-full mb-3 mt-0 pr-3 " v-if="user.role_name != 'CONTROLLER'">
          <v-btn
            v-for="(item, i) in defaultFilterDates"
            small
            color="success"
            class="mx-4 mb-4"
            :key="i"
            @click="selectDate(item)"
          >
            <span style="color: white">{{ item.name }}</span>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item`]="{ item }">
        <tr>
          <td>{{ getStatement.object.indexOf(item) + 1 }}</td>
          <td>{{ item._id.app_id }}</td>
           <td>{{ item.user_name }}</td>
          <td>{{ currencyFormat(item.balance) }}</td>
          <td class="amount-bet-color to-detail" @click="_goToDetail(item._id)">{{ currencyFormat(item.amount) }}</td>
          <td :class="item.amount_win < 0 ? 'lose-color' : 'win-color'">{{ currencyFormat(item.amount_win) }}</td>
        
        </tr>
      </template>


      <template slot="body.append">
        <tr>
          <td :colspan="4" class="text-xs-center pl-4 py-2 ">
            {{ $t("title.total") }}
          </td>
          <td class="text-xs-center total-style pl-4  py-2">
            {{
              getCountStatement.amount
                ? currencyFormat(getCountStatement.amount)
                : 0
            }}
          </td>
          <td
            class="text-xs-center pl-4 total-style font-weight-bold  py-2"
            :style="
              getCountStatement.amount_win < 0
                ? 'color:#BA2D4A'
                : 'color:#39afd6'
            "
          >
            {{
              getCountStatement.amount_win
                ? currencyFormat(
                    getCountStatement.amount_win
                  )
                : 0
            }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <loader
      v-if="this.loader == true"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
    <v-dialog
        :value="dialog"
        max-width="1200"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar>
          <v-btn icon  @click="dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-toolbar-title>Detail Player Statement</v-toolbar-title>
            </v-col>
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                
            </v-col>
              <v-col cols="8" sm="8" md="4" class="text-right">
                <v-text-field
                    v-model="v_search"
                    append-icon="mdi-magnify"
                    label="Search ( Fight no, គូ ប្រកួត )"
                    single-line
                    hide-details
                    clearable
                    class="mr-5"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" sm="4" md="2" class="text-right mb-5">
                <v-btn
                    class="white--text btn-custom mr-4 mt-3"
                    color="green darken-1"
                    depressed
                    @click="searchq"
                >
                  <!-- @click="fetchListStatement(params)" -->
                  SEARCH
                </v-btn>
              </v-col>
          </v-row>
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="date"
              :headers="headersDetail"
              :footer-props="footerProps"
              :items="detailData ? detailData : []"
              :server-items-length="detailDataCount ? parseInt(detailDataCount.count): null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              @update:page="updatePage"
              @update:items-per-page="updatePage"
              @update:sort-desc="updatePage"
              :loading="getLoadingDetail"
              mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ detailData ? (detailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                <td>{{ item.app_id }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.fight_no }}</td>
                <td class="amount-bet-color">
                   {{currencyFormat(item.amount)}}
                </td>
                <td>{{ item.payout }}</td>
                <td>{{ item.amount_win }}</td>
                <td>{{ item.cast }}</td>
              </tr>
            </template>
            <template slot="body.append">
              
              
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headers from "./_api/columns";
import moment from "moment";
import headersDetail from "./_api/columns_detail";
import axios from 'axios';

export default {
  components: {
    Header: () => import("./_components/HeaderTable.vue"),
  },

  data: () => ({
    valid: true,
    headers: headers,
    headersDetail:headersDetail,
    search: null,
    v_search:null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 10,
      fields: ["_id", "user_name", "balance", "amount", "amount_win"],
      search: "",
      startDate: new Date().toISOString().substr(0, 10),//new Date().toISOString().substr(0, 10),//
      endDate: new Date().toISOString().substr(0, 10),
      channelType:1,
      currencyType:2
    },
    dataRequest: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 10,
      fields: ["co_type_of_betting.name", "co_type_of_betting.desc", "co_type_of_betting.color", "date", "fight_no","is_win","amount","amount_win","cast"],
      search: "",
      startDate: new Date("2021-01-01").toISOString().substr(0, 10),//new Date().toISOString().substr(0, 10),//
      endDate: new Date().toISOString().substr(0, 10),
      channelType:1,
      currencyType:2
    },
    dialog: false,
    loading: false,
    loader: false,
    user_id: null,
    detailData:[],
    detailDataCount:[],
    check: {
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
    },
    format: "YYYY-MM-DD",
    defaultFilterDates: [
      {
        name: "Today",
        active: false,
      },
      {
        name: "Yesterday",
        active: false,
      },
      {
        name: "This Week",
        active: false,
      },
      {
        name: "Last Week",
        active: false,
      },
      {
        name: "This Month",
        active: false,
      },
      {
        name: "Last Month",
        active: false,
      },
    ],
    getLoadingDetail:false,
    totalItems: 0,
    time: null,
  }),
  watch: {
    user: function() {
      this.getData();
      this.getChannelTypeData();
    },
    data: function() {
      this.getData();
      this.getChannelTypeData();
    },
    getStatement: function() {},
  },
  computed: {
    reportUrl: function() {
      return "getViewPlayerCurrencyApiBranch";
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", user: "getUser" }),
    ...mapGetters("$_statementPlayerReports", ["getStatement", "getCountStatement","getChannelType"]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
    this.getChannelTypeData();
  },
  methods: {
    moment,
     _goToDetail(_id){
      this.user_iid = _id;
      this.loadDetailData(_id);
      this.dialog = true;
    },
    updatePage() {
      this.getLoadingDetail = true;
      this.loadDetailData(this.user_iid);  
      this.getLoadingDetail = false;
    },
    searchq() {
      if( this.defaultSelected && this.defaultSelected._id) this.channel_type = this.defaultSelected._id;
      else this.channel_type = this.defaultSelected;
      this.dataRequest.search = this.v_search;
      this.loadDetailData(this.user_iid)
    },
    async loadDetailData(_id){
      
      this.detailData = [],
      this.detailDataCount = [],
      this.getLoadingDetail = true;
      if( this.channel_type != null &&  typeof this.channel_type === 'object' ) {
        this.dataRequest.channelType = this.channel_type.id;
      }
      if( this.channel_type != null &&  typeof this.channel_type !== 'object' ) {
        this.dataRequest.channelType = this.channel_type;
      }
      this.dataRequest.startDate = this.data.startDate;
      this.dataRequest.endDate = this.data.endDate;
       this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id._id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);


      let reportUrl = process.env.VUE_APP_API_REPORT_URL + "adminReport/getViewPlayerDetailCurrencyApiBranch/"+_id;
      await axios.post(reportUrl, this.dataRequest).then(res => {
         
          if (res.data.code) {
            
            this.detailData = res.data.data.object;
            this.detailDataCount = res.data.data.objectCount;
            this.getLoadingDetail = false;
            
          }else{    
            this.getLoadingDetail = false;
          }
      })
      .catch(err => { console.log(err) })
    },
    selectDate({ name }) {
      const { format } = this;
      switch (name) {
        case "Today":
          this.startDate = this.moment().format(format);
          this.endDate = this.moment().format(format);
          break;
        case "Yesterday":
          this.startDate = this.moment()
            .subtract(1, "days")
            .format(format);
          this.endDate = this.moment().subtract(1, "days").format(format);
          break;
        case "This Week":
          this.startDate = this.moment()
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .endOf("isoWeek")
            .format(format);
          break;
        case "Last Week":
          this.startDate = this.moment()
            .subtract(1, "isoweek")
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "isoweek")
            .endOf("isoweek")
            .format(format);
          break;
        case "This Month":
          this.startDate = this.moment().format("YYYY-MM-01");
          this.endDate = this.moment().format(format);
          break;
        case "Last Month":
          this.startDate = this.moment()
            .subtract(1, "months")
            .startOf("month")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "months")
            .endOf("month")
            .format(format);
          break;
        default:
          break;
      }
      const { startDate, endDate } = this;
      this.time = { startDate: startDate, endDate: endDate };
      this.data.startDate = startDate;
      this.data.endDate = endDate;
      this.getData();
      //console.log("this.getData():",this.getData());
      this.check = { startDate, endDate };
    },
    // Gets the checkbox information from the child component
    checkboxValue: function(params) {
      this.check = params;
      this.data.startDate = params.startDate;
      this.data.endDate = params.endDate;
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString('en')
    },
    async getChannelTypeData() {
       await this.fetchChannelType();
    },
    async getData() {
      try {
        this.data.channelType = this.getChannelType.channel_type;
        this.loader = true;
        let preData = {
          data: this.data,
          user_id: this.user._id,
          reportUrl: this.reportUrl,
        };
        //console.log('pre_data:',preData); 
        this.checkUserPermission("VIEW")
          ? await this.fetchListStatement(preData)
          : [];
        // console.log(this.getCountStatement)
        this.loader = false;
      } catch (error) {
        // console.log("")
      }
    },
    ...mapMutations("$_statementPlayerReports", ["LIST_STATEMENT", "COUNT-STATEMENT","LIST_CHANNEL_TYPE"]),
    ...mapActions("$_statementPlayerReports", ["fetchListStatement","fetchChannelType"]),
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
.amount-bet-color {
  color: goldenrod !important;
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.amount-bet-color {
  color: goldenrod !important;
}
.to-detail{
  cursor: pointer;
  text-decoration: underline;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
</style>
