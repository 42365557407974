const getApp = (state) => state.apps;
const getPopUp = (state) => state.popUp;
const getCurrentApp = (state) => state.currentApp;
const getDeleteItems = (state) => state.deleteItems;
const getLoading = (state) => state.loading;
const getListUserCompany = (state) => state.listCompany;
const getListUserSenior = (state) => state.listSenior;

export default {
	getLoading,
	getApp,
	getPopUp,
	getCurrentApp,
	getDeleteItems,
	getListUserCompany,
	getListUserSenior
};
