/* eslint-disable no-unused-vars */
import Vue from 'vue';
let request = Vue.prototype.$request;

const fetchListPayments = async ({ commit }) => {
	const data = {
		descending: true,
		sortBy: '_id',
		page: 1,
		rowsPerPage: 10,
		fields: [ 'status', 'type', 'updatedAt', 'amount', 'old_balance', 'new_balance' ],
		search: 'deposit'
	};
	request = Vue.prototype.$request;
	const response = await request.post({
		url: 'payments/getAllByUserId',
		data
	});
	if (response.data.code) {
		commit('UPDATE_PAYMENTS', response.data.data);
	}
	return response;
};

export default {
	fetchListPayments
};
