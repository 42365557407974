/* eslint-disable no-unused-vars */
import axios from 'axios';
import Request from '@/services/axios'
let request = new Request;

const fetchListStatement = async({ commit }, preData) => {
    let data = preData.data
    // console.log('data:',data);
    let reportUrl = process.env.VUE_APP_API_REPORT_URL + 'adminReport/getViewCompanyCurrencyApiBranch';
    
    const response = await axios.post(reportUrl, data)
      .then(response => {
        if (response.data.code) {
            commit('LIST_STATEMENT', response.data.data);
            commit('COUNT_STATEMENT', response.data.data.objectCount);
        }
      })
      .catch(err => { console.log(err) })
};

const fetchChannelType = async({ commit }) =>  {
  let data = { 
    "base_url": process.env.VUE_APP_API_REPORT_URL
  }
  const response = await request.post({
    url: 'users/getChannelTypeReportBySub',
    data
  });
  if (response.data.code) {
      commit('LIST_CHANNEL_TYPE', response.data.data.object);
   }
   //prevent db don't have data yet
   if(response.data.data.object == null) {
    let object = {
      "_id": "62b3231fcabe536cbc9cb2b4",
      "channel_type": 1,
      "base_url": "http://localhost:3080/v1/"
  }
    commit('LIST_CHANNEL_TYPE', object);
  }
}

export default {
    fetchListStatement,
    fetchChannelType
};