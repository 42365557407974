// update data over statement

const LIST_STATEMENT = (state, data) => {
    state.statement = data;
};

const LIST_CHANNEL = (state, data) => {
    state.statement = data;
};

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}


export default {
    LIST_STATEMENT,
    LIST_CHANNEL,
    UPDATE_LOADING
};