// import reportOutStanding from '@/modules/reportOutStanding';
import han2ReportOutStanding from '@/modules/outstandingReports/han2';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/han2OutStanding',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: han2ReportOutStanding,
				name: 'han2ReportOutStanding'
			}
		]
	}
];

export default routes;
