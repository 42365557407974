<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <template>
              <v-select
                :items="[true, false]"
                v-model="channel.enable"
                label="Enable"
              />
            </template>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="channel.fight_no"
              label="Fight Number"
              readonly
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="channel.info"
              label="Channel Information"
              :rules="rules"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="channel.video_info"
              label="Video Information"
              :rules="rules"
            />
          </v-col>
          <v-col v-if="channel.live_urls">
            <v-row>
               <v-col cols="6">
                  <v-text-field
                    v-model="channel.live_urls[0]"
                    label="Current Url"
                    :rules="rules"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="channel.live_urls[1]"
                    label="Change Url"
                    :rules="rules"
                  />
              </v-col>
            </v-row>
          </v-col>
         
        </v-row>
        <v-row>
          <v-col cols="2" class="text-left">
            <v-btn  color="primary mr-2" @click="_selectGame" :disabled=" channel.is_open == false || socketChannelCountdown ? true: false ">
                <v-icon class="mr-2" v-if="channel.is_open == false || socketChannelCountdown ? true: false">fas fa-circle-notch fa-spin</v-icon> Start Game
            </v-btn>
          </v-col>
           <v-col cols="3" class="text-left">

            <v-btn  color="warning" @click="_select" :disabled="channel.is_open == true || (socketChannelCountdown <=10 && socketChannelCountdown >0 ) ? true: false ">
              Set Result
            </v-btn>
          </v-col>
          <v-col cols="1" class="text-center text-h5">
            <span class="uppercase">
              {{socketChannelCountdown ? socketChannelCountdown : defaultCountDown }}
            </span>
          </v-col>
          <v-col cols="4" class="text-center text-h5">
            <span class="uppercase">
              Channel is
            </span>
            <span
              :class="[
                channelStatus(channel.is_open).color,
                'uppercase pl-4 channel-status'
              ]"
              
              >
               {{channelStatus(channel.is_open).status }}</span
            >
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn color="primary"  @click="update" :disabled="channel.is_open == false || loading == true ? true : false ">
               <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon> Update
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="dialogResult" width="700" height="600">
      <v-card class="mx-auto my-3">
        <v-row>
          <v-col
            cols="6"
            sm="6"
            style="text-align:center; border:1px solid #000000; background-color:#ffffff"
          >
            <v-btn
              depressed
              color="white"
              height="80"
              width="150"
              @click="_submitResult(objSnNumber[0])"
            >
              <v-img height="60" width="60" src="images/1number.png"></v-img>
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            style="text-align:center; border:1px solid #000000; background-color:#ffffff"
          >
            <v-btn
              depressed
              color="white"
              height="80"
              width="150"
              @click="_submitResult(objSnNumber[5])"
            >
              <v-img height="60" width="60" src="images/6number.png"></v-img>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            style="text-align:center; border:1px solid #000000; background-color:#ffffff"
          >
            <v-btn
              depressed
              color="white"
              height="50"
              width="65"
              @click="_submitResult(objSnNumber[1])"
            >
              <v-img height="50" width="65" src="images/2number.png"></v-img>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            style="text-align:center; border:1px solid #000000; background-color:#ffffff"
          >
            <v-btn
              depressed
              color="white"
              height="50"
              width="65"
              @click="_submitResult(objSnNumber[2])"
            >
              <v-img height="50" width="65" src="images/3number.png"></v-img>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            style="text-align:center; border:1px solid #000000; background-color:#ffffff"
          >
            <v-btn
              depressed
              color="white"
              height="50"
              width="65"
              @click="_submitResult(objSnNumber[3])"
            >
              <v-img height="50" width="65" src="images/4number.png"></v-img>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            style="text-align:center; border:1px solid #000000; background-color:#ffffff"
          >
            <v-btn
              depressed
              color="white"
              height="50"
              width="65"
              @click="_submitResult(objSnNumber[4])"
            >
              <v-img height="50" width="65" src="images/5number.png"></v-img>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="390"
      max-height="320"
    >
      <v-card style="height:250px">
        <v-card-title class="justify-center res-img-containter">
          <v-img
            class="result-image"
            :src="'images/' + resultData.order + 'number.png'"
          ></v-img>
        </v-card-title>
        <v-card-actions>
          <v-btn color="pink ml-4 confirm-btn" @click="_cancelResult">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1 mr-4 confirm-btn" @click="_saveResult" :disabled="loadingResult == true ? true: false " >
             <v-icon class="mr-2" v-if="loadingResult">fas fa-circle-notch fa-spin</v-icon>
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: ["channel", "objSnNumber" ,"socketChannelType", "socketChannelCountdown"],

  data() {
    return {
      valid: true,
      rules: [value => !!value || "Required."],
      dialogResult: false,
      confirmDialog: false,
      resultData: [],
      n1: null,
      updateChanel: [],
      defaultCountDown :30,
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_channel", {
      loading: "getLoading",
      loadingResult:"getLoadingResult"
    })
  },
  watch: {
    socketChannelType: function () {
    },
    socketChannelCountdown: function () {
    },
    loading: function () {
    },
    loadingResult: function () {
    },
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.$emit("submitUpdateChanel", this.channel);
      }
    },

    channelStatus(status) {
      if (status === true) {
        return {
          status: "Open",
          color: "green--text"
        };
      } else if (status === false) {
        return {
          status: "Close",
          color: "red--text"
        };
      } else {
        return {
          status: "LIVE",
          color: "warning--text"
        };
      }
    },

    _select() {
      this.dialogResult = true;
    },

    _selectGame() {
      this.$socket.emit("server-start-count-down", { status: "start" });
    },

    _cancelResult() {
      this.confirmDialog = false;
    },

    _submitResult(i) {
      this.resultData = i;
      this.confirmDialog = true;
    },

    async _saveResult() {
      this.$emit("submitResult", this.resultData);
      this.confirmDialog = false;
      this.dialogResult = false;
    },
    ...mapMutations("$_channel", ["LOADING"]),
  }
};
</script>

<style scoped>
.channel-status {
  font-weight: 700;
  font-size: 28px;
  padding-top: 3px;
}
.result-image {
  max-width: fit-content;
  max-height: 100%;

  display: block;

  object-fit: contain;
}
.res-img-containter {
  width: 385;
  height: 180px;
  justify-content: center;
}
.confirm-btn{
  color: white !important; font-weight:700
}
</style>
