import ocStatementSummaryAgentUSD from '@/modules/statementReports/currency/ocStatementSummaryAgentUSD';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryAgentUSD',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryAgentUSD,
        name: 'ocStatementSummaryAgentUSD'
    }]
}];

export default routes;