<template>
  <div>
    <!-- {{this.objCoResult}} -->
    <v-layout class="mt-3">
      <v-col col="12" class="pb-0 pt-1" style="width:100%; height:67px; border:1px solid black">
        <v-row >
          <v-col col="6" class="pt-5">
            <v-row style="text-align:center; border-right:1px solid black; justify-content: left;">
              <p class="result-number"><span class="text-info">1 Fan</span> =  <span class="result-number">{{ objSummaryCoResult['1'] ? objSummaryCoResult['1'] : 0}}</span>, </p>
              <p class="result-number"><span class="text-info">2 Fan</span>  =  <span class="result-number">{{  objSummaryCoResult['2'] ?  objSummaryCoResult['2'] : 0}}</span> </p>
              <p class="result-number"><span class="text-info">3 Fan</span> =  <span class="result-number">{{  objSummaryCoResult['3'] ?  objSummaryCoResult['3'] : 0}}</span>, </p>
              <p class="result-number"><span class="text-danger">4 Fan</span>  =  <span class="result-number">{{  objSummaryCoResult['4'] ?  objSummaryCoResult['4'] : 0}}</span> </p>
          
            </v-row>
          </v-col>
          <v-col col="" class="pt-5">
            <v-row style="text-align:center; border-left:1px solid black; justify-content: center;" class="pt-3">
              <p class="result-number px-2"><span class="text-warning">Game No</span>  =  <span class="result-number">{{ channel.fight_no}}</span></p>
              <!-- <p class="result-number pl-5 pr-2"><span class="text-danger">Shoe</span> =  <span class="result-number">{{ channel.shoe}}</span>, </p> -->
              
              <p class="result-number px-2"><span class="text-success">Group</span>  =  <span class="result-number">{{  channel.group_no}}</span></p>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
    <v-row>
      <v-layout class="table-result" style="width:100%!important">
      <table class="d-flex flex-row justify-content-center" >
        <tbody style="background-color: #fff; border-radius: 5px;">
          <tr v-for="r in 6" :key="r">
            <td v-for="c in channel.column" :key="c" style="padding:0px; margin:0px">
              <div v-if="c > channel.column - channel.fix_column" class="td-table-style" style="position:relative">
                <button :class="matchResult(r, c).color" class="result-block"
                  :disabled="matchResult(r, c).disable || channel.is_open != false"
                  @click="_select(r, calculateColumn(c))">
                  <div class="justify-center text-center fight-no-style"> {{ matchResult(r, c).name }} </div>
                  <button class="show-fightNo" :class="'r-' + matchResult(r, c).color"> {{ matchResult(r, c).fightNo }}
                  </button>
                </button>

              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </v-layout>
   
    </v-row>
    <v-dialog v-model="dialogResult" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8" v-if="objCoTypeOfBetting">
          <v-btn-toggle v-model="defineResult.result_code">
            <btn-result v-if="disableBlue != true" :value="0" :text="'B'" :subText="'anker'" :color="'cir-red'"
              :disabled="disableBlue" />
            <btn-result v-if="disableRed != true" :value="1" :text="'P'" :subText="'layer'" :color="'cir-blue'"
              :disabled="disableRed" />
            <btn-result :value="2" :text="'T'" :subText="'ie'" :color="'cir-green'" />
            <btn-result :value="5" :text="'C'" :subText="'ancel'" :color="'cir-cancel'" />
          </v-btn-toggle>

        </v-card-text>
        <v-card-text class="text-center pb-12 pt-2" v-if="objCoTypeOfBetting">
          <v-btn-toggle v-model="defineResult.sub" multiple>
            <btn-result :value="3" :color="'cir-pair-banker'" :isPairBanker="true" />
            <div class="mx-5"></div>
            <btn-result :value="4" :color="'cir-pair-player'" :isPairPlayer="true" />
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cancelResult">Cancel</v-btn>
          <v-btn color="primary" @click="dialogConfirm = true"
            :disabled="defineResult.result_code == null && defineResult.result_code != 0">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirm" persistent max-width="580" max-height="580">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          <div class="w-100" style="width:100%">.
            Do you want to submit
            <span v-if="defineResult.result_code == 1" class="wala-text bold">Player</span>
            <span v-if="defineResult.result_code == 0" class="meron-text bold">Banker</span>
            <span v-if="defineResult.result_code == 2" class="tie-text bold">Tie</span>
            <span v-if="defineResult.result_code == 5" class="cancel-text bold">Cancel</span>
            <br>
          </div>

          <div
            :class="defineResult.result_code == 1 ? ' confirm-circle wala' : defineResult.result_code == 0 ? ' confirm-circle meron' : defineResult.result_code == 2 ? ' confirm-circle tie' : defineResult.result_code == 3 ? ' confirm-circle cancel' : ''">
          </div>
          <div class="w-100" style="width:100%; margin-top:15px">
            <span v-if="defineResult.sub && (defineResult.sub[0] == 3 || defineResult.sub[1] == 3)" class="pr-5">
              <btn-result :value="3" :color="'cir-pair-banker'" :isPairBanker="true" />
            </span>
            <span v-if="defineResult.sub && (defineResult.sub[0] == 4 || defineResult.sub[1] == 4)">
              <btn-result :value="4" :color="'cir-pair-player'" :isPairPlayer="true" />
            </span>
            <br>
          </div>
        </v-card-title>
        <v-card-actions>

          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogConfirm = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="saveResult" :disabled="loadingResult">
            Submit
          </v-btn>
          <td style="text-align:center " v-if="loadingResult">
            <div class="lds-roller my-3">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </td>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BtnResult from "../../../../components/shared/button/btn-result";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: { BtnResult },
  props: ['objCoResult', 'channel', 'objSummaryCoResult', 'objCoTypeOfBetting', "loadingResult"],
  data() {
    return {
      dialogConfirm: false,
      defineResult: {
        chanel_id: null,
        resultTypeId: null,
        sub: []
      },
      disableBlue: false,
      disableRed: false,
      break: false,
      allowToClick: true,
      loadingData: false,
      noCid : 1,
      noRid : 1,
      totalResult : 0
    }
  },

  computed: {
    orderResult() {
      return this.getListResult();
    },
    ...mapGetters("$_fantanChannel", {
      dialogResult: "getdialogResult",
    }),
  },

  watch: {
    objCoResult: function () {
    },
    objCoTypeOfBetting: function () {
    },
    channel: function () {
    },
    objSummaryCoResult: function () {
    },
    allowToClick: function () { }
  },

  mounted() {

  },

  methods: {

    _select(r, c) {
      this.disableRed = false;
      this.disableBlue = false;
      this.defineResult.rid = r
      this.defineResult.cid = c
      // this.dialogResult = true
      for (let i = 0; i < this.objCoResult.length; i = i + 1) {
        if (this.objCoResult[i].name != "CANCEL" && this.objCoResult[i].name != "TIE") {
          if (this.defineResult.rid > 1 && this.objCoResult[i].name == "MERON") this.disableRed = true;
          if (this.defineResult.rid > 1 && this.objCoResult[i].name == "WALA") this.disableBlue = true;
          if (this.defineResult.rid == 1 && this.objCoResult[i].name == "WALA") this.disableRed = true;
          if (this.defineResult.rid == 1 && this.objCoResult[i].name == "MERON") this.disableBlue = true;
          break;
        }
      }


    },
    //Match result column and row
    matchResult(r, c) {
      let flag, code, name,  f_no;
      this.objCoResult.forEach(item => {
        if (item.rid === r && item.cid === c) {
          flag = true
          if(item.name == "1"){
            code = 'cir-white';
          }else if(item.name == "2"){
            code = 'cir-green';
          }else if(item.name == "3"){
            code = 'cir-yellow';
          }
          else{
            code = 'cir-red';
          }
          name = item.name
          f_no = item.fight_no
          return false;
        }

      })
      if (flag) {
        return {
          color: code,
          name: name,
          fightNo: f_no,
          disable: true
        }
      } else {
        return {
          color: "no-circle",
          disable: false
        }
      }
    },

    getListResult() {
      if (this.objCoResult) {
        let row = 1;
        let col = 1;
        let newResult = [];
        for (var index = 0 ; index <= this.objCoResult.length; index++) {
          const item = this.objCoResult[index];
          if (index == this.objCoResult.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            
              if (pre.row >= 6) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                newResult.push({
                  ...item,
                  row: pre.row + 1,
                  col: pre.col,
                });
              
            }
          }
          // });
        }
        return newResult;
      }
    },

    saveResult() {
      this.defineResult.chanel_id = this.channel._id
      this.defineResult.resultTypeId = this.objCoTypeOfBetting[this.defineResult.result_code]._id;
      this.defineResult.sub[0] == 3 || this.defineResult.sub[1] == 3 ? this.defineResult.pairBanker = this.objCoTypeOfBetting[3]._id : this.defineResult.pairBanker = null;
      this.defineResult.sub[0] == 4 || this.defineResult.sub[1] == 4 ? this.defineResult.pairPlayer = this.objCoTypeOfBetting[4]._id : this.defineResult.pairPlayer = null;
      this.$emit("submitResult", this.defineResult);
      this.defineResult.result_code = null;
      this.defineResult.sub = [];
      if (this.loadingResult == false) {
        this.$emit("submitDynamicRes", false);
        this.dialogConfirm = false
        this.defineResult.rid = null
        this.defineResult.cid = null
      }
      else {
        window.location.reload();
      }
    },
    cancelResult() {
      this.defineResult.rid = null
      this.defineResult.cid = null
      this.defineResult.result_code = null
      this._closeDialogResult();
      // this.dialogResult = false
    },
    _closeDialogResult() {
      this.$emit("submitDynamicRes", false);
    },
    calculateColumn(c) {
      // if (this.channel.column - this.channel.fix_column > 0) {
      //   return c + (this.channel.column - this.channel.fix_column)
      // } else {
      //   return c;
      // }
      return c
    },
    switchResult(name) {
      let data;
      switch (name) {
        case 'small':
          data = "S";
          return data;
        case 'large':
          data = "L";
          return data;
        case 'TIE':
          data = "T";
          return data;
        case 'CANCEL':
          data = "C";
          return data;
      }
    },
    ...mapMutations("$_fantanChannel", [
      "POPUP_DIALOG_RESULT",
    ]),
  }
}
</script>


<style scoped>

.stage {
  width: 38px;
  height: 38px;
  padding: 3px;
  -ms-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
  -ms-perspective-origin: 50% 50%;
  -o-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
}

.range_4 {
  width:50px!important;
   background: radial-gradient(circle at 30% 120%, #323232, #79071b 90%, #000 100%)!important;
}
.range_5 {
   background: radial-gradient(circle at 30% 120%, #323232, #ff6882 90%, #000 100%)!important;
}
.range_1 {
  width:50px!important;
  height:50px!important;
   background: radial-gradient(circle at 30% 120%, #323232, #efc60d 90%, #000 100%)!important;
}
.range_2 {
  background: radial-gradient(circle at 30% 120%, #323232, #110de6 90%, #000 100%)!important;
}
.range_3 {
  background: radial-gradient(circle at 30% 120%, #323232, #090 90%, #000 100%)!important;
}
.l-result-icon {
  display: inline-block!important;
  width: 100%!important;
  height: 30px!important;
  margin: 0!important;
  padding-top: 16px;
    padding-left: 11px;
    color: white;
  border-radius: 50%!important;
  /* display: flex!important; */
  align-items: center!important;
  justify-content: center!important;
  position: relative!important;
  font-weight: 700!important;
  font-size: 20px!important;
  
}
.table-result {
  background-color: #acb8bd;
  overflow-x: scroll;
  width: 50%;
  margin-top: 10px;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.td-table-style {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: solid 1px #ccc;
  text-align: center;
}

.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 57px;
  width: 57px;
  max-width: 67px;
  max-height: 67px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}

.cir-blue {
  border: 25px solid #0b4592;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-red {
  border: 25px solid #b31013;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-green {
  border: 25px solid #28a745;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}
.cir-yellow {
  border: 25px solid #fce112;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}
.cir-white {
  border: 25px solid #fff;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-cancel {
  border: 25px solid grey;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.no-circle {
  width: 53px;
  height: 55px;
  display: block;
}

.cancel-btn {
  color: white !important;
  ;
  background-color: grey !important;
}

.agree-btn {
  color: white !important;
  ;
  background-color: #3F9542 !important;
}

.confirm-circle {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  padding: 20px;
  margin-top: 20px;
}

.meron {
  background-color: #b31013
}

.wala {
  background-color: #0b4592
}

.tie {
  background-color: #3F9542
}

.cancel {
  background-color: gray
}

.meron-text {
  color: #b31013
}

.wala-text {
  color: #0b4592
}

.tie-text {
  color: #3F9542
}

.cancel-text {
  color: gray
}

.fight-no-style {
  margin-left: -17.5px;
  width: 35px;
  margin-top: -11px;
  font-size: 14px;
  color: black
}

td:hover {
  background-color: #ffbf00 !important;
  ;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #cef;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.p-banker {
  position: absolute;
  border-radius: 25px;
  left: 15px;
  top: 15px;
  width: 9px;
  height: 9px;
  background-color: #f44336;
}

.p-player {
  position: absolute;
  border-radius: 25px;
  right: 15px;
  bottom: 15px;
  width: 9px;
  height: 9px;
  background-color: #1876d2;
}

.result-block {
  position: relative;
}

.result-block .show-fightNo {
  visibility: hidden;
}

.result-block:hover .show-fightNo {
  visibility: visible;
  width: 55px;
  height: 55px;
  color: black;
  position: absolute;
  top: -28px;
  left: -27px;
  font-size: 17px;
  /* font-style: italic; */
  font-weight: bold;

}

.r-cir-blue {
  background-color: #0b4592;
}

.r-cir-red {
  background-color: #b31013;
}

.r-cir-green {
  background-color: #28a745;
}

.r-cir-cancel {
  background-color: grey;
}
.result-number{
  font-family:'khmer mef1'; 
  font-weight:bold;
  color:black ;
  font-size: 17px;
  margin-top: 5px;
}
.result-number-1{
  font-family:'khmer mef1'; 
  font-weight:bold;
  color:black ;
  font-size: 17px;
  margin-top: 2px;
}
/* .fan1 {
  color: white;
} */
</style>
