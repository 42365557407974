import Request from '@/services/axios'

let request = new Request;

const fetchListPermission = async ({commit}, data) => {
    // data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    // data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    const response = await request.get({
        url: 'permissions/getAll',
        data
    });
    if (response.data.code) {
        commit('UPDATE_PERMISSION', response.data.data);
    }
};

const createPermission = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'permissions',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully created permission.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail created permission.`)
};

const updatePermission = async ({commit}, {vm, data}) => {
    const response = await request.put({
        url: 'permissions/' + data._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully updated permission.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail updated permission.`)
};

const deletePermission = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'permissions/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`successfully deleted permission.`)
    } else vm.$toastr.e(`'failed delete permission.`)
};

const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};

const fetchCurrentPermission = async ({commit}, payload) => {
    const response = await request.get({
        url: 'permissions/' + payload._id,
    });
    if (response.data.code) {
        commit('UPDATE_CURRENT_PERMISSION', response.data.data.object);
    }
};

export default {
    fetchListPermission,
    createPermission,
    fetchPopUp,
    fetchCurrentPermission,
    updatePermission,
    deletePermission
}
