import typeGameSummaryAgent from '@/modules/statementReports/typeGame/typeGameSummaryAgent';
import Layout from '@/layouts/default';

const routes = [{
    path: '/typeGameSummaryAgent',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: typeGameSummaryAgent,
        name: 'typeGameSummaryAgent'
    }]
}];

export default routes;