<template>
  <div>
    <div class="section-header" hidden>
      <h1>{{ $t("title.permission_list") }}</h1>
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active"><a href="/">Dashboard</a></div>
        <div class="breadcrumb-item">User Permission</div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive" style="text-align: center">
      <v-data-table
        :headers="headers"
        :items="
          getPermission && getPermission.object ? getPermission.object : []
        "
        :footer-props="footerProps"
        :loading="!getPermission"
        class=" table table-striped"
        id="table-1"
        item-key="_id"
        :server-items-length="getPermission ? getPermission.objectCount : null"
        style="font-family: 'khmer mef1'"
        :page.sync="data.page"
        :items-per-page.sync="data.rowsPerPage"
        :sort-desc.sync="data.descendingFormat"
        :sort-by.sync="data.sortByFormat"
        hide-default-header
        hide-default-footer
        @update:page="getData"
        @update:items-per-page="getData"
        @update:sort-desc="getData"
        show-select
        :value="getDeleteItems"
        @input="itemDelete"
        mobile-breakpoint="0"
      >
      <template v-slot:header="{ props }">
        <th v-for="head in props.headers" :key="head._id">
          {{ head.text }}
        </th>
      </template>
        <template v-slot:top>
          <Header
            :darkmode="darkmode"
            :params="data"
            :dataDelete="getDeleteItems"
            :user-permission="userAccessPermission"
          />
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <ActionComponents
            :darkmode="darkmode"
            :item="item"
            :dataDelete="getDeleteItems"
            :params="data"
            :user-permission="userAccessPermission"
          />
        </template>
      </v-data-table>
    </div>
      <Dialog
        :darkmode="darkmode"
        :loading="loading"
        :permission="getCurrentPermission"
        :dialog="getPopUp"
        :is-validate.sync="valid"
        v-on:submit="handleSubmit"
        v-on:onCloseDialog="fetchPopUp(false)"
      />
      <div class="text-center pt-2">
          <v-pagination
            v-model="data.page"
            :length="
              getPermission
                ? getPermission.objectCount % data.rowsPerPage != 0
                  ? getPermission.objectCount / data.rowsPerPage + 1
                  : getPermission.objectCount / data.rowsPerPage
                : 0
            "
          ></v-pagination>
          <v-text-field
            :value="data.rowsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="data.rowsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
        <div class="loader" v-if="this.loader == true"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/Header.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },

  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 10,
      fields: ["permission_name", "descr", "order", "status", "updatedAt"],
      search: "",
    },
    loading: false,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("header.permission"),
          align: "start",
          value: "permission_name",
          sortable: false,
        },
        {
          text: this.$t("header.description"),
          value: "descr",
          sortable: false,
        },
        { text: this.$t("header.order"), value: "order", sortable: false },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_permission", [
      "getPermission",
      "getPopUp",
      "getCurrentPermission",
      "getDeleteItems",
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      if (!this.getCurrentPermission._id) {
        await this.createPermission({
          vm: this,
          data: this.getCurrentPermission,
        });
      } else {
        await this.updatePermission({
          vm: this,
          data: this.getCurrentPermission,
        });
      }
      await this.getData();
      this.loading = false;
    },

    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListPermission(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    ...mapMutations("$_permission", ["DELETE_ITEMS", "UPDATE_PERMISSION"]),
    ...mapActions("$_permission", [
      "fetchListPermission",
      "createPermission",
      "fetchPopUp",
      "updatePermission",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_PERMISSION([]);
    this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;
}

table {
  font-family: "khmer mef1" !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>
