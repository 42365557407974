import topUp from '@/modules/topUp';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/top-up',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: topUp,
				name: 'topUp'
			}
		]
	}
];

export default routes;
