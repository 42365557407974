<template>
  <div>
    <div v-if="isData" class="m-5 shadow-md bg-white p-5 rounded">
      <a-table
        class="shadow-md p-5"
        :columns="columns"
        :data-source="data"
        :showHeader="false"
        :pagination="false"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
      columns: [
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Age",
          dataIndex: "age",
        },
        {
          title: "Address",
          dataIndex: "address",
        },
      ],
    };
  },
  computed: {
    isData() {
      if (!this.data.length) {
        this.getData();
      }
      return true;
    },
  },
  methods: {
    getData() {
      for (let i = 0; i < 10; i++) {
        this.data.push({
          key: i,
          name: `Edward King ${i}`,
          age: 32,
          address: `London, Park Lane no. ${i}`,
        });
      }
    },
  },
};
</script>
