import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    getListOption: {},
    getListOptionDefaultCustom:{},
    loading: true,
    getLoadingCustom : true,
    radioOption:{},
    radioOptionCurrent:{},
    listOptionCustom:{},
    listCreateOptionCustom:{}
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
