<template>
    <div>
        Bet Settings
    </div>
</template>

<script>
export default {
   
}
</script>

<style lang="scss" scoped>

</style>