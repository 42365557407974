<template>
  <v-card >
    <form-channel
      :channel="currentChannel.currentChannel"
      :objSnNumber="objSnNumber"
      :socketChannelType ="dataFromSocket.type"
      :socketChannelCountdown ="dataFromSocket.countDown"
      :selectResult="selectResult"      
      v-on:submitResult="handleSubmitResult"
      v-on:submitUpdateChanel="handleSubmitUpdateChanel"
    />
    <table-result :summaryResult="objSummarySnResult" :objSnResult="objSnResult" :rows="6" :cols="20"/>
  </v-card>
</template>

<script>
import formChannel from "./_components/formChannel";
import tableResult from "./_components/tableResult";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    formChannel,
    tableResult
  },
  data: () => {
    return {
      dataFromSocket:[],
      selectResult: {
        order: null,
        _id: null,
      },
      loading:false
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode"
    }),
    ...mapGetters("$_channel", {
      currentChannel: "getCurrentChannel",
      objSnNumber: "getBtnResults",
      objSummarySnResult: "getSummaryResult",
      objSnResult: "getResultTable",
    })
  },

  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    if (this.checkUserPermission("VIEW")) {
      await this.fetchCurrentChannel();
      await this.fetchBtnResult();
    }
  },

  async mounted() {
    this.$socket.on("client-update-channel-info", data => {
      if(data) {
        this.currentChannel.currentChannel = data
      }
    });
    this.$socket.on("client-start-count-down", data => {
      if (data) {
        this.dataFromSocket = data;
        if(this.dataFromSocket.countDown<=10){
          if(this.dataFromSocket.countDown <=10 && this.dataFromSocket.countDown >0){
            this.playAudioTing();
          }else if(this.dataFromSocket.countDown ==0){
            this.playAudioGoodLuck();
          }
        } 
      }
    });
  },
  methods: {
    async handleSubmitResult(data) {
      let submitData = {
        channel_id: this.currentChannel.currentChannel._id,
        sn_number_id: data._id
      };
      await this.createResult({ vm: this, data: submitData });
    },
    async handleSubmitUpdateChanel(dataUpdateChanel) {
      await this.updateChannel({ vm: this, data: dataUpdateChanel } )
    },

    playAudioTing() {
      let audio = new Audio("/sound/ting1s.mp3");
      let promise = audio.play();
      if (promise) {
        //Older browsers may not return a promise, according to the MDN website
        promise.catch(function () {});
      }
    },
    playAudioGoodLuck() {
      let audio = new Audio("/sound/good luck.mp3");
      let promise = audio.play();
      if (promise) {
        //Older browsers may not return a promise, according to the MDN website
        promise.catch(function () {});
      }
    },

    ...mapMutations("$_channel", ["UPDATE_CHANNEL", "SET_RESULT", "SUMMARY_RESULT"]),
    ...mapActions("$_channel", [
      "fetchCurrentChannel",
      "fetchBtnResult",
      "createResult",
      "updateChannel"
    ])
  }
};
</script>

<style lang="scss" scoped></style>
