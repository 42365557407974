/* eslint-disable no-unused-vars */
import Request from '@/services/axios'
import Vue from "vue";

let request = new Request;

const fetchListStatement = async({ commit }, preData) => {
    //console.log(preData.reportUrl)
    let data = preData.data
    let reportUrl = preData.reportUrl
    Vue.$cookies.set("currency-type", 1, 0);
    Vue.$cookies.set("app_id", preData.appId, 0);
    const response = await request.post({
        url: 'statementCurrency/' + reportUrl,
        data,
    });
    console.log(response);
    if (response.data.code) {
        commit('LIST_STATEMENT', response.data.data);
        commit('COUNT_STATEMENT', response.data.data.objectCount);
    }

};


export default {
    fetchListStatement,
};