import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    statement: {
        object: [],
    },
    listChannel:{
        object:[]
    },
    loading: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};