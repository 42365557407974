import seniorReports from '@/modules/seniorReports';
import Layout from '@/layouts/default';

const routes = [{
    path: '/seniorReports',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: seniorReports,
        name: 'seniorReports'
    }]
}];

export default routes;