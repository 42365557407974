const headerPlayer = [
 
  {
    text: 'No',
    align: 'start',
    value: 'no',
  },
  { text: 'App', value: '_id.app_id' },
  { text: 'View', align: 'start', value: 'actions', sortable: false },

  { text: 'Company', value: '_id.com' },
  { text: 'Senior', value: '_id.admin' },
  { text: 'Master', value: '_id.master' },
  { text: 'Agent', value: '_id.agent' },
  { text: 'Player', value: '_id.player' },
  { text: 'Balance', value: '_id.player_balance' },
  { text: 'Amount Turnover', value: 'amount' },
  { text: 'AmountWin/Lose', value: 'amount_win' },]

export default headerPlayer;

