export default {
	home: 'Home',
	invoice: 'invoice',
	patients: 'Patients',
	doctors: 'Doctors',
	stock: 'Stock',
	services: 'Services',
	settings: 'Settings',
	contact: 'Contact',
	new_invoice: 'New Invoice',
	detail_invoice: 'Detail Invoice',
	logout: 'Logout'
};
