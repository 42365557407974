const headers = [
   
    {
        text: 'ល.រ',
        align: 'start',
        value: 'no',
    },
    { text: 'កម្មវិធី', value: '_id.app_id' },
    { text: 'អ្នកប្រើប្រាស់', value: '_id.company' },
    { text: 'គណនី', value: '_id.company_balance' },
    { text: 'សរុបប្រាក់ភ្នាល់', value: 'amount' },
    { text: 'សរុបឈ្នះ/ចាញ់', value: 'amount_win' },
    // { text: 'សរុបចាញ់', value: 'amount_lose' },
];

export default headers;