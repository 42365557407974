import betLoto from '@/modules/betLoto';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/bet-loto',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: betLoto,
				name: 'betLoto'
			}
		]
	}
];

export default routes;
