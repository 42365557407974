const getCurrentChannel = (state) => state;

const getLoading = (state) => state.loading;

const getLoadingResult = (state) => state.loadingResult;

const getBtnResults = (state) => state.objSnNumber

const getSummaryResult = (state) => state.summaryResult

const getResultTable = (state) => state.objSnResult

const getDealerDialog = (state) => state.dealerDialog

const getDealerInfo = (state) => state.dealerInfo
const getAllowScanCard = (state) => state.allowScanCard
const getResultEvent = (state) => state.resultEvent
const getCurrentCard = (state) => state.currentCard
const getdialogResult = (state) => state.dialogResult

export default {
    getCurrentChannel,
    getBtnResults,
    getSummaryResult,
    getResultTable,
    getLoading,
    getLoadingResult,
    getDealerDialog,
    getDealerInfo,
    getAllowScanCard,
    getResultEvent,
    getCurrentCard,
    getdialogResult
};