import SeniorStatement from '@/modules/reports/senior';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/SeniorStatement',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: SeniorStatement,
				name: 'SeniorStatement'
			}
		]
	}
];

export default routes;
