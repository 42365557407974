/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchHistoryWithdraw = async ({ commit },data) => {
	data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
	const response = await request.post({
        url: 'users/getStatementWithdraw',
        data
    });
    commit('UPDATE_VIEW_WITHDRAW', response.data.data)
};

export default {
	fetchHistoryWithdraw
};
