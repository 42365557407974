// import reportOutStanding from '@/modules/reportOutStanding';
import fantanReportOutStanding from '@/modules/outstandingReports/fantan';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/fantanReportOutStanding',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: fantanReportOutStanding,
				name: 'fantanReportOutStanding'
			}
		]
	}
];

export default routes;
