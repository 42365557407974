import changePassword from '@/modules/changePassword';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/change-password',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: changePassword,
				name: 'changePassword'
			}
		]
	}
];

export default routes;
