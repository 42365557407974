/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListOptions = async({ commit }, data) => {
    commit('UPDATE_LOADING', true)
    const response = await request.post({
        url: 'optionsOperation/getOptionDefault',
        data
    });
    if (response.data.code) {
        commit('UPDATE_LIST_OPTION', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

const updateOptionDefault = async({ commit },{ vm, data, _id }) => {
    commit('UPDATE_LOADING', true)
    const response = await request.post({
        url: 'optionsOperation/updateOptionDefault/'+ _id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_LOADING', false)
    }else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const fetchListOptionsDefaultCustom = async({ commit }, data) => {
    commit('UPDATE_LOADING_CUSTOM', true)
    const response = await request.post({
        url: 'optionsOperation/getOptionDefaultCustom',
        data
    });
    if (response.data.code) {
        commit('UPDATE_LIST_OPTION_DEFAULT_CUSTOM', response.data.data);
        commit('UPDATE_LOADING_CUSTOM', false)
    }
};

const createOptionDefaultCustom = async({ commit },{ vm, data }) => {
    commit('UPDATE_LOADING_CUSTOM', true)
    const response = await request.post({
        url: 'optionsOperation/createOptionDefaultCustom',
        data
    });
    if (response.data.code) {   
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_LOADING', false)
    }else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const removeOptionDefault = async({ commit },{ vm, data }) => {
    commit('UPDATE_LOADING_CUSTOM', true)
    const response = await request.post({
        url: 'optionsOperation/removeOptionDefault',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_LOADING', false)
    }else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const removeOptionCustom = async({ commit },{ vm, data }) => {
    commit('UPDATE_LOADING_CUSTOM', true)
    const response = await request.post({
        url: 'optionsOperation/removeOptionCustom',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_LOADING', false)
    }else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

//
const fetchRadioOption = async({ commit }, data) => {
    commit('UPDATE_LOADING', true);
    const response = await request.post({
        url: 'optionsOperation/getAllOptions',
        data
    });
    if (response.data.code) {
        commit('UPDATE_RADIO_OPTION', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

const fetchCurrentRadioOption = async({ commit }, data) => {
    commit('UPDATE_LOADING', true);
    const response = await request.post({
        url: 'optionsOperation/getCurrentSelectedOptions',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CURRENT_RADIO_OPTION', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

const updateSelectedOptions = async({ commit },{ vm, data }) => {
    commit('UPDATE_LOADING_CUSTOM', true)
    const response = await request.post({
        url: 'optionsOperation/updateSelectedOption',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_LOADING', false)
    }else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const fetchOptionCustom = async({ commit }, data) => {
    
    commit('UPDATE_LOADING', true);
    const response = await request.post({
        url: 'optionsOperation/getOptionCustom',
        data
    });
    if (response.data.code) {
        commit('UPDATE_LIST_CUSTOM_OPTION', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};
const fetchCreateOptionCustom = async({ commit }, data) => {
    commit('UPDATE_LOADING', true);
    const response = await request.post({
        url: 'optionsOperation/getCreateOptionCustom',
        data
    });
    if (response.data.code) {
        
        commit('UPDATE_LIST_CREATE_CUSTOM_OPTION', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

const createNewOptionCustom = async({ commit },{ vm, data }) => {
    
    commit('UPDATE_LOADING_CUSTOM', true)
    const response = await request.post({
        url: 'optionsOperation/createNewOptionCustom',
        data
    });
    if (response.data.code) {   
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_LOADING', false)
    }else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const updateOptionCustom = async({ commit },{ vm, data, _id }) => {
    
    commit('UPDATE_LOADING', true)
    const response = await request.post({
        url: 'optionsOperation/updateOptionCustom/'+ _id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_LOADING', false)
    }else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};



export default {
    fetchListOptions,
    updateOptionDefault,
    fetchListOptionsDefaultCustom,
    createOptionDefaultCustom,
    removeOptionDefault,
    fetchRadioOption,
    fetchCurrentRadioOption,
    updateSelectedOptions,
    fetchOptionCustom,
    fetchCreateOptionCustom,
    createNewOptionCustom,
    updateOptionCustom,
    removeOptionCustom
};
