const getRoles = (state) => state.roles;
const getPopUp = (state) => state.popUp;
const getCurrentRole = (state) => state.currentRole;
const getDeleteItems = (state) => state.deleteItems;
const getRoleWithoutBody = (state) => state.roleWithoutBody;
const getPermissionSelection = (state) => state.permissionSelection;
const getAllRolesDependOnUser = (state) => state.allRolesDependOnUser;
const getLoading = (state) => state.loading

export default {
	getLoading,
	getRoles,
	getPopUp,
	getCurrentRole,
	getDeleteItems,
	getRoleWithoutBody,
	getPermissionSelection,
	getAllRolesDependOnUser
};
