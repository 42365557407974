export default {
	treaments: 'Treament',
	patient: 'Patient',
	doctor: 'Doctor',
	date: 'Date',
	discount: 'Discount',
	price: 'Price',
	total_price: 'Total Price',
	discount_for_all: 'Discount For All',
	quantity: 'Quantity',
	unit_price: 'Unit price',
	treatment_name: 'Treatment name',
	services: 'Services',
	cashier: "Cashier"
};
