import TreeStatementUSD from '@/modules/statementReports/channel/treeStatementUSD';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/treeStatementUSD',
		component: Layout,
		meta: {
			permission: 'treeStatementUSD',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: TreeStatementUSD,
				name: 'treeStatementUSD'
			}
		]
	}
];

export default routes;
