<template>
  <v-card dark>
      <v-card-text class="text-right">
     <v-row class="pl-5">
            <v-col cols="10" sm="10" md="10" class="text-left mt-4">
              <v-toolbar-title> {{this.title}}</v-toolbar-title>
            </v-col>  
            <v-col cols="2" sm="2" md="2" class="text-right mt-4">
              <v-toolbar-title>
                <v-btn @click="getData" color="green " >
                    <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
                    Refresh
                </v-btn>
          </v-toolbar-title>
            </v-col>  
     </v-row>
      </v-card-text>
        <v-card-text >
            <v-data-table
            :headers="headers"
            :items="generateResult ? generateResult : []"
            hide-default-footer
            :loading="loading"
            class="elevation-1">
          
              <template v-slot:[`item`]="{ item }">
                <tr>
                  <td>{{ item.name }} </td>
                  <td>{{ currencyFormat(item.total_betting) }}</td>
                  <td class="amount-bet-color">{{ item.amount_payout }}</td>
                  <td :class="item.amount_win_lose < 0 ? 'lose-color': item.amount_win_lose > 0 ? 'win-color':''">{{ currencyFormat(item.amount_win_lose) }}</td>
                </tr>
              </template>
                </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
  data: () => {
    return {
        loading:false,
        title:"ពិនិត្យលទ្ធផល",
        headers : [
            { text: 'ប្រភេទចាក់', value: 'name' },
            { text: 'សរុបការភ្នាល់', value: 'total_betting' },
            { text: 'ផលសង', value: 'amount_payout' },
            { text: 'សរុបចាញ់ឈ្នះ', value: 'amount_win_lose' },
        ],
        generateResult: [
            {
                "_id": null,
                "name": null,
                "total_betting": 0,
                "amount_payout": 0,
                "amount_win_lose": 0
            },
      ],
    }
  },
  mounted(){
      this.getData()
      this.$socket.on("client-generate-result", data => {
        if(data) {
          // console.log("client-generate-result",data)
          this.generateResult = data;
          this.$toastr.s("Page Refresh ")
        }
       });
  },
  methods: {
    async getData(){
        this.loading = true
        await this.$request.get({
          url: 'adminReport/getGenerateResult',}).then(res => {
            this.generateResult = res.data.data.object;
             
        });
        this.loading = false
    },
    currencyFormat (num) {
      num = parseFloat(num)
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      // return parseInt(num).toLocaleString('en')
    },
  }
}
</script>

<style scoped>
  .amount-bet-color{
    color: goldenrod!important
  }
  .win-color{
    color: rgb(57, 175, 214);
    font-weight: bold
  }
  .lose-color{
    color:#BA2D4A;
    font-weight:bold
  }
</style>
