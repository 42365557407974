import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    configBannerData: {
        _id: null,
        name: null,
        dob: null,
        profile_image: null,
        barcode: null,
        is_pr: false,
        is_active: false,
        is_pitbox: false,
        position: "",
        address: "",
    },
    currentBannerData:{},
    loading: false,
    popUp: false,
    deleteItems: []
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};