import widthdrawStatement from '@/modules/widthdrawStatement';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/widthdrawStatement',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: widthdrawStatement,
				name: 'widthdrawStatement'
			}
		]
	}
];

export default routes;
