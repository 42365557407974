<template>
  <div>
    <v-btn class="capitalize">
      {{ label }}
      <slot />
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["label"],
};
</script>

<style lang="scss" scoped>
button {
  background-color: var(--primary) !important;
  color: var(--app-text) !important;
}
</style>