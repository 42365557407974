import adminUnit from '@/modules/adminUnit';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/admin-unit',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: adminUnit,
				name: 'adminUnit'
			}
		]
	}
];

export default routes;
