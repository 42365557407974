const headers = [
	{ text: 'សកម្មភាព', value: 'actions', sortable: false },
	{
		text: 'អ្នកគ្រប់គ្រង',
		align: 'start',
		sortable: false,
		value: 'manager.user_name'
	},
	{ text: 'ឈ្មោះអ្នកប្រើប្រាស់', value: 'player.user_name' },
	{ text: 'កាលបរិច្ឆេទ', value: 'date' },
	{ text: 'ទឹកប្រាក់ស្នើសុំ', value: 'amount' },
	{ text: 'ទឹកប្រាក់ដើម', value: 'old_balance' },
	{ text: 'ទឹកប្រាក់នៅសល់', value: 'new_balance' },
	{ text: 'បានធ្វើបច្ចុប្បន្នភាព', value: 'updatedAt' }
];

export default headers;
