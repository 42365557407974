import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [...routes]
});

router.beforeEach(async (to, from, next) => {
    const token = Vue.$cookies.get('token');
    if (!token && to.name !== 'login') {
        next({name: 'login'});
    } else if ((token && to.name === 'login') || to.path === '/') {
        next({path: '/dashboard'});
    } else {
        next();
    }
    //return next();
});

export default router;
