import TreeStatementKH from '@/modules/statementReports/channel/treeStatementKH';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/treeStatementKH',
		component: Layout,
		meta: {
			permission: 'treeStatementKH',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: TreeStatementKH,
				name: 'treeStatementKH'
			}
		]
	}
];

export default routes;
