<template>
  <v-toolbar flat>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <div class="flex justify-between">
      <v-text-field
        :value="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
      <v-spacer></v-spacer>
      <div>
        <v-btn @click="$emit('onDelete')" color="red"> DELETE ALL </v-btn>
        <v-btn @click="$emit('onCreate')" class="ml-5" color="primary">
          create
        </v-btn>
      </div>
    </div>
    <!-- <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card >
        <v-card-title class="text-h5">
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  text @click="closeDelete">Cancel</v-btn>
          <v-btn  text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-toolbar>
</template>

<script>
export default {
  props: ["title", "search", "darkmode"],
  data() {
    return {
      dialogDelete: null,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>