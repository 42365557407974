const getCurrentChannel = (state) => state;

const getLoading = (state) => state.loading;

const getLoadingResult = (state) => state.loadingResult;

const getBtnResults = (state) => state.objCoTypeOfBetting

const getSummaryResult = (state) => state.objSummaryCoResult

const getResultTable = (state) => state.objCoResult

export default {
    getCurrentChannel,
    getBtnResults,
    getSummaryResult,
    getResultTable,
    getLoading,
    getLoadingResult
};