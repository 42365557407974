const UPDATE_LIST_KDPAYOUT = (state, data) => {
    state.kdPayout = data;
};

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}

export default {
    UPDATE_LOADING,
    UPDATE_LIST_KDPAYOUT,
};
