<template>
  <div>
    <!-- sort-by="user_name" -->
    <v-data-table
      :headers="headers"
      :items="getStatement ? getStatement.object : []"
      
      :server-items-length="getCountStatement.count"
      item-key="_id._id"
      class="elevation-1"
      style="font-family: 'khmer mef1'"
      :footer-props="footerProps"
      :page.sync="data.page"
      :items-per-page.sync="data.rowsPerPage"
      :sort-desc.sync="data.descendingFormat"
      :sort-by.sync="data.sortByFormat"
      @update:page="getData"
      @update:items-per-page="getData"
      @update:sort-desc="getData"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header
          :darkmode="darkmode"
          :params="data"
          :user-permission="userAccessPermission"
          :user="user"
          :time="time"
          :listChannelType="getChannelType"
          @child-checkbox="checkboxValue"
        />
        <div class="between w-full mb-3 mt-0 pr-3 "  v-if="user.role_name != 'CONTROLLER'">
          <v-btn
            v-for="(item, i) in defaultFilterDates"
            small
            color="success"
            class="mx-4 mb-4"
            :key="i"
            @click="selectDate(item)"
          >
            <span style="color: white">{{ item.name }}</span>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item`]="{ item }">
        <tr>
          <td>{{ getStatement.object.indexOf(item) + 1 }}</td>
           <td>{{ item._id.app_id }}</td>
          <td>{{ item.user_name }}</td>
          <td>{{ currencyFormat(item.balance) }}</td>
          <td class="amount-bet-color">{{ currencyFormat(item.amount) }}</td>
          <!-- <td class="win-color">{{ currencyFormat(item.amount_win) }}</td> -->
          <td :class="item.amount_win < 0 ? 'lose-color' : 'win-color'">{{ currencyFormat(item.amount_win) }}</td>
        </tr>
      </template>
      <template slot="body.append">
        <tr>
          <td :colspan="4" class="text-xs-center pl-4 py-2 ">
            {{ $t("title.total") }}
          </td>
          <td class="text-xs-center total-style pl-4  py-2">
            {{
              getCountStatement.amount
                ? currencyFormat(getCountStatement.amount)
                : 0
            }}
          </td>
          <td
            class="text-xs-center pl-4 total-style font-weight-bold  py-2"
            :style="
              getCountStatement.amount_win < 0
                ? 'color:#BA2D4A'
                : 'color:#39afd6'
            "
          >
            {{
              getCountStatement.amount_win
                ? currencyFormat(
                    getCountStatement.amount_win
                  )
                : 0
            }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <loader
      v-if="this.loader == true"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headers from "./_api/columns";
import moment from "moment";

export default {
  components: {
    Header: () => import("./_components/HeaderTable.vue"),
  },

  data: () => ({
    valid: true,
    headers: headers,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descending: false,
      sortBy: "amount_win",
      page: 1,
      rowsPerPage: 10,
      fields: ["_id", "user_name", "balance", "amount", "amount_win"],
      search: "",
      startDate: new Date().toISOString().substr(0, 10),//new Date("2021-01-01").toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      channelType: 1,
      currencyType:2
    },
    dialog: false,
    loading: false,
    loader: false,
    user_id: null,
    check: {
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
    },
    format: "YYYY-MM-DD",
    defaultFilterDates: [
      {
        name: "Today",
        active: false,
      },
      {
        name: "Yesterday",
        active: false,
      },
      {
        name: "This Week",
        active: false,
      },
      {
        name: "Last Week",
        active: false,
      },
      {
        name: "This Month",
        active: false,
      },
      {
        name: "Last Month",
        active: false,
      },
    ],
    totalItems: 0,
    time: null,
  }),
  watch: {
    user: function() {
      this.getData();
      this.getChannelTypeData();
    },
    data: function() {
      this.getData();
      this.getChannelTypeData();
    },
    getStatement: function() {},
  },
  computed: {
    reportUrl: function() {
      return "getViewMasterCurrencyApiBranch";
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", user: "getUser" }),
    ...mapGetters("$_statementMasterReports", ["getStatement", "getCountStatement","getChannelType"]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
    await this.getChannelTypeData();
  },
  methods: {
    moment,
    selectDate({ name }) {
      const { format } = this;
      switch (name) {
        case "Today":
          this.startDate = this.moment().format(format);
          this.endDate = this.moment().format(format);
          break;
        case "Yesterday":
          this.startDate = this.moment()
            .subtract(1, "days")
            .format(format);
          this.endDate = this.moment().subtract(1, "days").format(format);
          break;
        case "This Week":
          this.startDate = this.moment()
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .endOf("isoWeek")
            .format(format);
          break;
        case "Last Week":
          this.startDate = this.moment()
            .subtract(1, "isoweek")
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "isoweek")
            .endOf("isoweek")
            .format(format);
          break;
        case "This Month":
          this.startDate = this.moment().format("YYYY-MM-01");
          this.endDate = this.moment().format(format);
          break;
        case "Last Month":
          this.startDate = this.moment()
            .subtract(1, "months")
            .startOf("month")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "months")
            .endOf("month")
            .format(format);
          break;
        default:
          break;
      }
      const { startDate, endDate } = this;
      this.time = { startDate: startDate, endDate: endDate };
      this.data.startDate = startDate;
      this.data.endDate = endDate;
      this.getData();
      //console.log("this.getData():",this.getData());
      this.check = { startDate, endDate };
    },
    // Gets the checkbox information from the child component
    checkboxValue: function(params) {
      this.check = params;
      this.data.startDate = params.startDate;
      this.data.endDate = params.endDate;
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString('en')
    },
    async getChannelTypeData() {
       await this.fetchChannelType();
    },
    async getData() {
      try {
        this.data.channelType = this.getChannelType.channel_type;
        this.loader = true;
        let preData = {
          data: this.data,
          user_id: this.user._id,
          reportUrl: this.reportUrl,
        };
        //console.log('pre_data:',preData); 
        this.checkUserPermission("VIEW")
          ? await this.fetchListStatement(preData)
          : [];
        // console.log(this.getCountStatement)
        this.loader = false;
      } catch (error) {
        // console.log("")
      }
    },
    ...mapMutations("$_statementMasterReports", ["LIST_STATEMENT", "COUNT-STATEMENT","LIST_CHANNEL_TYPE"]),
    ...mapActions("$_statementMasterReports", ["fetchListStatement","fetchChannelType"]),
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
.amount-bet-color {
  color: goldenrod !important;
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
</style>
