<template>
  <div>
    <v-data-table
    :headers="headers"
    :items="kdPayout? kdPayout.object:''"
    sort-by="codeName"
    class="elevation-1"
    item-key="codeName"
    style="font-family: 'khmer mef1'"
    mobile-breakpoint="0"
  >

    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Channel 1 Payout</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>

       <template v-slot:[`item`]="{ item }">
              <tr class="list-kd">
                <td></td>
                <td>{{ item.target_percentage }}</td>
                <td>{{ item.wala_percentage }}</td>
                <td>{{ item.meron_percentage }}</td>
                <td>{{ item.channel_type }}</td>
                <td>
                  {{ moment(item.createdAt).format("DD-MM-YYYY h:mm:ss")}}
                </td>
                <td>
                   {{ moment(item.updatedAt).format("DD-MM-YYYY h:mm:ss")}}
                </td>
                <td><v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon></td>
              </tr>
            </template>
  </v-data-table>
    <Dialog
          :darkmode="darkmode"
          :selectKdPayout="editedItem"
          :dialog="dialog"
          v-on:submit="handleSubmit"
          v-on:onCloseDialog="closeDialogCreate"
      />
  </div>
  
  
</template>

<script>
import headers from "./_api/columns";
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    components: {
      Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    dialog: false,
    headers,
    editedItem: {},
    format: "YYYY-MM-DD",
  }),
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
      ...mapGetters("$_kdPayout", {
      kdPayout: "getKdPayout",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "CREATE NEW" : "EDIT ITEM";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    moment,
    initialize() {
      this.fetchListKdPayout();
    },

    editItem(item) {
      this.editedItem = item;
      this.dialog = true;
    },
    closeDialogCreate() {
      this.editedItem = [];
      this.initialize();
      this.dialog = false;
    
    },
    async handleSubmit(){
      let uploadData = {
          target_percentage: parseInt(this.editedItem.target_percentage),
          wala_percentage: parseInt(this.editedItem.wala_percentage),
          meron_percentage: parseInt(this.editedItem.meron_percentage),
          channel_type: parseInt(this.editedItem.channel_type),
        };
        await this.updateKdPayout({
          vm: this,
          data: uploadData,
          _id: this.editedItem._id,
        });
        this.initialize();
        this.dialog = false;
        
    },
    // save() {
    //   if (this.editedIndex > -1) {
    //     Object.assign(this.desserts[this.editedIndex], this.editedItem);
    //   } else {
    //     this.desserts.push(this.editedItem);
    //   }
    //   this.close();
    // },
    ...mapActions("$_kdPayout", ["fetchListKdPayout", "updateKdPayout"]),
    ...mapMutations("$_kdPayout", ["UPDATE_LIST_KDPAYOUT"]),
  },
};
</script>

<style lang="scss" scoped>
.list-kd td {
  font-size: 20px!important
};
th span {
  font-size: 20px!important
};
.sortable{
  font-size: 20px!important
}
</style>