/* eslint-disable no-empty-pattern */
import Request from '@/services/axios'

let request = new Request;

const fetchLogout = async () => {
	await request.post({
		url: 'logout'
	});
};

const fetchLogIn = async ({}, data) => {
	return await request.post({
		url: 'users/login',
		data
	});
};

const fetchVerifyOtp = async ({}, data) => {
	const response =  await request.post({
		url: '/users/verifyOTP',
		data
	});
	return response
};

// const changePassword = async ({vm, data}) => {
// 	const response = await request.post({
// 		url: 'users/changePassword' + data._id,
// 		data
// 	});
// 	if (response.data.code) {
// 		vm.$toastr.s(`${response.data.message.descriptions}`)
// 	} else vm.$toastr.e(`${response.data.message.descriptions}`)
// }

export default {
	fetchLogout,
	fetchLogIn,
	fetchVerifyOtp
	// changePassword
};
