import RoleLevel from '@/modules/roleLevel';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/roleLevel',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'RoleLevel',
				component: RoleLevel
			}
		]
	}
];

export default routes;
