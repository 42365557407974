const UPDATE_DRAWER = (state, data) => {
    state.drawer = data;
};
const UPDATE_MENU = (state, data) => {
    state.menus = data;
};
const UPDATE_USER_INFO = (state, data) => {
    state.userInfo = data;
};
const UPDATE_USER = (state, data) => {
    state.user = data;
};
const UPDATE_DARK_MODE = (state, data) => {
    state.darkMode = data;
};

const UPDATE_USER_ACCESS_PERMISSION = (state, data) => {
    state.userAccessPermission = data
}

export default {
    UPDATE_DRAWER,
    UPDATE_MENU,
    UPDATE_USER_INFO,
    UPDATE_DARK_MODE,
    UPDATE_USER,
    UPDATE_USER_ACCESS_PERMISSION
};
