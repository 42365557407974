import accBank from '@/modules/accBank';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/acc-bank',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: accBank,
				name: 'accBank'
			}
		]
	}
];

export default routes;
