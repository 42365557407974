/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListUsersPlayer = async ({commit}, data) => {
    commit('UPDATE_LOADING', true)
    
    data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    const response = await request.post({
        url: 'users/getListPlayerByRoleSenior',
        data
    });
    
    if (response.data.code) {
        
        commit('LIST_PLAYER', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};


const updateUser = async ({commit}, {vm, data}) => {
    
    const response = await request.post({
        url: 'users/updateStatusPlayer' ,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};

const updateSuspendUser = async ({commit}, {vm, data}) => {
    // console.log("akdshf", data)
    const response = await request.post({
        
        url: 'users/updatePlayerSuspendStatus' ,
        data
    });
    if (response.data.code) {
        // console.log(response.data)
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
const updateConfigUser = async ({ commit }, { vm, d }) => {
    let data = d
    
    const response = await request.post({
        url: 'configUser/update/' + d._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
export default {
    fetchListUsersPlayer,
    updateUser,
    updateSuspendUser,
    updateConfigUser
};
