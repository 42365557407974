import Weight from '@/modules/weight';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/weight',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'Weight',
				component: Weight
			}
		]
	}
];

export default routes;
