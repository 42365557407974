const getStatement = (state) => {
    return state.statement;
};

const getCountStatement = (state) => {
    return state.countStatement;
};
const getChannelType = (state) => {
    return state.channelType;
};
const getLoading = (state) => {
    console.log('loading...');
    return state.loading;
}
// const getPopUp = (state) => state.popUp;
// const getCurrentUser = (state) => state.currentUser;
// const getDeleteItems = (state) => state.deleteItems;

export default {
    getLoading,
    getStatement,
    getCountStatement,
    getChannelType
};