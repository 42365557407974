const headers = [
	{ text: 'សកម្មភាព', value: 'actions', sortable: false },
	{ text: 'ការភ្នាល់ធម្មតា', value: 'default_bet_a_fight' },
	{ text: 'ការភ្នាល់ក្នុងមួយថ្ងៃ', value: 'default_bet_win_a_day' },
	{ text: 'ភ្នាក់ងារភ្នាល់', value: 'agent_bet_a_fight' },
	{ text: 'ភ្នាក់ងារភ្នាល់ក្នុងមួយថ្ងៃ', value: 'agent_bet_win_a_day' },

]

export default headers;
