import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    dataUSD: {},
    dataKh:{},
    dataTb:{},
    users: [{
        user_id: null,
        user_name :null
    }],
    currentPayOutData:{},
    loading: false,
    popUp: false,
    deleteItems: [],
    dataCancelBetting:[]
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};