import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    statement: {
        object: [],
    },
    countStatement: {
        objectCount: null,
    },
    channelType: null,
    loading: true,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};