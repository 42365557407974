const getRoleLevelData = (state) => state.roleLevelData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getUsers = (state) => state.users;

const getCurrentRoleLevelData = (state) => state.currentRoleLevelData;

export default {
    getRoleLevelData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getUsers,
    getCurrentRoleLevelData
};