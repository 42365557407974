import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    permission: {
        object: [],
        objectCount: null
    },
    popUp: false,
    currentPermission: {
        permission_name: null,
        descr: null,
        order:null
    },
    deleteItems: []
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
