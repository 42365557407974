import setOptionOperation from '@/modules/setOptionOperation';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/setOptionOperation',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: setOptionOperation,
				name: 'setOptionOperation'
			}
		]
	}
];

export default routes;
