import apongChannel from '@/modules/channelGames/apongChannel';
import Layout from '@/layouts/default';

const routes = [{
    path: '/apongChannel',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: apongChannel,
        name: 'apongChannel'
    }]
}];

export default routes;