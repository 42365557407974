import yukiChannel from '@/modules/channelGames/yukiChannel';
import Layout from '@/layouts/default';

const routes = [{
    path: '/yukiChannel',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: yukiChannel,
        name: 'yukiChannel'
    }]
}];

export default routes;