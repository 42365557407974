import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    roles: {
        object: [],
        objectCount: null
    },
    popUp: false,
    currentRole: {
        role_name: null,
        descr: null,
        menu: [],
    },
    permissionSelection: [],
    deleteItems: [],
    roleWithoutBody: {
        object: [],
        objectCount: null
    },
    allRolesDependOnUser: [],
    loading: true
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
