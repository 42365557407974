// update data over statement

const LIST_STATEMENT = (state, data) => {
    state.statement = data;
};

const PARENT_LIMIT_DATA = (state, data) => {
    state.parentLimitData = data;
};

const COUNT_STATEMENT = (state, data) => {
    state.countStatement = data;
};

const LIST_CHANNEL_TYPE = (state, data) => {
    state.channelType = data;
};
const UPDATE_LOADING = (state, data) => {
    state.loading = data
}
const UPDATE_POPUP = (state, data) => {
    state.popUp = data;
};

export default {
    LIST_STATEMENT,
    COUNT_STATEMENT,
    LIST_CHANNEL_TYPE,
    UPDATE_LOADING,
    PARENT_LIMIT_DATA,
    UPDATE_POPUP
};