const getMenus = (state) => state.menus;
const getCurrentMenu = (state) => state.currentMenu;
const getPopUp = (state) => state.popUp
const getDeleteItems = (state) => state.deleteItems
const getParentMenu = (state) => state.menuParent
const getTreeMenu = (state) => state.treeMenu
const getLoading = (state) => state.loading

export default {
	getMenus,
	getCurrentMenu,
	getPopUp,
	getDeleteItems,
	getParentMenu,
	getTreeMenu,
	getLoading
};
