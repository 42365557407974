import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    roleLevelData: {
        _id: null,
        role_name: null,
        child_role_balance: null,
        child_role_name: null,
        user_id: null,
        user_name: null,
    },
    users: [{
        user_id: null,
        user_name :null
    }],
    currentRoleLevelData:{},
    loading: false,
    popUp: false,
    deleteItems: [],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};