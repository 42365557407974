// import reportOutStanding from '@/modules/reportOutStanding';
import baccaratCmReportOutStanding from '@/modules/outstandingReports/baccaratCm';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/baccaratCmReportOutStanding',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: baccaratCmReportOutStanding,
				name: 'baccaratCmReportOutStanding'
			}
		]
	}
];

export default routes;
