// update data over statement

const LIST_STATEMENT = (state, data) => {
    state.statement = data;
};

const COUNT_STATEMENT = (state, data) => {
    state.countStatement = data;
};
const LIST_CHANNEL_TYPE = (state, data) => {
    state.channelType = data;
};

export default {
    LIST_STATEMENT,
    COUNT_STATEMENT,
    LIST_CHANNEL_TYPE
};