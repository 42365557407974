import taisievChannel from '@/modules/channelGames/taisievChannel';
import Layout from '@/layouts/default';

const routes = [{
    path: '/taisievChannel',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: taisievChannel,
        name: 'taisievChannel'
    }]
}];

export default routes;