const headers = [
    {
        text: 'No',
        align: 'start',
        value: 'no',
    },
    { text: 'App', value: '_id.app_id' },
    { text: 'Company', value: '_id.com' },
    { text: 'User', value: '_id.admin' },
    { text: 'Balance', value: '_id.admin_balance' },
    { text: 'Amount Turnover', value: 'amount' },
    { text: 'AmountWin/Lose', value: 'amount_win' },
];

export default headers;