const getPayOutData = (state) => state.payOutData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getUsers = (state) => state.users;

const getCurrentPayOutData = (state) => state.currentPayOutData;

export default {
    getPayOutData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getUsers,
    getCurrentPayOutData
};