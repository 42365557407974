import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    payOutData: {
        _id: null,
        target: null,
        percentage_meron: null,
        percentage_wala: null,
        start_time: 0,
        end_time:0,
        meron:null,
        user_id: null,
        user_name: null,
    },
    users: [{
        user_id: null,
        user_name :null
    }],
    currentPayOutData:{},
    loading: false,
    popUp: false,
    deleteItems: [],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};