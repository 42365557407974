import setPayout from '@/modules/setPayout';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/setPayout',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: setPayout,
				name: 'setPayout'
			}
		]
	}
];

export default routes;
