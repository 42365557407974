import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    currentChannel: {
        _id: null,
        tem_id: null,
        info: null,
        video_info: null,
        date: null,
        fight_no: null,
        live_urls: [],
        // url_live: [],
        // enable: null,
        is_open: null,
    },
    objSnResult: {
        _id: null,
        name: null,
        desc: null,
        color: null
    },

    summaryResult: {
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        five: 0,
        six: 0
    },
    objSnNumber: {
        _id: null,
        name: null,
        desc: null,
        payout: null,
        createdAt: null,
        updatedAt: null,
        order: null,
        average: null,
        number_average: [],
        color: null
    },
    loading: false,
    loadingResult: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};