const UPDATE_PERMISSION = (state, data) => {
    state.permission = data;
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentPermission = {
            permission_name: null,
            descr: null,
            order: null
        }
    }
    state.popUp = data;
};

const UPDATE_CURRENT_PERMISSION = (state, data) => {
    state.currentPermission = data;
};

const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
}

export default {
    UPDATE_PERMISSION,
    UPDATE_POPUP,
    UPDATE_CURRENT_PERMISSION,
    DELETE_ITEMS
}
