/* eslint-disable no-unused-vars */
import Request from '@/services/axios'
let request = new Request;
const fetchGetAllPayOut = async ({ commit }, data) => {
    const response = await request.post({
        url: 'weight/getAll',
        data
    });
    if (response.data.code) {
        // console.log(response.data.data);
        commit('UPDATE_PAYOUT_DATA', response.data.data);
    }
};
const createPayOut = async ({ commit }, { vm, data }) => {
    commit('LOADINGPAYOUT', true , { root: true });
    const response = await request.post({
        url: 'weight/create',
        data
    });
    if (response.data.code) {
        commit('UPDATE_PAYOUT_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};
const updatePayout = async ({ commit }, { vm, data, _id }) => {
    commit('LOADINGPAYOUT', true , { root: true });
    const response = await request.post({
        url: 'weight/update/'+_id,
        data
    });
    if (response.data.code) {
        commit('UPDATE_PAYOUT_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};
const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const fetchCurrentPayOutData = async ({commit}, payload) => {
    commit('UPDATE_CURRENT_DATA', payload);
};

const fetchListUsers = async ({commit}, data) => {
    const response = await request.post({
        url: 'users/getAllByUserId',
        data
    });
    if (response.data.code) {
        commit('UPDATE_USERS', response.data.data.object);
    }
};
const deleteData = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'payout/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
export default {
    fetchGetAllPayOut,
    createPayOut,
    updatePayout,
    fetchPopUp,
    fetchCurrentPayOutData,
    fetchListUsers,
    deleteData
};