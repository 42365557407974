import PlayerStatement from '@/modules/reports/player';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/PlayerStatement',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: PlayerStatement,
				name: 'PlayerStatement'
			}
		]
	}
];

export default routes;
