<template>
  <div>
    <template class="pt-5">
      <Header
        :darkmode="darkmode"
        :params="data"
        :user-permission="userAccessPermission"
      />
    </template>
    <v-tabs color="deep-purple accent-4" right>
      <v-tab @click="_gotoOption(1)">Option Default</v-tab>
      <v-tab @click="_gotoOption(2)">VIP 1</v-tab>
      <v-tab @click="_gotoOption(3)">VIP 2</v-tab>

      <v-tab-item v-for="n in 3" :key="n">
        <v-data-table
          :headers="headers"
          :items="getPayOutData ? getPayOutData.object : []"
          :footer-props="footerProps"
          item-key="_id"
          class="elevation-1 pt-5"
          :server-items-length="
            getPayOutData ? getPayOutData.objectCount : null
          "
          style="font-family: 'khmer mef1'"
          :page.sync="data.page"
          :items-per-page.sync="data.rowsPerPage"
          :sort-desc.sync="data.descendingFormat"
          :sort-by.sync="data.sortByFormat"
          @update:page="getData"
          @update:items-per-page="getData"
          @update:sort-desc="getData"
          show-select
          :loading="getLoading"
          :value="getDeleteItems"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <ActionComponents
              :darkmode="darkmode"
              :item="item"
              :dataDelete="getDeleteItems"
              :params="data"
              :user-permission="userAccessPermission"
            />
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>

    <Dialog
      :darkmode="darkmode"
      :loading="loading"
      :users="getUsers"
      :payOutData="getCurrentPayOutData"
      :dialog="getPopUp"
      :is-validate="valid"
      v-on:submit="handleSubmit"
      v-on:onCloseDialog="closeDialogCreate"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      currency_type: 1,
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "target",
        "percentage_meron",
        "percentage_wala",
        "start_time",
        "end_time",
        "meron.name",
        "wala.name",
      ],
      search: "",
      vip_option: 1,
      channel_id:1
    },
    dataUser: {
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 5000,
      fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
      search: "",
    
    },
    dialog: false,
    loading: false,
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("input.target"), value: "target" },
        { text: "payout", value: "meron.payout" },
        { text: "payout", value: "wala.payout" },
        { text: this.$t("input.percentage_meron"), value: "meron.percentage" },
        { text: this.$t("input.percentage_wala"), value: "wala.percentage" },
        { text: "Amount Meron", value: "meron.amount" },
        { text: "Amount Wala", value: "wala.amount" },
        { text: "fake Amount Meron", value: "meron.fake_amount" },
        { text: "fake Amount Wala", value: "wala.fake_amount" },
        { text: this.$t("input.target"), value: "target" },
        { text: this.$t("input.amount_min"), value: "amount_min" },
        { text: this.$t("input.amount_max"), value: "amount_max" },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_weight", [
      "getPayOutData",
      "getLoading",
      "getDeleteItems",
      "getPopUp",
      "getUsers",
      "getCurrentPayOutData",
    ]),
    ...mapGetters("$_role", ["getAllRolesDependOnUser"]),
  },
  async created() {
    // await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
    await this.getDataUser();
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      if (!this.getCurrentPayOutData._id) {
        await this.createPayOutData({
          vm: this,
          data: this.getCurrentPayOutData,
        });
        this.getCurrentPayOutData = [];
        this.fetchPopUp(false);
      } else {
        this.getCurrentPayOutData.channel_id = this.data.channel_id;
        let uploadData =  this.getCurrentPayOutData;
        // console.log(this.data);
        await this.updatePayout({
          vm: this,
          data: uploadData,
          _id: this.getCurrentPayOutData._id,
        });
        this.fetchPopUp(false);
      }
      await this.getData();
      this.loading = false;
    },

     _gotoOption(selectOption){
      this.data.vip_option = selectOption;
      this.getData();
    },

    async getData() {
      // this.checkUserPermission("VIEW")
        await this.fetchGetAllPayOut(this.data)

    },
    async getDataUser() {
      // this.checkUserPermission("VIEW")
        await this.fetchListUsers(this.dataUser)
        // : [];
    },

    closeDialogCreate() {
      this.getCurrentPayOutData = {};
      this.fetchPopUp(false);
    },

    ...mapMutations("$_weight", [
      "UPDATE_PAYOUT_DATA",
      "DELETE_ITEMS",
      "UPDATE_USERS",
    ]),
    ...mapActions("$_weight", [
      "fetchPopUp",
      "createPayOutData",
      "fetchGetAllPayOut",
      "fetchListUsers",
      "updatePayout",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_USERS([]);
    this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
</style>
