const getDataCancelBetting = (state) => state.dataCancelBetting;
const getDataKh = (state) => state.dataKh;
const getDataTb = (state) => state.dataTb;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getUsers = (state) => state.users;

const getCurrentPayOutData = (state) => state.currentPayOutData;

export default {
    getDataCancelBetting,
    getDataKh,
    getDataTb,
    getLoading,
    getDeleteItems,
    getPopUp,
    getUsers,
    getCurrentPayOutData
};