import seniorManagePlayer from '@/modules/seniorManagePlayer';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/managePlayer',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: seniorManagePlayer,
				name: 'seniorManagePlayer'
			}
		]
	}
];

export default routes;
