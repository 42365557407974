import ocStatementSummaryMasterKm from '@/modules/statementReports/currency/ocStatementSummaryMasterKm';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryMasterKm',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryMasterKm,
        name: 'ocStatementSummaryMasterKm'
    }]
}];

export default routes;