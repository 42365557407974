const getCStatement = (state) => {
    return state.statement;
};

const getChannel = (state) => {
    return state.listChannel;
};

const getLoading = (state) => state.loading


export default {
    getCStatement,
    getChannel,
    getLoading
};