<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    
    
  >
    <v-text-field
      v-model="name"
      :counter="50"
      :rules="nameRules"
      label="គោលដៅ"
      required
      
    ></v-text-field>

    <v-text-field
      v-model="email"
      :rules="nameRules"
      label="តម្លៃទាបបំផុត"
      required
      type="number"
      
    ></v-text-field>

    <v-text-field
      v-model="email"
      :rules="nameRules"
      label="តម្លៃធំបំផុត"
      required
      type="number"
      
    ></v-text-field>

    <v-text-field
      v-model="email"
      :rules="nameRules"
      label="ប៉ុស្តិទី១"
      required      
      
    ></v-text-field>

    <v-text-field
      v-model="email"
      :rules="nameRules"
      label="ប៉ុស្តិទី២"
      required
      
    ></v-text-field>
 
    <v-btn
      color="error"
      class="mr-4"
      @click="reset"
    >
      Reset Form
    </v-btn>

    <v-btn
      color="success"      
    >
      SAVE
    </v-btn>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
  export default {      
    data: () => ({
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
    }),
    computed: {
      ...mapGetters("$_modules", {
        darkmode: "getDarkMode",
      }),
      
    },
    
    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>