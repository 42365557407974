const headers = [
	{ text: 'សកម្មភាព', value: 'actions', sortable: false },
	{
		text: 'អ្នកស្នើសុំ',
		align: 'start',
		sortable: false,
		value: 'proposer'
	},
	{ text: 'ប្រភេទនៃប្រតិបត្តិការណ៍', value: 'operationUnit' },
	{ text: 'ព័ត៌មានគណនីធនាគារ', value: 'account_info' },
	{ text: 'ទឹកប្រាក់ស្នើសុំ', value: 'amount_proposer' },
	{ text: 'កាលបរិច្ឆេទ', value: 'date' },
	{ text: 'អ្នកទទួល', value: 'recipient' },
	{ text: 'លេខគណនី', value: 'account_number' },
	{ text: 'ស្ថានភាព', value: 'active' }
];

export default headers;
